import React, { useState } from 'react';
import '../../assets/styles/categories.css';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SearchIcon from '../../assets/img/icon/search-dark.svg';
import { constructCatProductsURL, currencyFormat } from '../../utils/Utils';

const Sidebar = ({
  firstSlug = null,
  secondSlug = null,
  thirdSlug = null,
  categoriesList = [],
  setMaxPrice,
  setMinPrice,
  maxPrice,
  minPrice,
  products,
  filters,
  setFilters,
  handleFilterProduct,
  filterByPriceRange,
  productValues = [],
  brands = [],
  inCategoriesPage = false,
  showBrands = false,
  fixMinPrice = null,
  fixMaxPrice = null,
  priceRanges = [],
  isSearchPanel = false,
  foundCategories = [],
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {}, [products]);

  const [selectedBrandsFilters, setSelectedBrandsFilters] = useState([]);
  const [selectedDeliveryFilters, setSelectedDeliveryFilters] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(5);
  const [subcatsToShow, setSubcatsToShow] = useState(5);

  const handleFilterChange = (filter, selectedFilters, setSelectedFilters) => {
    const index = selectedFilters.indexOf(filter);
    if (index === -1) {
      setSelectedFilters([...selectedFilters, filter]);
    } else {
      const updatedFilters = [...selectedFilters];
      updatedFilters.splice(index, 1);
      setSelectedFilters(updatedFilters);
    }
  };

  const showmore = () => {
    setItemsToShow(brands.length);
  };

  const showless = () => {
    setItemsToShow(5);
  };

  const showmoreSubcats = () => {
    setSubcatsToShow(categoriesList.length);
  };

  const showlessSubcats = () => {
    setSubcatsToShow(5);
  };

  const handleBrandFilterChange = filter => {
    handleFilterChange(filter, selectedBrandsFilters, setSelectedBrandsFilters);
  };

  const [selectedAttributesFilters, setSelectedAttributesFilters] = useState(
    {}
  );

  const handleAttributesFilterChange = (attribute, value) => {
    setSelectedAttributesFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };

      if (updatedFilters[attribute]) {
        // Toggle the selection for the value
        const isSelected = updatedFilters[attribute].includes(value);

        if (isSelected) {
          // Remove the value if it's already selected
          updatedFilters[attribute] = updatedFilters[attribute].filter(
            val => val !== value
          );

          // Remove the attribute key if all values are unselected
          if (updatedFilters[attribute].length === 0) {
            delete updatedFilters[attribute];
          }
        } else {
          // Add the value if it's not selected
          updatedFilters[attribute] = [...updatedFilters[attribute], value];
        }
      } else {
        // Add the attribute and value if it doesn't exist
        updatedFilters[attribute] = [value];
      }

      return updatedFilters;
    });
  };

  const getCategoryLink = selected => {
    let link = `/categories/products?mainCategory=${firstSlug}`;

    if (secondSlug) {
      link += `&subCategory=${secondSlug}`;
      link += `&thirdCategory=${selected}`;
    } else {
      link += `&subCategory=${selected}`;
    }

    return link;
  };

  const renderFoundCategoriesList = () => {
    return foundCategories ? (
      <div className="mt-4">
        <h6 className="text-blue-600 text-sm font-bold px-4">
          {t('common.found_categories')}
        </h6>
        <ul className="mt-2">
          {foundCategories.map((cat, index) => (
            <li
              key={index}
              className="text-black hover:text-primary text-[15px] block rounded px-4 py-2.5 transition-all"
            >
              <Link
                to={
                  '/' +
                  i18n.language +
                  '/categories/products?' +
                  constructCatProductsURL(cat?.slug, cat.parents)
                }
              >
                {cat.name}
                <span className="ml-2 text-[#A0A0A0]">
                  {secondSlug || thirdSlug
                    ? '(' + cat.products_length + ')'
                    : null}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    ) : null;
  };

  const renderCategoriesList = () => {
    return categoriesList && !thirdSlug ? (
      <div className="mt-4">
        <h6 className="text-blue-600 text-sm font-bold px-4">
          {t('common.subcategories')}
        </h6>
        <ul className="mt-2">
          {categoriesList.slice(0, subcatsToShow).map((cat, index) => (
            <li
              key={index}
              className="text-black hover:text-primary text-[15px] block rounded px-4 py-2.5 transition-all"
            >
              <Link to={getCategoryLink(cat.slug)}>
                {cat.name}
                <span className="ml-2 text-[#A0A0A0]">
                  {secondSlug || thirdSlug
                    ? '(' + cat.products_length + ')'
                    : null}
                </span>
              </Link>
            </li>
          ))}
          {categoriesList.length > 3 && subcatsToShow < 6 ? (
            <button
              style={{ marginTop: '5px', marginLeft: '15px', color: '#4e4790' }}
              onClick={showmoreSubcats}
            >
              <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>{' '}
              {t('common.show_more')}
            </button>
          ) : subcatsToShow > 3 && categoriesList.length > 5 ? (
            <button
              style={{ marginTop: '5px', marginLeft: '15px', color: '#4e4790' }}
              onClick={showlessSubcats}
            >
              <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>{' '}
              {t('common.show_less')}
            </button>
          ) : (
            ''
          )}
        </ul>
      </div>
    ) : null;
  };

  const renderBrandsList = () => {
    return thirdSlug && brands ? (
      <div className="mt-4">
        <h6 className="text-blue-600 text-sm font-bold px-4">
          {t('common.brands')}
        </h6>
        <ul className="mt-2">
          {brands.slice(0, itemsToShow).map((brand, index) => (
            <li key={index}>
              <label
                className="bs_custom_checkbox_cont"
                style={{
                  padding: '10px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <input
                  type="checkbox"
                  className="bs_custom_checkbox_input"
                  id={brand + '_' + index}
                  value={brand + '_' + index}
                  onChange={() => handleBrandFilterChange(brand)}
                  checked={selectedBrandsFilters.includes(brand)}
                ></input>
                <span className="bs_custom_checkbox_span w-full">{brand}</span>
              </label>
            </li>
          ))}
          {brands.length > 3 && itemsToShow < 6 ? (
            <button
              style={{ marginTop: '5px', marginLeft: '15px', color: '#4e4790' }}
              onClick={showmore}
            >
              <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>{' '}
              {t('common.show_more')}
            </button>
          ) : itemsToShow > 3 && brands.length > 5 ? (
            <button
              style={{ marginTop: '5px', marginLeft: '15px', color: '#4e4790' }}
              onClick={showless}
            >
              <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>{' '}
              {t('common.show_less')}
            </button>
          ) : (
            ''
          )}
        </ul>
      </div>
    ) : null;
  };

  const renderAttributesList = () => {
    return thirdSlug && brands ? (
      <div className="mt-4">
        <h6 className="text-blue-600 text-sm font-bold px-4">
          {t('common.attributes')}
        </h6>
        <ul className="mt-2">
          {productValues
            ? Object.entries(productValues).map(([key, values]) => (
                <div>
                  <h4 className="text-blue-600 px-4">{key}</h4>
                  <div className="overflow-scroll max-h-[200px]">
                    {values &&
                      values.map(
                        (value, index) =>
                          value !== '' && (
                            <li key={index}>
                              <label
                                className="bs_custom_checkbox_cont"
                                style={{
                                  padding: '10px',
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="bs_custom_checkbox_input"
                                  id={value + '_' + index}
                                  value={value + '_' + index}
                                  onChange={() =>
                                    handleAttributesFilterChange(key, value)
                                  }
                                  checked={selectedAttributesFilters[
                                    key
                                  ]?.includes(value)}
                                ></input>
                                <span className="bs_custom_checkbox_span w-full">
                                  {value}
                                </span>
                              </label>
                            </li>
                          )
                      )}
                  </div>

                  <hr
                    style={{
                      marginTop: '5px',
                      marginBottom: '20px',
                      color: 'gray',
                      opacity: '0.3  ',
                    }}
                  ></hr>
                </div>
              ))
            : null}
        </ul>
      </div>
    ) : null;
  };

  return (
    <nav className="bg-white shadow-header h-full top-0 left-0 sm:min-w-[0px] py-4 px-2 font-[sans-serif] overflow-none">
      {' '}
      {/*there was class min-w-[400px] */}
      {isSearchPanel && renderFoundCategoriesList()}
      {!isSearchPanel && renderCategoriesList()}
      {renderBrandsList()}
      {renderAttributesList()}
      <div className="mt-4">
        <h6 className="text-blue-600 text-sm font-bold px-4">
          {t('common.price')}
        </h6>
        <div className="price-filter items-center justify-between mb-3">
          <div className="radio-input-wrapper">
            {priceRanges.map((price, index, array) => {
              const startRange = price;
              const endRange = array[index + 1];
              const isLastItem = index === array.length - 1; // Check if it's the last item

              return (
                !isLastItem && (
                  <label
                    className="custom-label"
                    onClick={() => filterByPriceRange(startRange, endRange)}
                  >
                    <input
                      value="value-4"
                      name="value-radio"
                      id={`value-${index}`} // Use index for unique IDs
                      className="custom-radio-input"
                      type="radio"
                    ></input>
                    <div className="custom-radio-design"></div>

                    <div className="custom-label-text">
                      from {currencyFormat(startRange)} to{' '}
                      {currencyFormat(endRange)}
                    </div>
                  </label>
                )
              );
            })}
          </div>

          <div className="space-y-6 font-[sans-serif] max-w-md mx-auto p-[15px]">
            <div>
              <label className="mb-2 text-sm text-black block">
                {t('common.min_price')}
              </label>
              <div className="relative flex items-center">
                <input
                  type="text"
                  placeholder={'Minimum price : ' + fixMinPrice}
                  name="min-price"
                  onChange={e => setMinPrice(e.target.value)}
                  style={{ border: '1px solid #00000040' }}
                  className="px-4 py-3.5 bg-white text-black w-full text-sm border-gray-100 focus:border-blue-500 rounded outline-none"
                />
              </div>
            </div>
            <div>
              <label className="mb-2 text-sm text-black block">
                {t('common.max_price')}
              </label>
              <div className="relative flex items-center">
                <input
                  type="text"
                  placeholder={'Maximum price: ' + fixMaxPrice}
                  name="max-price"
                  onChange={e => setMaxPrice(e.target.value)}
                  style={{ border: '1px solid #00000040' }}
                  className="px-4 py-3.5 bg-white text-black w-full text-sm border-gray-100 focus:border-blue-500 rounded outline-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="price-filter items-center justify-between mb-3">
        <div className="space-y-6 font-[sans-serif] max-w-md mx-auto p-[15px]">
          <button
            onClick={() =>
              handleFilterProduct(
                selectedBrandsFilters,
                selectedDeliveryFilters,
                selectedAttributesFilters
              )
            }
            className="h-[40px] bg-white flex justify-around items-center cursor-pointer filter-button py-2 px-4 border border-black rounded-md w-full"
          >
            <span className="mr-[5px]"> {t('header.filter_products')}</span>
            <img src={SearchIcon} alt="Search" />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
