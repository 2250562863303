import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../assets/styles/commons.css';
import coreService from '../../services/core.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const QuickContact = () => {
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mailSent, setMailSent] = useState(null);
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const handleSubmitContactForm = async () => {
    setIsLoading(true);

    coreService
      .createQuickContact(contactFormData)
      .then(response => {
        if (response.status === 201) {
          setMailSent(response.data);
          setIsLoading(false);
        }
      })
      .catch(error => {});
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setContactFormData({
      ...contactFormData,
      [name]: value,
    });
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center gap-16 p-4 mx-auto max-w-7xl bg-white rounded text-[#333] font-[sans-serif]">
      {mailSent ? (
        <div className="bg-white text-[#333] p-4 w-full rounded font-[sans-serif]">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="sm:text-xl text-xl font-extrabold">
              {t('common.successful_mail')}
            </h1>
            <div className="my-10">
              <p className=" text-sm">{t('common.mail_sent_helptext')}</p>
            </div>
            <hr />
            <div className="mt-10 flex max-sm:flex-col justify-center sm:gap-6 gap-4">
              <button
                onClick={() => navigate('/')}
                type="button"
                className="px-6 py-3 rounded tracking-wider font-semibold border border-blue-600 outline-none bg-blue-600 hover:bg-blue-700"
              >
                {t('common.continue_shopping')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-5 quick-contact">
          <div>
            <h1 className="text-md font-bold">{t('common.quick_contact')}</h1>
            <p className="text-sm text-gray mt-3">
              {t('common.quick_contact_helptext')}
            </p>
            <div className="mt-12">
              <h2 className="text-md font-bold">{t('common.email')}</h2>
                            <ul className="mt-3">
                <li className="flex items-center">
                  <div className="bg-[#fff] h-10 w-10 border border-[#4e4790] rounded-full flex items-center justify-center shrink-0">
                  </div>
                  <div className="text-[#4E4790] text-sm ml-3">
                    <small className="block">Contact number</small>
                    <strong>+994515324124</strong>
                  </div>
                </li>
              </ul>
 
	      <ul className="mt-3">
                <li className="flex items-center">
                  <div className="bg-[#fff] h-10 w-10 border border-[#4e4790] rounded-full flex items-center justify-center shrink-0">
                  </div>
                  <div className="text-[#4E4790] text-sm ml-3">
                    <small className="block">Mail</small>
                    <strong>hello@baysart.com</strong>
                  </div>
                </li>
              </ul>
            </div>
            <div className="mt-12">
              <h2 className="text-md font-bold">{t('common.socials')}</h2>
              <ul className="flex mt-3 space-x-4">
                <li className="bg-[#fff] h-10 w-10 border border-[#4e4790] rounded-full flex items-center justify-center shrink-0">
                  <a href="https://www.facebook.com/baysartcom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      fill="#4E4790"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M6.812 13.937H9.33v9.312c0 .414.335.75.75.75l4.007.001a.75.75 0 0 0 .75-.75v-9.312h2.387a.75.75 0 0 0 .744-.657l.498-4a.75.75 0 0 0-.744-.843h-2.885c.113-2.471-.435-3.202 1.172-3.202 1.088-.13 2.804.421 2.804-.75V.909a.75.75 0 0 0-.648-.743A26.926 26.926 0 0 0 15.071 0c-7.01 0-5.567 7.772-5.74 8.437H6.812a.75.75 0 0 0-.75.75v4c0 .414.336.75.75.75zm.75-3.999h2.518a.75.75 0 0 0 .75-.75V6.037c0-2.883 1.545-4.536 4.24-4.536.878 0 1.686.043 2.242.087v2.149c-.402.205-3.976-.884-3.976 2.697v2.755c0 .414.336.75.75.75h2.786l-.312 2.5h-2.474a.75.75 0 0 0-.75.75V22.5h-2.505v-9.312a.75.75 0 0 0-.75-.75H7.562z"
                        data-original="#000000"
                      />
                    </svg>
                  </a>
                </li>
                <li className="bg-[#fff] h-10 w-10 border border-[#4e4790] rounded-full flex items-center justify-center shrink-0">
                  <a href="https://www.instagram.com/baysartcom/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      fill="#4E4790"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 9.3a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4Zm0-1.8a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm5.85-.225a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM12 4.8c-2.227 0-2.59.006-3.626.052-.706.034-1.18.128-1.618.299a2.59 2.59 0 0 0-.972.633 2.601 2.601 0 0 0-.634.972c-.17.44-.265.913-.298 1.618C4.805 9.367 4.8 9.714 4.8 12c0 2.227.006 2.59.052 3.626.034.705.128 1.18.298 1.617.153.392.333.674.632.972.303.303.585.484.972.633.445.172.918.267 1.62.3.993.047 1.34.052 3.626.052 2.227 0 2.59-.006 3.626-.052.704-.034 1.178-.128 1.617-.298.39-.152.674-.333.972-.632.304-.303.485-.585.634-.972.171-.444.266-.918.299-1.62.047-.993.052-1.34.052-3.626 0-2.227-.006-2.59-.052-3.626-.034-.704-.128-1.18-.299-1.618a2.619 2.619 0 0 0-.633-.972 2.595 2.595 0 0 0-.972-.634c-.44-.17-.914-.265-1.618-.298-.993-.047-1.34-.052-3.626-.052ZM12 3c2.445 0 2.75.009 3.71.054.958.045 1.61.195 2.185.419A4.388 4.388 0 0 1 19.49 4.51c.457.45.812.994 1.038 1.595.222.573.373 1.227.418 2.185.042.96.054 1.265.054 3.71 0 2.445-.009 2.75-.054 3.71-.045.958-.196 1.61-.419 2.185a4.395 4.395 0 0 1-1.037 1.595 4.44 4.44 0 0 1-1.595 1.038c-.573.222-1.227.373-2.185.418-.96.042-1.265.054-3.71.054-2.445 0-2.75-.009-3.71-.054-.958-.045-1.61-.196-2.185-.419A4.402 4.402 0 0 1 4.51 19.49a4.414 4.414 0 0 1-1.037-1.595c-.224-.573-.374-1.227-.419-2.185C3.012 14.75 3 14.445 3 12c0-2.445.009-2.75.054-3.71s.195-1.61.419-2.185A4.392 4.392 0 0 1 4.51 4.51c.45-.458.994-.812 1.595-1.037.574-.224 1.226-.374 2.185-.419C9.25 3.012 9.555 3 12 3Z"></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <form className="ml-auo space-y-4" onSubmit={e => e.preventDefault()}>
            <input
              type="text"
              placeholder={t('common.name')}
              value={contactFormData.name}
              name="name"
              onChange={handleChange}
              className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#4E4790]"
            />
            <input
              type="email"
              placeholder="Email"
              value={contactFormData.email}
              name="email"
              onChange={handleChange}
              className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#4E4790]"
            />
            <input
              type="text"
              placeholder={t('common.subject')}
              value={contactFormData.subject}
              name="subject"
              onChange={handleChange}
              className="w-full rounded-md py-2.5 px-4 border text-sm outline-[#4E4790]"
            />
            <textarea
              placeholder={t('common.message')}
              rows="6"
              value={contactFormData.message}
              name="message"
              onChange={handleChange}
              className="w-full rounded-md px-4 border text-sm pt-2.5 outline-[#4E4790]"
            ></textarea>
            <button
              type="button"
              onClick={handleSubmitContactForm}
              className="text-white bg-[#4E4790] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full"
            >
              {t('common.send')}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default QuickContact;
