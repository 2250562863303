import React, { useState } from 'react';
import '../../assets/styles/accordion.css';
import AccountSidebar from '../AccountSidebar';
import '../../assets/styles/commons.css';

const AccordionSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion accord-sidebar">
      <div className="accordion-header" onClick={handleToggle}>
        <h3>Account Sidebar</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && 
      <div className="accordion-content">
        <AccountSidebar/>
      </div>}
    </div>
  );
};

export default AccordionSidebar;
