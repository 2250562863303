import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Sidebar from '../../components/Sidebar';

import useWindowSize from '../../hooks/useWindowSize';
import productService from '../../services/product.service';

import { useTranslation } from 'react-i18next';
import '../../assets/styles/accordion.css';
import '../../assets/styles/categories.css';
import '../../assets/styles/commons.css';
import ProductListingsBreadcrumbs from '../../components/ProductsListing/BreadCrumbs';
import ProductListPanel from '../../components/ProductsListing/ProductListPanel';

const ProductsList = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const { t, i18n } = useTranslation();
  const [categories, setMainCats] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [childrenCats, setChildrenCats] = useState([]);

  const [productValues, setProductValues] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productBrands, setProductBrands] = useState([]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [fixMinPrice, setFixMinPrice] = useState(null);
  const [fixMaxPrice, setFixMaxPrice] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);

  const [brand, setBrand] = useState('');
  const [filters, setFilters] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get('mainCategory');
  const secondSlug = queryParams.get('subCategory');
  const thirdSlug = queryParams.get('thirdCategory');
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const [sorted, setSorted] = useState('-1');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleGetProducts = useCallback(slug => {
    setIsLoading(true);
    productService
      .filterProducts({
        category: slug,
        search: null,
        sort: null,
        store_slug: null,
        min_price: null,
        max_price: null,
        attributes: null,
        brand: null,
        delivery_method: null,
      })
      .then(response => {
        setProducts(response.data.results.products);
        setTotalProducts(response.data.results.total_count);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
        setMainCats(response.data.results.upper_cats);
        setChildrenCats(response.data.results.child_cats);
        handleGetProductValue(slug);
        setFixMaxPrice(response.data.results.max_price);
        setFixMinPrice(response.data.results.min_price);
        setPriceRanges(response.data.results.price_ranges);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const categorySlug = thirdSlug || secondSlug || slug;
    if (categorySlug) {
      handleGetProducts(categorySlug);
      handleGetProductBrands(categorySlug);
    }
  }, [slug, secondSlug, thirdSlug, handleGetProducts]);

  const handleGetProductValue = category => {
    productService
      .getProductValue(category)
      .then(response => {
        setProductValues(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetProductBrands = catSlug => {
    productService
      .getProductBrands(catSlug)
      .then(response => {
        setProductBrands(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetPriceRange = e => {
    setIsLoading(true);
    productService
      .getProducts({
        category: params.slug,
        min_price: minPrice,
        max_price: maxPrice,
        sort: sorted,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
        }
      })
      .catch(error => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFinalCatSlug = () => {
    let final_slug = null;
    if (thirdSlug) {
      final_slug = thirdSlug;
    } else if (secondSlug && thirdSlug === null) {
      final_slug = secondSlug;
    } else {
      final_slug = slug;
    }

    return final_slug;
  };

  const handleSortProducts = value => {
    setIsLoading(true);
    setSorted(value);
    const final_slug = getFinalCatSlug();

    productService
      .getProducts({
        category: final_slug,
        sort: value,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
          setIsLoading(false);
        }
      })
      .catch(error => {});
  };

  const filterByPriceRange = (min, max) => {
    const final_slug = getFinalCatSlug();

    const lookup_data = {
      category: final_slug,
      min_price: min,
      max_price: max,
    };

    productService
      .filterProducts(lookup_data)
      .then(res => {
        setProducts(res.data.results.products);
        setTotalProducts(res.data.results.total_count);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleFilterProduct = (
    selectedBrandsFilters,
    selectedDeliveryFilters,
    selectedAttributesFilters,
    minPrice,
    maxPrice
  ) => {
    const final_slug = getFinalCatSlug();

    const atibutesString = JSON.stringify(selectedAttributesFilters);

    const lookup_data = {
      category: final_slug,
      min_price: minPrice,
      max_price: maxPrice,
      sort: sorted,
      brand: selectedBrandsFilters,
      delivery_method: selectedDeliveryFilters,
      attributes: atibutesString,
    };

    productService
      .filterProducts(lookup_data)
      .then(res => {
        setProducts(res.data.results.products);
        setTotalProducts(res.data.results.total_count);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleLoadMore = e => {
    e.preventDefault();

    productService
      .getProductsPagination(productsPaginationInfo.next)
      .then(response => {
        setProducts(prev => [...prev, ...response.data.results.products]);
        setTotalProducts(response.data.results.total_count);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
      })
      .catch(error => {})
      .finally(() => {});
  };

  const renderCurrentCategoryName = () => {
    if (categories.length > 0) {
      const currentCat = categories[categories?.length - 1];
      return currentCat.name;
    }
    return '';
  };

  const renderSidebarContent = () => {
    const sidebarProps = {
      slug,
      secondSlug,
      thirdSlug,
      categoriesList: childrenCats,
      setMinPrice,
      setMaxPrice,
      setBrand,
      minPrice,
      maxPrice,
      products,
      filters,
      setFilters,
      handleFilterProduct,
      handleGetPriceRange,
      productValues,
      brands: productBrands,
      fixMinPrice,
      fixMaxPrice,
      priceRanges,
      getFinalCatSlug,
    };

    if (!isMobile) {
      return (
        <div>
          <Sidebar {...sidebarProps} />
        </div>
      );
    }

    return (
      <div className="accordion">
        <div className="accordion-header" onClick={handleToggle}>
          <h3>Filters</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <Sidebar {...sidebarProps} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-auto max-w-8xl">
      <ProductListingsBreadcrumbs
        isSearchPage={false}
        currentCategoryName={renderCurrentCategoryName()}
        totalProducts={totalProducts}
        categories={categories}
      ></ProductListingsBreadcrumbs>
      <div className="lg:flex justify-center gap-4">
        <div className="lg:w-1/5">{renderSidebarContent()}</div>
        <div className="lg:w-4/5 w-full">
          <ProductListPanel
            products={products}
            totalProducts={totalProducts}
            productsPaginationInfo={productsPaginationInfo}
            handleSortProducts={handleSortProducts}
            handleLoadMore={handleLoadMore}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
