import React from 'react';

import { currencyFormat, isUserLoggedIn } from '../../utils/Utils';

import '../../assets/styles/commons.css';

import FavoriteAn from '../../assets/img/icon/icons8-heart.gif';
import MyBasketAn from '../../assets/img/icon/icons8-shopping-bag.gif';

import Favorite from '../../assets/img/icon/icons8-heart.png';
import MyBasket from '../../assets/img/icon/icons8-shopping-bag.png';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useBasketHandler from '../../utils/useBasketHandler';
import useFavHandler from '../../utils/useFavHandler';

const ProductCard = ({ product, minimized = false }) => {
  const isLoggedUser = isUserLoggedIn();
  const handleAddBasket = useBasketHandler(product, isLoggedUser);
  const handleAddFav = useFavHandler(product, isLoggedUser);
  const { t, i18n} = useTranslation();
  const getLink = slug => {
    return `/${i18n.language}/products/${slug}`;
  };

  const discountLabelHandler = () => {
    if (product.discount_type !== 'F') {
      return (
        <>
          {product.discount_value}₼ {t('common.sale')}
        </>
      );
    } else {
      return (
        <>
          {product.discount_value}
          {product.discount_type} {t('common.sale')}
        </>
      );
    }
  };
  return (
    <div
      title={product?.name}
      className={classNames(
        'bg-white w-auto rounded-xl p-3 sm:p-3 shadow-header cursor-pointer hover:-translate-y-1 transition-all relative'
      )}
    >
      {product.is_discount ? (
        <span className="bg-red px-1 py-1 text-sm text-white absolute rounded">
          {discountLabelHandler()}
        </span>
      ) : null}

      <div className="font-[sans-serif] w-full bg-white flex justify-end rounded">
        <img
          src={Favorite}
          alt="gif"
          onMouseOver={e => {
            e.currentTarget.src = FavoriteAn;
          }}
          onMouseOut={e => {
            e.currentTarget.src = Favorite;
          }}
          onClick={() => handleAddFav(product)}
          className="w-6 hover:cursor-pointer"
          title="Add to favorites and buy later"
        ></img>
        <img
          src={MyBasket}
          alt="gif"
          onMouseOver={e => {
            e.currentTarget.src = MyBasketAn;
          }}
          onMouseOut={e => {
            e.currentTarget.src = MyBasket;
          }}
          onClick={() => handleAddBasket(product)}
          className="w-7 hover:cursor-pointer absolute bottom-4 right-4"
          title="Add to basket"
        ></img>
      </div>

      <a href={getLink(product?.slug)} className="product-image__image">
        <div className="w-11/12 h-[250px] sm:h-[200px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-1">
          <img
            src={product.image}
            alt={product.slug}
            className="h-full w-full object-contain"
          />
        </div>
      </a>

      <div>
        <h3 className="font-bold text-gray text-sm">{product.store.name}</h3>
        <a href={getLink(product?.slug)} className="product-image__image">
          <p className="text-gray-500 text mt-4 pro-header">
            {String(product?.name).length > 20 // previous was >25
              ? String(product?.name).substring(0, 20) + '...' // previous was 25
              : product?.name}
          </p>

          <p className="text-[14px]">
            {currencyFormat(product?.price / 12)} / 12 ay
          </p>
        </a>
        <div
          className="flex items-center rounded-full cursor-pointer"
          style={{ justifyContent: 'space-between' }}
        >
          <h4 className="text-gray font-bold mt-4" style={{ fontSize: '16px' }}>
            {product?.is_discount ? (
              <React.Fragment>
                <>
                  <span className="mr-4 text-primary">
                    {currencyFormat(product?.discount_price)}
                  </span>
                  <span className="line-through font-light text-gray-disabled">
                    {currencyFormat(product?.price)}
                  </span>{' '}
                </>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="mr-4 text-primary">
                  {currencyFormat(product?.price)}
                </span>
              </React.Fragment>
            )}{' '}
          </h4>{' '}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
