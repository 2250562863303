import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiUser } from 'react-icons/ci';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLanguageNavigate } from '../../../utils/useLangNavigate';

const UserDropdown = ({ isLoggedUser, isMobile, user, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  const renderLoggedUserContent = () => (
    <ul className="text-left z-199">
      <li className="font-light text-sm mb-3 cursor-default font-bold">
        <span>
          {t('header.hello')}, {user.name || t('header.guest')}
        </span>
        <br />
        <span className="font-normal">ClientID: {user.id}</span>
        <br />
        <span className="font-normal">{user.email}</span>
      </li>
      <li className="text-sm text-center text-[#3D3770] border border-[#574FA0] p-1 mb-3">
        <div
          onClick={() => {
            navigate('/account/profile');
            closeDropdown();
          }}
        >
          {t('header.account-settings')}
        </div>
      </li>
      <li className="font-light text-sm mb-3">
        <div
          onClick={() => {
            navigate('/account/orders');
            closeDropdown();
          }}
        >
          {t('header.order-history')}
        </div>
      </li>
      <li className="font-light text-sm mb-3">
        <div
          onClick={() => {
            navigate('/favorites');
            closeDropdown();
          }}
        >
          {t('common.favorites')}
        </div>
      </li>
      <li className="font-light text-sm mb-3">
        <div
          onClick={() => {
            navigate('/shop');
            closeDropdown();
          }}
        >
          {t('common.my_basket')}
        </div>
      </li>
      <li className="font-light text-sm mb-3">
        <div
          onClick={() => {
            navigate('/faq');
            closeDropdown();
          }}
        >
          {t('common.help_center')}
        </div>
      </li>
      <li
        className="text-sm text-center text-[#3D3770] border border-[#574FA0] p-1"
        onClick={handleLogout}
      >
        <span>{t('header.logout')}</span>
      </li>
    </ul>
  );

  const renderGuestUserContent = () => (
    <div className="dropdown-content">
      <h3>{t('common.welcome_to_baysart')}</h3>
      <p>{t('common.login_and_see_notifications')}</p>
      <button
        onClick={() => {
          navigate('/account/sign-in');
          closeDropdown();
        }}
        className="login-btn mt-2 mb-2"
      >
        {t('common.login_uppercase')}
      </button>
      <button
        onClick={() => {
          navigate('/account/sign-up');
          closeDropdown();
        }}
        className="mt-2"
      >
        {t('header.register_now')}
      </button>
    </div>
  );

  return (
    <div className="relative">
      <CiUser className="text-xl cursor-pointer" onClick={toggleDropdown} />
      <OutsideClickHandler onOutsideClick={() => closeDropdown}>
        {isOpen && (
          <div
            className={`absolute z-10 ${
              isMobile ? 'left-[-150px]' : 'left-[-240px]'
            } mt-2 p-4 bg-white shadow-[0_0px_5px_2px_rgba(0,0,0,0.1)] ${
              isMobile ? 'w-64' : 'w-72'
            }`}
          >
            {isLoggedUser
              ? renderLoggedUserContent()
              : renderGuestUserContent()}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default UserDropdown;
