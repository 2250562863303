import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';

import RightArrow from '../assets/img/icon/right-arrow.svg';
import CategoriesSidebar from '../components/CategoriesSidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ScrollButton from '../components/ScrollButton';
import categoryService from '../services/category.service';
import { useLanguageNavigate } from '../utils/useLangNavigate';

const DefaultLayout = () => {
  const { navMenuMobile } = useSelector(state => state.nav);
  const [categories, setCategories] = useState([]);

  const navigate = useLanguageNavigate();
  useEffect(() => {
    if (navMenuMobile) {
      handleGetCategories();
    }
  }, []);

  const handleGetCategories = () => {
    categoryService
      .getCategories()
      .then(response => {
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(error => {
        throw error.response.data;
      });
  };

  return (
    <React.Fragment>
      <CategoriesSidebar></CategoriesSidebar>
      <Header />
      <main
        className="relative w-full h-full"
        // style={{ backgroundColor: '#F7F6FA' }}
      >
        {navMenuMobile && (
          <div className="absolute z-10 w-full h-full bg-primary-white">
            <div className="px-10">
              <ul>
                {categories && categories.length
                  ? categories
                      .sort((a, b) => (a.order > b.order ? 1 : -1))
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((category, key) => (
                        <li
                          key={key}
                          className="flex items-center justify-between h-full text-sm leading-3 my-4 hover:cursor-pointer"
                          onClick={() =>
                            navigate(`/categories/${category.slug}`)
                          }
                        >
                          <div className="flex items-center">
                            <img
                              src={category.icon}
                              alt="CatIcon"
                              className="w-5 h-5"
                            />
                            <Link to="#" className="mt-1 ml-2">
                              {String(category.name).length > 30
                                ? String(category.name).substring(0, 30) + '...'
                                : category.name}
                            </Link>
                          </div>
                          <img
                            src={RightArrow}
                            alt="RightArrow"
                            className="w-6 h-6"
                          />
                        </li>
                      ))
                  : null}
              </ul>
            </div>
          </div>
        )}
        <Outlet />
      </main>
      <ScrollButton />
      <Footer />
    </React.Fragment>
  );
};

export default DefaultLayout;
