// FavoritesCollectionsBanner.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import userService from '../../../services/user.service';
import { CiTrash } from 'react-icons/ci';
const FavoritesCollectionsBanner = () => {
  const { t } = useTranslation();
  const [activeAddListModal, setActiveAddListModal] = useState(false);
  const [listTitle, setTitle] = useState('');
  const [listDescr, setListDescr] = useState('');
  const [selectedListCategory, setSelectedListCat] = useState(0);
  const [selectedListing, setSelectedListing] = useState(0);
  const [selectedListPrivacyType, setSelectedListPrivType] = useState(0);
  const [favoritesList, setFavoritesList] = useState([]);
  const [favoriteCollections, setFavoriteCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchFavoriteCollections();
  }, []);

  const fetchFavoriteCollections = async () => {
    setIsLoading(true);
    try {
      const collections = await userService.getFavListing();
      setFavoriteCollections(collections);
    } catch (error) {
      console.error('Error fetching favorite collections:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavoritesList();
  }, []);

  const fetchFavoritesList = async () => {
    try {
      const response = await userService.getFavoriteListings();
      setFavoritesList(response.data);
    } catch (error) {
      console.error('Error fetching favorites list:', error);
    }
  };

  const handleSelectListPrivacyType = e => {
    setSelectedListPrivType(e.target.value === '1');
  };

  const handleSubmitNewFavsList = async e => {
    e.preventDefault();
    try {
      await userService.createFavoriteListing({
        title: listTitle,
        description: listDescr,
        sharing_options: selectedListPrivacyType,
      });
      setActiveAddListModal(false);
      fetchFavoritesList(); // Refresh the list
      // Reset form
      setTitle('');
      setListDescr('');
      setSelectedListPrivType(false);
    } catch (error) {
      console.error('Error creating favorites list:', error);
    }
  };

  // const handleListingClick = listing => {
  //   setSelectedListing(listing);
  // };

  const closeSelectedListingModal = () => {
    // setSelectedListing(null);
  };

  const handleRemoveListing = async () => {
    if (selectedListing) {
      try {
        await userService.deleteFavoriteListing(selectedListing.id);
        fetchFavoritesList(); // Refresh the list
        // closeSelectedListingModal();
      } catch (error) {
        console.error('Error removing favorites list:', error);
      }
    }
  };

  const renderLikedProductListingModal = () => {
    if (!selectedListing) return null;

    return (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-6xl bg-white shadow-lg rounded-lg p-6 relative">
          <div className="flex items-center pb-3 border-b border-gray-300">
            <h3 className="text-gray-800 text font-bold flex-1">
              {selectedListing.title}
            </h3>
            <svg
              onClick={closeSelectedListingModal}
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </div>

          <div className="my-6">
            <p className=" text-sm leading-relaxed">
              {selectedListing.description}
            </p>
          </div>

          <div className="my-6">
            <div className="font-[sans-serif] space-y-8 text-[#333] text-center max-w-xl mx-auto">
              <img
                // src={EmptyIcon}
                className="w-20 fill-gray-300 inline"
                alt="EmptyIcon"
              ></img>
              <p className="text-base">You dont have any liked products</p>
            </div>
          </div>

          <div className="border-t border-gray-300 pt-6 flex justify-end gap-2">
            <button
              type="button"
              onClick={handleRemoveListing}
              className="px-4 py-2 rounded-lg text-white text-sm border border-red tracking-wide bg-white"
            >
              <CiTrash className="text-red text-md"></CiTrash>
            </button>
            <button
              type="button"
              className="px-4 py-2 rounded-lg text-sm border border-[#4e4790] text-[#4e4790] tracking-wide bg-white"
            >
              Share my listing
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAddListModal = () => {
    return activeAddListModal ? (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative">
          <div className="flex items-center pb-3 border-b border-gray-300">
            <h3 className="font-bold flex-1">
              {t('common.add_new_favs_list')}
            </h3>
            <svg
              onClick={() => setActiveAddListModal(false)}
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </div>

          <form onSubmit={handleSubmitNewFavsList} className="space-y-2 mt-3">
            <div>
              <label className="text-gray-800 text-sm mb-2 block">
                {t('common.title')}
              </label>
              <input
                type="text"
                className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
                placeholder={t('common.listing_name')}
                required
                value={listTitle}
                onChange={e => setTitle(e.target.value)}
              />
            </div>

            <div>
              <label className="text-gray-800 text-sm mb-2 block">
                {t('common.list_description')}
              </label>
              <textarea
                className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
                placeholder={t('common.list_descr_helptext')}
                rows="3"
                value={listDescr}
                onChange={e => setListDescr(e.target.value)}
              ></textarea>
            </div>

            <div>
              <label className="text-gray-800 text-sm mb-2 block">
                {t('common.privacy')}
              </label>
              <select
                className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
                value={selectedListPrivacyType ? '1' : '0'}
                onChange={handleSelectListPrivacyType}
              >
                <option value="0">Private</option>
                <option value="1">Public</option>
              </select>
              <p className="text-sm text-gray mt-1">
                You will be able to share this listing with other and they can
                see it
              </p>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-[#4E4790] text-white text-sm rounded-md"
              >
                {t('common.save')}
              </button>
            </div>
          </form>
        </div>
      </div>
    ) : null;
  };

  return (
    <React.Fragment>
      <div className="bg-gray-50 text-[#333] px-8 py-8 mt-10 w-full rounded-lg shadow-header font-[sans-serif]">
        <h1 className="font-bold">My favorites collections</h1>
        <p className="mt-4 text-sm text-gray-600">
          Here you can create your collections for your products
        </p>

        <button
          type="button"
          onClick={() => setActiveAddListModal(true)}
          className="px-6 py-3 mt-8 rounded-lg text-white text-sm tracking-wider border-none outline-none bg-[#4e4790]"
        >
          Add New Collection
        </button>
        {renderAddListModal()}

        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Your Favorite Collections
          </h2>
          {isLoading ? (
            <p>Loading your collections...</p>
          ) : favoriteCollections?.length === 0 ? (
            <p>You don't have any favorite collections yet.</p>
          ) : (
            <ul className="space-y-4">
              {favoriteCollections?.map(collection => (
                <li
                  key={collection.id}
                  className="bg-white p-4 rounded-lg shadow"
                >
                  <h3 className="font-semibold">{collection.title}</h3>
                  <p className="text-sm text-gray-600">
                    {collection.description}
                  </p>
                  <p className="text-sm mt-2">
                    Privacy:{' '}
                    {collection.sharing_options === '0' ? 'Private' : 'Public'}
                  </p>
                  <p className="text-sm">
                    Products: {collection.product_count || 0}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FavoritesCollectionsBanner;

// <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
//                 <div>
//                   <label className="text-gray-800 text-sm mb-2 block">
//                     {t('common.list_categories')}
//                   </label>
//                   <select
//                     className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
//                     value={selectedListCategory}
//                     onChange={handleSelectListCategory}
//                   >
//                     <option
//                       value="Seasonal"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Seasonal
//                     </option>
//                     <option value="Spring">Spring</option>
//                     <option value="Summer">Summer</option>
//                     <option value="Fall">Fall</option>
//                     <option value="Winter">Winter</option>

//                     <option
//                       value="Occasions and Events"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Occasions and Events
//                     </option>
//                     <option value="Birthdays">Birthdays</option>
//                     <option value="Weddings">Weddings</option>
//                     <option value="Anniversaries">Anniversaries</option>
//                     <option value="Holidays">Holidays</option>
//                     <option value="Back to School">Back to School</option>
//                     <option value="Graduation">Graduation</option>
//                     <option value="Baby Shower">Baby Shower</option>
//                     <option value="Housewarming">Housewarming</option>

//                     <option
//                       value="Types of Products"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Types of Products
//                     </option>
//                     <option value="Electronics">Electronics</option>
//                     <option value="Clothing">Clothing</option>
//                     <option value="Footwear">Footwear</option>
//                     <option value="Accessories">Accessories</option>
//                     <option value="Home Decor">Home Decor</option>
//                     <option value="Furniture">Furniture</option>
//                     <option value="Kitchen Appliances">
//                       Kitchen Appliances
//                     </option>
//                     <option value="Beauty and Personal Care">
//                       Beauty and Personal Care
//                     </option>
//                     <option value="Toys and Games">Toys and Games</option>
//                     <option value="Books">Books</option>
//                     <option value="Sports Equipment">Sports Equipment</option>
//                     <option value="Outdoor Gear">Outdoor Gear</option>
//                     <option value="Automotive">Automotive</option>

//                     <option
//                       value="Gift Ideas"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Gift Ideas
//                     </option>
//                     <option value="For Him">For Him</option>
//                     <option value="For Her">For Her</option>
//                     <option value="For Kids">For Kids</option>
//                     <option value="For Teens">For Teens</option>
//                     <option value="For Pets">For Pets</option>
//                     <option value="For Colleagues">For Colleagues</option>
//                     <option value="For Friends">For Friends</option>

//                     <option
//                       value="Hobbies and Interests"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Hobbies and Interests
//                     </option>
//                     <option value="Gardening">Gardening</option>
//                     <option value="Cooking and Baking">
//                       Cooking and Baking
//                     </option>
//                     <option value="Travel">Travel</option>
//                     <option value="Fitness and Wellness">
//                       Fitness and Wellness
//                     </option>
//                     <option value="Arts and Crafts">Arts and Crafts</option>
//                     <option value="Photography">Photography</option>
//                     <option value="Music">Music</option>
//                     <option value="Reading">Reading</option>
//                     <option value="Technology and Gadgets">
//                       Technology and Gadgets
//                     </option>

//                     <option
//                       value="Price Range"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Price Range
//                     </option>
//                     <option value="Budget">Budget (Under $25)</option>
//                     <option value="Mid-range">Mid-range ($25 - $100)</option>
//                     <option value="Luxury">Luxury (Over $100)</option>

//                     <option
//                       value="Sales and Discounts"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Sales and Discounts
//                     </option>
//                     <option value="On Sale">On Sale</option>
//                     <option value="Clearance">Clearance</option>
//                     <option value="New Arrivals">New Arrivals</option>
//                     <option value="Best Sellers">Best Sellers</option>

//                     <option
//                       value="User-Specific"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       User-Specific
//                     </option>
//                     <option value="My Wishlist">My Wishlist</option>
//                     <option value="Must-Haves">Must-Haves</option>
//                     <option value="Favorites">Favorites</option>
//                     <option value="To Buy Later">To Buy Later</option>
//                     <option value="Recommended by Friends">
//                       Recommended by Friends
//                     </option>
//                   </select>
//                 </div>
//               </div>
