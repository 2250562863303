import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProductListingsBreadcrumbs = ({
  isSearchPage = false,
  searchQuery = '',
  categories = [],
  totalProducts = 0,
  currentCategoryName = null,
}) => {
  const { t, i18n } = useTranslation();

  const renderBreadCrumbs = () => {
    return (
      <ul className="flex items-center justify-left space-x-4 font-[sans-serif]">
        <li
          className="text-gray-400 text-sm cursor-pointer hover:text-primary"
          style={{ fontSize: '14px' }}
        >
          <Link to="/">{t('error.homepage')}</Link>
        </li>
        {isSearchPage ? renderSearchBreadcrumbs() : renderCategoryBreadcrumbs()}
      </ul>
    );
  };

  const renderSearchBreadcrumbs = () => (
    <li
      className="text-gray-400 cursor-pointer hover:text-primary"
      style={{ fontSize: '14px' }}
    >
      <span>{t('common.search_results')}</span>
    </li>
  );

  const renderCategoryBreadcrumbs = () => (
    <>
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          <li className="text-gray-400" style={{ fontSize: '12px' }}>
            {'>'}
          </li>
          <li
            className="text-gray-400 cursor-pointer hover:text-primary"
            style={{ fontSize: '14px' }}
          >
            <Link
              to={`/${i18n.language}/categories/products?${getUrlParams(
                category.slug
              )}`}
            >
              {category.name}
            </Link>
          </li>
        </React.Fragment>
      ))}
    </>
  );

  const getUrlParams = catSlug => {
    const categoryIndex = categories.findIndex(cat => cat.slug === catSlug);
    const urlParams = {
      mainCategory: categories[0]?.slug || '',
      subCategory: categories[1]?.slug || '',
      thirdCategory: categories[2]?.slug || '',
    };

    return Object.entries(urlParams)
      .filter(([_, value]) => value !== '')
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
  };

  const renderCurrentCategoryName = () => {
    if (isSearchPage) {
      return `${t('common.search_results')} : ${searchQuery}`;
    }
    if (currentCategoryName) {
      return currentCategoryName;
    }
    return '';
  };

  return (
    <div className="text-[#333] px-4 py-4 mb-10 w-full rounded-lg font-[sans-serif] shadow-header">
      <h1 className="font-bold" style={{ fontSize: '18px', color: '#4E4790' }}>
        {renderCurrentCategoryName()} ({totalProducts})
      </h1>
      <nav className="mt-4 text-sm text-gray-500" aria-label="Breadcrumb">
        {renderBreadCrumbs()}
      </nav>
    </div>
  );
};

export default ProductListingsBreadcrumbs;
