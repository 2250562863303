import React from 'react';
import { useTranslation } from 'react-i18next';
import NothingFound from '../../NothingFound';
import ProductsGrid from '../../ProductsGrid';

const ProductListPanel = ({ 
  products, 
  totalProducts, 
  productsPaginationInfo, 
  handleSortProducts, 
  handleLoadMore 
}) => {
  const { t } = useTranslation();

  const renderSortProducts = () => {
    return (
      <select
        name="popularity"
        className="w-[275px] h-[40px] rounded-[5px] text-sm leading-3 text-center outline-none"
        onChange={e => handleSortProducts(e.target.value)}
      >
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="-1"
        >
          {t('common.sort_by_pop')}
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="a-z"
        >
          A-Z
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="z-a"
        >
          Z-A
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="low-high"
        >
          {t('common.sort_by_price_up')}
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="high-low"
        >
          {t('common.sort_by_price_down')}
        </option>
      </select>
    );
  };

  return (
    <div className="font-[sans-serif] shadow-header">
      <div className="p-2 mx-auto">
        <div className="flex items-center justify-end pb-3">
          {renderSortProducts()}
        </div>
        {products && products.length > 0 ? (
          <ProductsGrid gridcols={5} products={products}></ProductsGrid>
        ) : (
          <NothingFound></NothingFound>
        )}
      </div>
      {productsPaginationInfo?.next && (
        <div
          className="group load-more w-full text-center flex justify-center items-center hover:cursor-pointer transition-all pb-5"
          onClick={e => handleLoadMore(e)}
        >
          <div className="group-hover:bg-shadow-hover w-44 h-10 bg-shadow-disabled rounded-md flex justify-center items-center transition-all">
            <span className="font-light text-sm leading-3">
              {t('common.load_more')}...
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductListPanel;