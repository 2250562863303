import React, { useState } from 'react';
import AutoComplete from 'react-google-autocomplete';

import WorkWithUs from '../../assets/img/general/2502.jpg';
import DoneIcon from '../../assets/img/icon/icons8-done-64.png';
import NextIcon from '../../assets/img/icon/icons8-next-64.png';
import ButtonLarge from '../../components/ButtonLarge';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Steps from '../../components/Steps';

import { useTranslation } from 'react-i18next';
import BaysartBg from '../../assets/img/general/baysart-bg.svg';
import BusinessIcon from '../../assets/img/icon/business.svg';
import EmailIcon from '../../assets/img/icon/email.svg';
import ProfileIcon from '../../assets/img/icon/profile.svg';
import SuccessIcon from '../../assets/img/icon/success.svg';
import storeService from '../../services/store.service';
import { nahToast } from '../../utils/Toaster';
import { useEffectOnce } from '../../utils/useEffectMiddleware';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const SellOnBaysart = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [businessName, setBusinessName] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [category, setCategory] = useState([]);

  const [activeModal, setActiveModal] = useState(false);
  const [activeModalSec, setActiveModalSec] = useState(false);
  const [agree, setAgree] = useState(false);

  // Store
  const [street, setStreet] = useState('');
  const [street2, setStreet2] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [region, setRegion] = useState('');
  // Payment
  const [bankAccountName, setBankAccountName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [iban, setIban] = useState('');
  const [swiftCode, setSwiftCode] = useState('');

  const [step, setStep] = useState(1);

  const navigate = useLanguageNavigate();

  const { t } = useTranslation();

  useEffectOnce(() => {
    handleGetCategories();
  }, []);

  const handleGetCategories = () => {
    const cachedCategories = JSON.parse(
      sessionStorage.getItem('categoriesCached')
    );
    setCategories(cachedCategories);
  };

  const handleChange = e => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setCategory(value);
  };

  const handleNext = (e, step) => {
    e.preventDefault();
    if (step === 1) {
      if ((businessName, fullName, email, phone, category)) {
        setStep(2);
      } else {
        nahToast('Bütün sahələr tələb olunur!');
      }
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      if ((street, street2, city, zipCode, region)) {
        const emailUpdate = email.toLowerCase();

        const data = {
          category_id: category,
          business_name: businessName,
          email: emailUpdate,
          full_name: fullName,
          phone,
          street,
          street2,
          city,
          zip_code: zipCode,
          region,
          // bank_account_name: bankAccountName,
          // bank_account_number: bankAccountNumber,
          // bank_name: bankName,
          // bank_address: bankAddress,
          // iban,
          // swift_code: swiftCode,
        };

        storeService
          .createStore(data)
          .then(response => {
            if (response.status === 201) {
              setStep(5);

              setCategory([]);
              setBusinessName('');
              setEmail('');
              setFullName('');
              setPhone('');
              setStreet('');
              setStreet2('');
              setCity('');
              setZipCode('');
              setRegion('');
              setBankAccountName('');
              setBankAccountNumber('');
              setBankName('');
              setBankAddress('');
              setIban('');
              setSwiftCode('');
            }
          })
          .catch(error => {
            setStep(1);
            nahToast(error.response.data.non_field_errors[0]);
          })
          .finally(() => {});
      } else {
        nahToast('Bütün sahələr tələb olunur!');
      }
    }
    // else if (step === 4) {
    //   if (
    //     (bankAccountName,
    //     bankAccountNumber,
    //     bankName,
    //     bankAddress,
    //     iban,
    //     swiftCode)
    //   ) {
    //     const emailUpdate = email.toLowerCase();

    //     const data = {
    //       category_id: category,
    //       business_name: businessName,
    //       email: emailUpdate,
    //       full_name: fullName,
    //       phone,
    //       street,
    //       street2,
    //       city,
    //       zip_code: zipCode,
    //       region,
    //       // bank_account_name: bankAccountName,
    //       // bank_account_number: bankAccountNumber,
    //       // bank_name: bankName,
    //       // bank_address: bankAddress,
    //       // iban,
    //       // swift_code: swiftCode,
    //     };

    //     storeService
    //       .createStore(data)
    //       .then((response) => {
    //         if (response.status === 201) {
    //           setStep(5);

    //           setCategory([]);
    //           setBusinessName("");
    //           setEmail("");
    //           setFullName("");
    //           setPhone("");
    //           setStreet("");
    //           setStreet2("");
    //           setCity("");
    //           setZipCode("");
    //           setRegion("");
    //           setBankAccountName("");
    //           setBankAccountNumber("");
    //           setBankName("");
    //           setBankAddress("");
    //           setIban("");
    //           setSwiftCode("");
    //         }
    //       })
    //       .catch((error) => {
    //         setStep(1);
    //         toast.error(error.response.data.non_field_errors[0], {
    //           position: "bottom-right",
    //           autoClose: 1000,
    //           hideProgressBar: true,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //       })
    //       .finally(() => {});
    //   } else {
    //     toast.error("Bütün sahələr tələb olunur!", {
    //       position: "bottom-right",
    //       autoClose: 1000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // }
  };

  const handleChangeAddress = place => {
    const city = place.address_components.filter(item =>
      item.types.includes('administrative_area_level_2')
    )[0];

    setStreet(place.formatted_address);
    setStreet2(place.formatted_address);
    setCity(city.long_name);
  };

  const renderBanner = () => {
    return (
      <div className="relative font-[sans-serif] before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
        <img
          src={BaysartBg}
          alt="Banner Image"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="min-h-[300px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
          <h2 className="sm:text-4xl text-2xl font-bold mb-6">
            {t('sell-on.sell-on')}
          </h2>
          <p className="text-lg text-center text-gray-200">
            {t('sell-on.slogan')}
          </p>

          <a
            href="#baysart-form"
            className="mt-8 bg-transparent text-white text-base font-semibold py-2.5 px-6 border-2 border-white rounded hover:bg-white hover:text-black transition duration-300 ease-in-out"
          >
            {t('sell-on.start-selling')}
          </a>
        </div>
      </div>
    );
  };

  const renderHowItWorks = () => {
    return (
      <div className="p-4 max-w-[1300px] mx-auto">
        <h2 className="font-heading mb-1  font-bold">
          {t('sell-on.sell_working')}
        </h2>
        <p className="mb-6">
          {t('sell-on.selling_steps')}
        </p>

        <div className="flex">
          <div className="mr-4 flex flex-col items-center">
            <div>
              <div className="flex h-20 w-20 items-center justify-center rounded-full">
                <img width={64} src={NextIcon}></img>
              </div>
            </div>
            <div className="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
          </div>
          <div className="pt-1 pb-8">
            <p className="mb-2  font-bold text-gray-900 dark:text-slate-300">
              {t('sell-on.selling_register')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.free_register')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.cooperation')}
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="mr-4 flex flex-col items-center">
            <div>
              <div className="flex h-20 w-20 items-center justify-center rounded-full">
                <img width={64} src={NextIcon}></img>
              </div>
            </div>
            <div className="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
          </div>
          <div className="pt-1 pb-8">
            <p className="mb-2  font-bold text-gray-900 dark:text-slate-300">
              {t('sell-on.products_list')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.submit_products_adminPanel')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.promotion_store')}
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="mr-4 flex flex-col items-center">
            <div>
              <div className="flex h-20 w-20 items-center justify-center rounded-full">
                <img width={64} src={NextIcon}></img>
              </div>
            </div>
            <div className="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
          </div>
          <div className="pt-1 pb-8">
            <p className="mb-2  font-bold text-gray-900 dark:text-slate-300">
              {t('sell-on.product_packing_shipping')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.packing_after_purchased')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.deliver_courier')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.customer_gets_product')}
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="mr-4 flex flex-col items-center">
            <div>
              <div className="flex h-20 w-20 items-center justify-center rounded-full">
                <img width={64} src={DoneIcon}></img>
              </div>
            </div>
          </div>
          <div className="pt-1 pb-8">
            <p className="mb-2  font-bold text-gray-900 dark:text-slate-300">
              {t('sell-on.final')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.funds_from_sales')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.credit_partners')}
            </p>
            <p className="text-gray-600 dark:text-slate-400">
              {t('sell-on.baysart_supporting_your_store')}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderStartSellingOnBaysart = () => {
    return (
      <div className="p-4 max-w-[1300px] mx-auto " id="baysart-form">
        <div className="font-[sans-serif] text-gray-800 bg-gray-100 px-6 py-12">
          <div className="grid lg:grid-cols-2 gap-8 max-lg:max-w-2xl mx-auto">
            <div className="text-left">
              <h2 className="text-4xl font-bold mb-6">
                {t('sell-on.cooperation_application')}
              </h2>

              <label className="bs_custom_checkbox_cont">
                <span className="bs_custom_checkbox_span w-max p-0">
                  <div className="product-icon-container">
                    <div className="product-icon-text">
                      <div className="w-full sm:w-[300px]">
                        <p className="text-[#4F4F4F] sm:text-sm sm:leading-3">
                          <span>
                            {t('sell-on.before_form')}{' '}
                            <br></br>
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => setActiveModal(!activeModal)}
                            >
                              {t('sell-on.form_policy')}
                            </span>{' '}
                            <span>{t('sell-on.and')}</span>
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => setActiveModalSec(!activeModalSec)}
                            >
                              {t('sell-on.membership_agreement')}
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </span>
              </label>

              <form className="mt-8" onSubmit={e => handleNext(e, 1)}>
                <div className="flex flex-col sm:items-center">
                  <div className="mt-8">
                    <Input
                      type="text"
                      name="business-name"
                      placeholder={t('sell-on.business_name')}
                      onChange={setBusinessName}
                      value={businessName}
                      icon={BusinessIcon}
                      isRequired
                    />
                  </div>
                  <div className="mt-8">
                    <Input
                      type="text"
                      name="name-surname"
                      placeholder={t('sell-on.name_surname')}
                      onChange={setFullName}
                      value={fullName}
                      icon={ProfileIcon}
                      isRequired
                    />
                  </div>
                  <div className="mt-8">
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={setEmail}
                      value={email}
                      icon={EmailIcon}
                      isRequired
                    />
                  </div>
                  <div className="mt-8">
                    <Input
                      type="tel"
                      name="phone"
                      placeholder="+994 --- -- --"
                      onChange={setPhone}
                      value={phone}
                      icon={BusinessIcon}
                      isRequired
                    />
                  </div>

                  <h6 className="leading-5 font-light mt-[30px] sm:mt-[60px]">
                    {t('sell-on.want_to_cell')}
                  </h6>
                  <select
                    name="category"
                    required
                    id="category"
                    value={category}
                    onChange={e => handleChange(e)}
                    className="w-[480px] sm:w-[300px] h-[200px] bg-primary-white rounded-[5px] mt-4 p-4 outline-none"
                    multiple
                  >
                    {categories &&
                      categories.length &&
                      categories.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>

                  <div className="mt-8">
                    <ButtonLarge
                      text={t('sell-on.next')}
                      type="submit"
                      primary
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="flex justify-center items-center">
              <img
                src={WorkWithUs}
                alt="Placeholder Image"
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modalRegulations = () => {
    return (
      <Modal activeModal={activeModal} setActiveModal={setActiveModal}>
        <div className="relative bg-primary-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-4 rounded-t border-b border-shadow-active">
            <h3 className="text-md leading-5 font-semibold text-active">
              Şəxsi məlumatlar haqqında siyasət
            </h3>
            <button
              onClick={() => setActiveModal(!activeModal)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6 space-y-6 overflow-y-scroll h-screen">
            <div className="mb-4">
              <h4 className="text-lg leading-[25px] mb-3">Razılıq:</h4>
              <p className="font-light pl-4">
                - Əgər siz Baysart.com vebsaytından, Baysart mobil tətbiqindən
                (bundan sonra “Baysart Məhsulları” adlandırılır) istifadə
                edirsinizsə, o zaman siz hazırkı Şəxsi məlumatlar haqqında
                siyasətin tətbiqinə razılıq verirsiniz (aşağıda göstərilmiş
                hallar istisna olmaqla). Əgər siz Baysart Məhsullarının istifadə
                olunduğu kənar vebsayta daxil olmusunuzsa, o zaman həmin onlayn
                resursda təqdim etdiyiniz hər hansı məlumatlar həmin vebsaytın
                (şirkətin) şəxsi məlumatlar haqqında siyasətinə uyğun şəkildə
                toplanılır. Sizin məlumatlarınızın məxfiliyi bizim üçün çox
                vacibdir və biz şəxsi məlumatların mühafizəsinin əhəmiyyətli
                olduğunu qəbul edirik. Ona görə də, hazırkı Siyasəti
                hazırlamaqda məqsədimiz şəxsi məlumatlarınızın bizim
                tərəfimizdən necə toplanıldığı, istifadə edildiyi, bölüşüldüyü
                və dəyişdirdiyi barədə sizə məlumat verməkdir.
              </p>
            </div>
            <div className="mb-4">
              <h4 className="text-lg leading-[25px] mb-3">
                Şəxsi məlumatların tərkibi:
              </h4>
              <p className="font-light pl-4">
                - Şəxsi məlumatlar – birbaşa və ya dolayı şəkildə müəyyən olan
                və ya müəyyənləşdirilən fiziki şəxsə (vətəndaşa) aid hər hansı
                informasiyadır. Bu cür məlumatlara xüsusilə aşağıdakıları aid
                etmək olar: ad, soyad və ata adı, doğulma tarixi və yeri, ünvan,
                ailə vəziyyəti, sosial və əmlak vəziyyəti haqqında məlumatlar,
                təhsil, peşə, gəlir haqqında məlumatlar, habelə digər
                informasiya. Bizim tərəfimizdən emal edilən şəxsi məlumatlar
                qanunvericiliyə uyğun qaydada ciddi şəkildə qorunan məxfi
                informasiya hesab edilir. Baysart tətbiqindən istifadə
                çərçivəsində biz istifadəçidən aşağıdakılara çıxış imkanı
                istəyirik:
              </p>
              <ul className="list-disc pl-10 mt-2 font-light">
                <li>Geolokasiya</li>
                <li>Kamera</li>
                <li>IDFA</li>
                <li>Telefon kitabçası</li>
                <li>Fayl sistemi</li>
                <li>Şəbəkəyə qoşulma (mobil şəbəkə və Wi-Fi)</li>
              </ul>
              <p className="font-light mt-4 pl-4">
                - Baysart.com vebsaytından istifadə çərçivəsində biz aşağıdakı
                məlumatları toplayırıq :
              </p>
              <ul className="list-disc pl-10 mt-2 font-light">
                <li>Cookies</li>
                <li>Əməliyyat Sisteminin versiyası</li>
                <li>Brauzerin versiyası</li>
                <li>Cihazın növü</li>
                <li>Brauzerin cari dili</li>
                <li>Cari vaxt zonası</li>
              </ul>
              <p className="font-light mt-4 pl4">
                - Şəxsi məlumatların emalı – avtomatlaşdırma vasitələrindən
                istifadə etməklə və ya bu cür vasitələrdən istifadə etmədən
                şəxsi məlumatlar üzərində edilən istənilən hərəkət (əməliyyat)
                və ya hərəkətlərin (əməliyyatların) məcmusu. Bu cür hərəkətlərə
                (əməliyyatlara) aşağıdakıları aid etmək olar: şəxsi məlumatların
                yığılması, əldə edilməsi, qeydə alınması, sistemləşdirilməsi,
                toplanması, saxlanılması, dəqiqləşdirilməsi (yenilənməsi,
                dəyişdirilməsi), çıxarılması, istifadə olunması, ötürülməsi
                (paylanılması, təqdim olunması, çıxış), anonimləşdirilməsi,
                bloklanması, silinməsi, məhv edilməsi.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                İstifadə məqsədləri:
              </h4>
              <p className="font-light mt-4 pl4">
                - Müştərilərin məlumatları yalnız istehlakçı loyallığına dair
                Baysart koalisiya proqramında (bundan sonra “Proqram”
                adlandırılır) iştirak haqqında Müştəri ilə bağlanılmış müqavilə
                çərçivəsində aşağıdakı məqsədlərlə xidmətlərin göstərilməsi üçün
                toplanılır və saxlanılır: eyniləşdirmə (identifikasiya) və
                kommunikasiya üçün; Proqramın Operatoru və/ və ya Tərəfdaşları
                tərəfindən göstərilən xidmətlər və məhsullar haqqında Müştəriyə
                məlumat vermək üçün; Baysart Məhsullarının və Proqramının işini
                analiz etmək, texniki idarəetməsini həyata keçirmək və
                yaxşılaşdırmaq üçün; marketinq məqsədləri üçün; hazırkı Məxfilik
                Siyasətində göstərilmiş digər məqsədlər üçün. Hazırkı Məxfilik
                Siyasəti çərçivəsində Müştərinin məlumatları dedikdə
                aşağıdakılar nəzərdə tutulur: Müştərinin müqavilələr
                bağlayarkən, mobil tətbiqi quraşdırarkən və istifadə edərkən
                müstəqil şəkildə təqdim etdiyi və Baysart.com vebsaytında şəxsi
                kabinetdə yerləşən şəxsi məlumatlar (o cümlədən ad, soyad, ata
                adı, elektron poçt ünvanı, telefon daxil olmaqla, lakin bunlarla
                məhdudlaşmayaraq); habelə mobil tətbiqdən istifadə prosesində
                avtomatik şəkildə təqdim olunan məlumatlar, o cümlədən
                aşağıdakılar, lakin bunlarla məhdudlaşmayaraq:
              </p>
              <ul className="list-disc pl-10 mt-2 font-light">
                <li>
                  Mobil tətbiqin quraşdırıldığı mobil cihazın identifikasiya
                  edilməsi üçün zəruri olan İDFA (reklam verənlər üçün
                  identifikator) göstəricisinin əldə olunması. Bu, istifadəçinin
                  Baysart məhsullarından istifadə zamanı təhlükəsizliyini
                  gücləndirmək üçün lazımdır.
                </li>
                <li>
                  Cihazın kamerasına çıxış icazəsinin verilməsi loyallıq
                  kartının skanerlənməsi üçün lazımdır. İstifadəçinin
                  geolokasiyasına çıxış üçün icazənin verilməsi müvafiq
                  tərəfdaşların və onların təkliflərinin mobil tətbiqdəki
                  məlumat lentinə verilməsi zamanı bu atributun nəzərə
                  alınmasına imkan yaradır.
                </li>
                <li>
                  Telefon kitabçasına giriş icazəsi istifadəçiyə imkan verəcək
                  ki, Baysart tətbiqini öz telefon kitabçasındakı şəxslərə
                  tövsiyə etməklə bonus manatlar qazansın.
                </li>
                <li>
                  “Cookies” faylları istifadəçiyə imkan verir ki, Baysart
                  platformasından istifadə tarixçəsini, tənzimlənmiş
                  parametrləri və Baysart tətbiqindən istifadə rahatlığını
                  artıran digər məlumatları öz cihazında saxlasın.
                </li>
                <li>
                  Əməliyyat Sisteminin versiyası haqqında məlumat müştərinin
                  istifadə etdiyi əməliyyat sisteminin versiyasının
                  xüsusiyyətlərini nəzərə almaqla müştəriyə əks etdirilən
                  informasiyanı texniki cəhətdən optimallaşdırmaq üçün lazımdır.
                </li>
                <li>
                  Brauzerin versiyası haqqında məlumat müştərinin istifadə
                  etdiyi brauzer sisteminin versiyasının xüsusiyyətlərini nəzərə
                  almaqla müştəriyə əks etdirilən informasiyanı texniki cəhətdən
                  optimallaşdırmaq üçün lazımdır.
                </li>
                <li>
                  Cihazın növü barədə məlumat müştərinin istifadə etdiyi
                  əməliyyat sisteminin versiyasının xüsusiyyətlərini nəzərə
                  almaqla müştəriyə əks etdirilən informasiyanı texniki cəhətdən
                  optimallaşdırmaq üçün lazımdır.
                </li>
                <li>
                  Vaxt zonası müştərinin yerləşdiyi vaxt zonası (saat qurşağını)
                  nəzərə almaqla müştəriyə əks etdirilən vaxtın tənzimlənməsi
                  üçün lazımdır.
                </li>
                <li>
                  Fayl sistemi platformanın düzgün fəaliyyət göstərməsi üçün
                  zəruri olan tətbiqi proqramdakı meta-məlumatların saxlanılması
                  üçün lazımdır.
                </li>
                <li>
                  Şəbəkəyə qoşulma (mobil şəbəkə və Wi-Fi) mobil tətbiqin
                  meta-məlumatlarının yüklənilməsi imkanlarını izləmək üçün və
                  mobil tətbiqdəki tərəfdaşların və məhsulların kataloqlarının
                  tərkibindəkilərin aktuallığını təmin etmək məqsədilə server
                  tərəfindən məlumatların alınması üçün lazımdır.
                </li>
              </ul>
              <p className="font-light mt-4 pl4">
                - Bundan əlavə, biz kənar xidmətlərdən (məsələn Google Analytics
                kimi) istifadə edə bilərik. Bu kənar xidmətlərin təqdim olunmuş
                informasiyadan necə istifadə olunduğunu əks etdirən öz məxfilik
                siyasəti var. Brauzerinizdə və ya cihazınızda quraşdırılmış
                icazələrdən asılı olaraq yerləşmə haqqında məlumatlar. Siz
                cihazınızın parametrlərində yerləşmənin müəyyənləşdirilməsi
                xidmətini söndürə bilərsiniz. İnteqrasiya edildiyi təqdirdə
                kənar xidmətlərin təqdim etdiyi məlumatlar. Siz Baysart
                Məhsulları ilə qarşılıqlı əlaqə üçün kənar xidmətlərdən istifadə
                edə bilərsiniz (məsələn Facebook, poçt istifadəçiləri və s.). Bu
                cür qarşılıqlı əlaqə zamanı sizin şəxsi məlumatlarınız Baysart
                Məhsullarına ötürülə bilər. Bundan əlavə, kənar xidmətlərin
                məlumatları ilə İnteqrasiya olduğu halda Baysart sizin adınızdan
                digər xidmətlərə daxil olmaq üçün icazəsi istəyə bilər. Siz
                Baysart Məhsulları ilə qarşılıqlı əlaqədə olduğunuz zaman biz
                istənilən vaxt sizin şəxsi məlumatlarınızı istəyə bilərik.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                Məlumatların mühafizəsi:
              </h4>

              <p className="font-light mt-4 pl4">
                - Biz sizin məlumatlarınızın təhlükəsiz saxlanılmasını təmin
                etmək üçün xüsusi tədbirlər görürük. Bu cür tədbirlər
                çərçivəsində sizin məlumatlarınıza icazəsiz giriş və ya onların
                icazəsiz açıqlanması kimi risklərin azaldılmasına yönəlmiş
                xüsusi proseslər və prosedurlar tətbiq olunur. İnformasiya
                təhlükəsizliyini təmin etmək məqsədilə tərəfimizdən zəruri
                təşkilati, texniki və fiziki tədbirlər görülüb. Məlumat bazası
                xidməti məlumatların saxlanılmasında şifrələmədən istifadə edir
                və FİPS 140-2 standartına cavab verir. Ehtiyat nüsxələr də daxil
                olmaqla diskdə şifrələnmiş məlumatlar. Bu xidmət məlumat
                saxlancının şifrələnməsinə tətbiq olunan 256-bit AES
                şifrələrindən istifadə edir, açarlar isə sistem tərəfindən idarə
                olunur. Məlumat saxlancının şifrələnməsi həmişə aktiv rejimdə
                olur və heç vaxt söndürülə bilməz. Nəqliyyat (transport)
                protokolu səviyyəsində məlumatların şifrələnməsi üçün TLS 1.3
                standartından istifadə edilir.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                İnformasiyanın üçüncü tərəflərə açıqlanması:
              </h4>

              <p className="font-light mt-4 pl4">
                - Biz istifadəçilərin məlumatlarını üçüncü tərəflərə
                açıqlamırıq. İstifadəçi məlumatları qanunvericiliyə uyğun olaraq
                səlahiyyətli orqanlar tərəfindən tələb olunduqda, həmin
                orqanlara təqdim edilə bilər.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                Məlumatların təqdim edilməsindən imtina:
              </h4>

              <p className="font-light mt-4 pl4">
                - Biz sizdən şəxsi məlumatlarınızı istəyən zaman sizə onları
                təqdim etməkdən imtina etmək imkanı veririk. Əgər şəxsi
                məlumatlarınızı təqdim etməkdən imtina edirsinizsə, biz sizə
                Baysart Məhsullarına giriş təmin edə bilmirik. Şəxsi
                məlumatlarınızı təqdim etməkdən imtina etmək üçün bu barədə
                hello@baysart.com ünvanına elektron məktub göndərməlisiniz.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                Məlumatların silinməsi
              </h4>

              <p className="font-light mt-4 pl4">
                - Baysart Məhsullarından öz şəxsi məlumatlarınızın silinməsini
                istəyə bilərsiniz. Bu cür sorğu əsasında biz sizin haqqınızda
                toplanılmış şəxsi məlumatları siləcəyik və ondan sonra Baysart
                Məhsullarından istifadəyə zəmanət verə bilməyəcəyik. Sizin şəxsi
                məlumatlarınızın silinməsi üçün hello@baysart.com ünvanına
                müraciət edin.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                İnformasiya və reklam xarakterli bildirişlərin alınmasından
                imtina
              </h4>

              <p className="font-light mt-4 pl4">
                - Baysart-dan informasiya və reklam xarakterli bildirişlərin
                alınmasından imtina edə bilərsiniz. Bunun üçün göndərilmiş
                materiallardakı müvafiq “bildirişlərdən imtina” parametrindən
                istifadə edin və ya öz müraciətinizi hello@Baysart.com ünvanına
                göndərin.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                “Cookies” fayllarının bloklanması
              </h4>

              <p className="font-light mt-4 pl4">
                - Öz brauzerinizdəki parametrlərdən istifadə etməklə “cookies”
                fayllarını silə və ya bloklaya bilərsiniz, lakin bundan sonra
                biz Baysart Məhsullarından istifadəyə zəmanət verə bilmərik. Siz
                öz şəxsi məlumatlarınızın silinməsi, dəyişdirilməsi və ya təqdim
                olunması üçün bizə istənilən vaxt müraciət edə bilərsiniz Bunun
                üçün hello@Baysart.com ünvanına müvafiq qaydada müraciət edin.
                Biz əsassız olaraq təkrarlanan və ya sistematik olan,
                qeyri-proporsional texniki səylər tələb edən (məsələn, ehtiyat
                məlumat daşıyıcılarında saxlanılan məlumatlarla bağlı sorğular),
                digər istifadəçilərin məxfiliyini təhlükə altında qoyan
                sorğuların icrasından imtina edə bilərik. İstənilən halda, biz
                məlumatları silərkən və ya dəyişdirərkən, bu xidməti ödənişsiz
                (pulsuz) yerinə yetiririk (qeyri-proporsional səylər tələb edən
                hallar istisna olmaqla). Nəzərə almaq lazımdır ki, şəxsi hesab
                edilməyən məlumatlar hətta siz Baysart Məhsullarından istifadəni
                dayandırdığınızda və ya şəxsi məlumatların silinməsini
                istədiyinizdə belə saxlanıla bilər.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                Uşaqların məxfiliyi
              </h4>

              <p className="font-light mt-4 pl4">
                - Yalnız 14 yaşına çatmış uşaqlar Baysart Məhsullarından
                istifadə edə bilər. Baysart Məhsulları 14 yaşından aşağı
                şəxslərə (“Uşaqlar”) şamil edilmir. Biz 14 yaşdan aşağı uşaqlar
                barədə şəxsi məlumatları bilərəkdən toplamırıq. Əgər siz
                valideyn və ya himayəçisinizsə, uşaqlarınızın bizə şəxsi
                məlumatlarını təqdim etdiyini öyrəndiyinizdə bizimlə əlaqə
                saxlayın. Biz valideynin razılığı olmadan 14 yaşdan aşağı uşağın
                şəxsi məlumatını topladığımızı bildikdə, biz həmin məlumatları
                serverlərimizdən silmək üçün tədbirlər görürük.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                Hazırkı Məxfilik Siyasətinə dəyişikliklər
              </h4>

              <p className="font-light mt-4 pl4">
                - Biz istənilən vaxt öz Siyasətimizi yeniləmək və ya dəyişdirmək
                hüququnu özümüzdə saxlayırıq və siz bu Məxfilik Siyasətini
                vaxtaşırı olaraq yoxlamalısınız. Bu səhifədə Məxfilik Siyasətinə
                hər hansı dəyişikliklər edildikdən sonra Baysart
                Məhsulundan(Məhsullarından) istifadəni davam etdirməyiniz sizin
                həmin dəyişiklikləri təsdiqlədiyinizi və dəyişdirilmiş Məxfilik
                Siyasətinə riayət etməyə və onunla bağlı öhdəlik daşımağa
                razılaşdığınızı bildirəcək. Hazırkı Məxfilik Siyasətinə hər
                hansı əhəmiyyətli dəyişikliklər etdiyimiz təqdirdə, bu barədə
                sizə elektron poçt ünvanına (təqdim etdiyiniz elektron poçt
                ünvanı) bildiriş göndərməklə və ya vebsaytımızda aydın şəkildə
                bildiriş yerləşdirməklə məlumat verəcəyik.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const modalUserAgreement = () => {
    return (
      <Modal activeModal={activeModalSec} setActiveModal={setActiveModalSec}>
        <div className="relative bg-primary-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-4 rounded-t border-b border-shadow-active">
            <h3 className="text-md leading-5 font-semibold text-active">
              BAYSART ÜZVLÜK MÜQAVİLƏSİ
            </h3>
            <button
              onClick={() => setActiveModalSec(!activeModalSec)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6 space-y-6 overflow-y-scroll h-screen">
            <div className="mb-4">
              <h4 className="text-lg leading-[25px] mb-3">TƏRƏFLƏR:</h4>
              <p className="font-light pl-4">
                Üzvlük Müqaviləsi ("Müqavilə"); bir tərəfdən www.baysart.com və
                mobil tətbiqlərin sahibi olan, VÖEN:1703115231, BAYSART Məhdud
                Məsuliyyətli Cəmiyyəti (“BAYSART”) ilə; www.baysart.com və onun
                mobil tətbiqi (“Platforma”) arasında internet saytının
                istifadəçisi və ya mobil tətbiqlərin üzvü olan istifadəçi
                (“Üzv”) tərəfindən aşağıdakı şərtləri qəbul etməklə, Üzvün
                BAYSART tərəfindən təklif olunan Xidmətlərdən faydalanması ilə
                əlaqədar şərtləri müəyyən etmək üçün qərar qəbul edilmişdir.
                BAYSART və Üzv Üzvlük Müqaviləsində ayrı-ayrılıqda “Tərəf” və
                birlikdə “Tərəflər” adlandırılacaq.
              </p>
            </div>
            <div className="mb-4">
              <h4 className="text-lg leading-[25px] mb-3">
                ANLAYIŞLAR VƏ TƏRİFLƏR:
              </h4>
              <p className="font-light pl-4">
                Platforma Biznes Modeli (PBM): Satıcılar ilə Alıcıları Platforma
                üzərindən birləşdirərək Satıcıların Platformadan həyata
                keçirdiyi məhsul və/və ya xidmət satışlarına ticarət təmsilçisi
                olaraq vasitəçilik etdiyi modeli ifadə edir. Alıcı: Platformadan
                məhsul və/və ya xidmətlər alan fiziki və/və ya hüquqi şəxsləri
                ifadə edir. Məxfilik Siyasəti və Məlumatların Qorunması
                Bəyanatı: BAYSART-ın şəxsi məlumatlar və kukilərdən istifadə ilə
                bağlı ümumi məxfilik siyasətini, o cümlədən BAYSART-ın Platforma
                vasitəsilə Üzvlər tərəfindən ötürülən şəxsi məlumatlardan hansı
                məqsədlər üçün və necə istifadə edəcəyini tənzimləyən ve
                Platforma üzərindən Məxfilik Siyasəti bölməsində əldə edilə
                bilən mətni ifadə edir.. Mənim Hesabım Səhifəsi: Üzvün
                Platformada yerləşən müxtəlif tətbiqlərdən və Xidmətlərdən
                yararlana bilməsi üçün lazımi əməliyyatları həyata keçirə
                biləcəyi, fərdi məlumatlarını və tətbiq əsasında özündən tələb
                edilən məlumatlarını daxil etdiyi sadəcə əlaqədər Üzv tərəfindən
                müəyyən edilən istifadəçi adı və şifrə ilə əlçatan Üzvə özəl
                səhifəni ifadə edir. Xidmətlər: Üzvlərin Üzvlük Müqaviləsi
                daxilində müəyyən olan iş və hərəkətlərini həyata keçirməsini
                təmin etmək məqsədi ilə BAYSART tərəfindən ortaya qoyulan
                tətbiqləri ifadə edir. Satıcı: PBM butiklərindı BAYSART ilə
                etdiyi SATICI TƏRƏFDAŞLIĞI VƏ ELAN MÜQAVİLƏSİ çərçivəsində
                Platformaya üzv olan ve yaratdığı hesab üzərindən yayımladığı
                elanlar vasitəsi ilə müxtəlif məhsil və/və ya xidmətləri satış
                üçün təklif edən fiziki və/və ya hüquqi şəxsləri ifadə edir.
              </p>
              <h4 className="text-lg leading-[25px] mb-3">
                Tərəflərin hüquq və öhdəlikləri
              </h4>
              <p className="font-light mt-4 pl-4">
                3.1. Üzvlük statusu əldə etmək üçün Üzv olmaq istəyən istifadəçi
                Platformada Üzvlük Müqaviləsini təsdiq etməli və burada tələb
                olunan məlumatları düzgün və aktual məlumatlarla doldurmalı,
                Üzvlük ərizəsi BAYSART tərəfindən qiymətləndirilməli və
                təsdiqlənməlidir. Üzv olmaq istəyən istifadəçi 14 (on dörd)
                yaşından yuxarı olmalıdır.Təsdiq prosesinin başa çatması və Üzvə
                bildiriş göndərilməsi ilə Üzvlük statusu başlayır və beləliklə,
                Üzv Üzvlük Müqaviləsində və Platformanın müvafiq hissələrində
                göstərilən hüquq və öhdəliklərə nail olur. Sözügedən Üzvlük
                Müqaviləsini doldurarkən dəqiq və aktual məlumat verməyən Üzv
                bununla bağlı yarana biləcək bütün zərərlərə görə şəxsən
                məsuliyyət daşıyır.
              </p>
              <p className="font-light mt-4 pl-4">
                3.2. Üzvlük hüquq və vəzifələrinin hansı şəxsə aid olması ilə
                bağlı mübahisə və bu mövzuda sözügedən şəxslər BAYSART-dan sorğu
                tələb edərsə, müvafiq Üzvlük hesabından istifadə edərək hər
                hansı bir Xidmət üçün BAYSART-a ödəmə edən ən son şəxsin Üzvlük
                hesabının sahibi olduğunu qəbul edərək, buna uyğun hərəkət
                ediləcəkdir.
              </p>
              <p className="font-light mt-4 pl-4">
                3.3. Üzv Platformada baxılan elanlarla bağlı hər hansı hüquqi
                hərəkətə və ya satınalma prosesinə başlamaq istədikdə, O,
                Platformada olanlar da daxil olmaqla, bütün zəruri hüquqi
                öhdəlikləri və hüquqi prosedurları yerinə yetirməli olduğunu,
                sözügedən öhdəlik və prosedurlarla bağlı BAYSART-ın hər hansl
                bir məlumat və məsuliyyətinin olmadığını qəbul və bəyan edir.
              </p>
              <p className="font-light mt-4 pl-4">
                3.4. PBM butiklərində, başqa sözlə BAYSART-ın Platformada
                yerləşən hər hansı bir məhsul və ya xidmətin satıcısı mövqesində
                olmaması halında "yer təminedici" mövqesindədir. BAYSART, PBM
                butiklerində Platformada yerləşən və özü tərəfindən
                yayınlanmamış heç bir görüntü, yazılı və ya digər məzmunun
                gerçəkliyindən, etibarlılığından, doğruluğundan ya da hüquqa
                uygunluğundan məsul deyildir və sözügedən məzmunların
                doğruluğunu yoxlama kimi bir öhdəliyi mövcud deyil.
              </p>
              <p className="font-light mt-4 pl-4">
                3.5. Üzv PBM butiklərində Platforma vasitəsilə hər hansı
                Satıcıdan ediləcək alış-verişlərdə bağlanacaq məsafədən satış
                müqavilələrində Satıcının satıcı tərəf, özünün isə alıcı tərəf
                olduğunu; BAYSART-ən yuxarıda qeyd olunan məsafədən satış
                müqaviləsinin tərəfi olmadığını; buna görə də, o, qəbul edir və
                bəyan edir ki, yalnız Satıcı mövcud istehlakçı hüququ
                qanunvericiliyi və digər qanunvericilik aktları çərçivəsində
                bütün məsələlərdə şəxsən məsuliyyət daşıyır. Bu çərçivədə PBM
                butiklərində sərgilənən və satılan bütün məhsulların keyfiyyəti,
                qanunvericiliyə uyğunluğu, zəmanət şəhadətnaməsinin verilməsi,
                faktura və digər zəruri sənədlərin çatdırılması və sair digər
                lazımi sənədin təslimindən və satış sonrası ehtiyac duyulan
                servis və başqa xidmətlərindən və məhsulların vaxtında təslim
                edilməsindən Satıcı şəxsən və müstəsna olaraq məsuliyyət
                daşıyacaqdır.
              </p>
              <p className="font-light mt-4 pl-4">
                3.6. PBM butiklərində, BAYSART, vasitəçi xidmət təminatçısı
                statusuna və Satıcı ilə bağlanmış müqavilədəki səlahiyyətinə
                görə; Üzvün Satıcıdan alınacaq məhsullarla bağlı Satıcı adından
                məhsulun qiymətini toplamaq səlahiyyətinə malik olacaq və
                BAYSART-a ediləcək bu ödənişlə Alıcı Satıcıya ödəmə öhdəliyindən
                azad olacaq və həmçinin Satıcıya pul ödəməli olmayacaqdır.
              </p>
              <p className="font-light mt-4 pl-4">
                3.7. Üzv Platformada apardığı əməliyyatlarda və yazışmalarında
                Üzvlük Müqaviləsinin müddəalarına, Platformada göstərilən bütün
                şərtlərə, qüvvədə olan qanunvericiliyə və əxlaq qaydalarına
                uyğun hərəkət edəcəyini qəbul etdiyini bəyan edir. Üzvün
                Platforma daxilindəki əməliyyat və hərəkətlərinə görə hüquqi və
                cinayət məsuliyyəti ona məxsusdur.
              </p>
              <p className="font-light mt-4 pl-4">
                3.8. BAYSART müvafiq qanunvericiliyə uyğun olaraq səlahiyyətli
                orqanların tələbi ilə Üzvün məlumatını qeyd olunan orqanlarla
                paylaşa bilər.
              </p>
              <p className="font-light mt-4 pl-4">
                3.9. Platformaya üzvlük sırasında və/və ya alış-veriş sırasında
                Üzvlərdən alınan fərdi məlumatlar, Üzv və/və ya Satıcılar
                arasında saxtakarlıq, dələduzluq, Platformanın sui-istifadə
                edilməsi, Qanun çərçivəsində cinayət törətməyə qadir mözvularda
                çıxan mübahisələrdə, yalnızca tələb edilən mözvu ilə məhdud
                olmaq şərtilə tərəflərin öz qanuni hüquqlarından istifadə etmək
                məqsədiylə və sadəcə bu çərçivədə məhdudlaşan mübahisədə tərəf
                ola biləcək digər Üzv və/və ya Satıcılara ötürə biləcəkdir.
              </p>
              <p className="font-light mt-4 pl-4">
                3.10. Üzvün Hesabım Səhifəsinə daxil olmaq və Platformada
                əməliyyatları yerinə yetirmək üçün tələb olunan istifadəçi adı
                və parol məlumatı Üzv tərəfindən yaradılmaqda olub və sözügedən
                məlumatların təhlükəsizliyi və məxfiliyi tamamilə Üzvün
                məsuliyyətindədir. Üzv istifadəçi adı və şifrəsi ilə həyata
                keçirilən əməliyyatların onun tərəfindən həyata keçirildiyini və
                bu əməliyyatlardan irəli gələn məsuliyyətin əvvəlcədən ona
                məxsus olduğunu, bu şəkildə həyata keçirilən işləri və
                əməliyyatları həyata keçirmədiyinə dair heç bir etiraz edə
                bilməyəcəyini və bu etiraz əsasında öhdəliklərini yerinə
                yetirməkdən çəkinməyəcəyini qəbul edir, bəyan edir və öhdəsinə
                götürür.
              </p>
              <p className="font-light mt-4 pl-4">
                3.11. Üzv, xüsusilə aşağıda sadalanan hallarda, Platformadan
                qeyri-qanuni və qeyri-etik şəkildə istifadə etməyəcəkdir.
                <ul className="list-disc">
                  <li>
                    {' '}
                    Hər hansı şəxsin adından verilənlər bazası, qeyd və ya
                    kataloq yaratmaq, idarə etmək, yeniləmək və ya dəyişdirmək
                    üçün Platformadan istifadə;
                  </li>
                  <li>
                    Platformanın bütövlükdə və ya qismən pozulması,
                    dəyişdirilməsi və ya tərs mühəndislik məqsədi ilə
                    istifadəsi;
                  </li>
                  <li>
                    {' '}
                    Yalan məlumatlardan və ya başqa şəxsin məlumatlarından
                    istifadə etməklə əməliyyatlar etmək, yanlış və ya aldadıcı
                    şəxsi məlumatlardan, o cümlədən yanlış və ya yanıltıcı
                    yaşayış ünvanı, e-poçt ünvanı, əlaqə, ödəniş və ya hesab
                    məlumatlarından istifadə etməklə qeyri-real Üzvlük hesabları
                    yaratmaq və bu hesabların Üzvlük Müqaviləsi və ya qüvvədə
                    olan qanunvericiliyə zidd olaraq istifadə edilməsi, başqa
                    Üzvün hesabından icazəsiz istifadə edilməsi, başqa şəxs kimi
                    təqdim edilərək və ya yanlış ad altında əməliyyatların
                    tərəfi və ya iştirakçısı olması;
                  </li>
                  <li>
                    {' '}
                    Şərh və reytinq bölməsinin; Platformadaki rəyləri
                    Platformadan kənar yayınlamaq kimi Platforma xarici
                    məqsədlər üçün istifadə edilməsi və ya sistemləri
                    manipulyasiya etmək üçün nəzərdə tutulan məqsədlərdən başqa
                    məqsədlər üçün istifadə edilməsi;
                  </li>
                  <li>
                    {' '}
                    Şərh bölməsində mövzusu cinayət təşkil edən, vebsaytdakı
                    qaydaların, qüvvədə olan qanunvericiliyin və ya beynəlxalq
                    müqavilələrin pozulmasına səbəb olan və ya onu təşviq edən,
                    qeyri-qanuni, təhdidedici, reklam və/və ya marketinq məzmunu
                    ehtiva edən, təhqiredici, təhqiramiz ifadələr ehtiva edən və
                    söyüş, təhqiredici, vulqar, pornoqrafik və ya əxlaqsız,
                    cəmiyyətin hamı tərəfindən qəbul edilmiş qaydalarına zidd
                    olaraq, şəxsi hüquqlara zidd, əqli və sənaye hüquqlarına
                    zidd, haqsız rəqabət yaradan və/və ya buna bənzər hər hansı
                    məzmunun Platformada paylaşılması;
                  </li>
                  <li>
                    {' '}
                    Platformaya, Platformanın məlumat bazasına, Platformada olan
                    hər hansı məzmuna zərərli virus və ya hər hansı digər
                    texnologiyanın yayılması;
                  </li>
                  <li>
                    {' '}
                    Platforma tərəfindən müəyyən edilmiş rabitə və texniki
                    sistemlərdə əsassız və ya qeyri-mütənasib şəkildə böyük
                    yükləmələr yaradacaq və ya texniki işə zərər verəcək
                    fəaliyyətlərlə məşğul olmaq, BAYSART-ın əvvəlcədən yazılı
                    razılığı olmadan Platformada avtomatik proqramlar,, robot,
                    web crawler, web, məlumatların çıxarılması (data mining) v’
                    məlumatların skan edilməsi (data crawling) kimi "screen
                    scraping" proqram təminatından və ya sistemlərdən istifadə
                    etməklə və bu şəkildə Platformada olan məzmunun hər hansı və
                    ya hamısının icazəsiz surətdə çıxarılması, nəşri və ya
                    istifadəsi.
                  </li>
                </ul>
              </p>
              <p className="font-light mt-4 pl-4">
                3.12. Üzvün Platformanı 3.11. maddəsi başda olmaqala, BAYSART,
                Üzvün Platformadan Müqavilənin hər hansı maddəsinə zidd olaraq
                istifadə edərsə, Platformadan tam və/və ya bir hissəsini, qismən
                və/və ya tamamilə istifadəsinə mane olmaq hüququna malikdir. Bu
                kontekstdə BAYSART 3.11. maddədə göstərilən şərhlər və nöqtələr
                bölməsini daim nəzərdən keçirəcək., 3.11. maddədə qeyd edildiyi
                kimi şərh aşkar edilərsə, sözügedən məzmun Platformada dərc
                edilməyəcək.Üzv yekun qərarın müstəsna olaraq BAYSART-a aid
                olduğunu qəbul edir, bəyan edir və öhdəsinə götürür.
              </p>
              <p className="font-light mt-4 pl-4">
                3.13. Üzv Platformada əməliyyatları heç bir şəkildə BAYSART-a
                maddi və texniki cəhətdən Platformaya zərər verməyəcək şəkildə
                həyata keçirməyə borcludur. Üzv, Platformaya zərər verəcək hər
                cür proqram, virus, proqram, lisenziyasız məhsullar, trojan
                atları və s. qarşısını almaq üçün lazımi qoruyucu proqram
                təminatı və lisenziyalı məhsullardan istifadə də daxil olmaqla,
                bütün zəruri tədbirləri gördüyünü qəbul edir və öhdəsinə
                götürür.Üzv həmçinin Robot və ya avtomatik giriş üsulları ilə
                Hesab Səhifəsinə daxil olmayacağı ilə razılaşır.
              </p>
              <p className="font-light mt-4 pl-4">
                3.14. Platformadan və ya onun üzərindəki məzmundan Üzvlük
                Müqaviləsi ilə müəyyən edilmiş istifadə şərtləri və ya qüvvədə
                olan qanunvericiliyin müddəaları pozulmaqla istifadə edilməsi
                qanunsuzdur; BAYSART-ın əlaqədar tələb, iddia və təqib hüquqları
                qorunur.
              </p>
              <h4 className="text-lg leading-[25px] my-3">MƏXFİLİK:</h4>
              <p className="font-light mt-4 pl4">
                4.1. BAYSART, təklif olunan Xidmətlərdən yararlanmaq üçün
                Platforma vasitəsilə Üzv tərəfindən təqdim edilən fərdi
                məlumatların 998-IIIQ - Fərdi məlumatlar haqqında qanunu da
                daxil olmaqla, hər cür qanunvericiliyə uyğun işlənməsinə,
                təhlükəsizliyinə və qorunmasına önəm verir. Bu kontekstdə
                BAYSART Məxfilik Siyasəti bölməsində mövcud Məxfilik Siyasətinə
                uyğun olaraq Üzv tərəfindən təqdim olunan şəxsi məlumatları
                toplayır, istifadə edir, ötürür və emal edir. Məxfilik Siyasəti
                Üzvlük Müqavilənin ayrılmaz hissəsidir.
              </p>
              <p className="font-light mt-4 pl4">
                4.2. Xidmətlərdən istifadə etməklə və/və ya hesab yaratmaqla Üzv
                şəxsi məlumatlarının Məxfilik Siyasətində qeyd olunduğu kimi
                toplanması, istifadəsi, ötürülməsi və digər işlənməsinə açıq və
                sərbəst şəkildə razılıq verir. Fərdi məlumatların istifadəsi
                şərtləri və bununla bağlı hüquqlarınız haqqında ətraflı məlumat
                almaq üçün məxfilik siyasətimizi nəzərdən keçirə və
                hello@baysart.com elektron poçt ünvanına e-poçt göndərməklə
                qanunvericilikdən irəli gələn hüquqlarınızı həyata keçirə
                bilərsiniz.
              </p>
              <p className="font-light mt-4 pl4">
                4.3. Üzv tərəfindən Platformada bəyan edilən və paylaşılmasına
                razılıq verilən şəxsi məlumatlar; Üzvlük Müqaviləsi ilə müəyyən
                edilmiş öhdəliklərin icrası, Platformanın fəaliyyəti üçün tələb
                olunan müraciətlərin icrası, Üzv üçün müxtəlif üstünlüklərin
                təmin edilməsi və təklif edilməsi və Üzv üçün xüsusi reklam,
                satış, marketinq, sorğu, oxşar məqsədlər üçün hər cür elektron
                kommunikasiya, profilləşdirmə, statistik araşdırmalar məqsədiylə
                BAYSART və ya onun biznes tərəfdaşları tərəfindən Məxfilik
                Siyasətinə uyğun olaraq toplanır, saxlanılır, emal edilir,
                istifadə olunur və müqavilə münasibətlərimiz əsasında üçüncü
                tərəflərlə paylaşıla bilnir.
              </p>
              <p className="font-light mt-4 pl4">
                4.4. Üzv şəxsi məlumatlarının BAYSART tərəfindən bu şəkildə
                istifadə edilməsinə və saxlanmasına razı olduğunu qəbul edir və
                bildirir. BAYSART, sözügedən şəxsi məlumatların 998-IIIQ - Fərdi
                məlumatlar haqqında Qanuna uyğun olaraq təhlükəsiz saxlanılması,
                icazəsiz girişin və qeyri-qanuni məlumatların işlənməsinin
                qarşısını almaq üçün bütün lazımi tədbirləri görəcəkdir. Üzv
                998-IIIQ - Fərdi məlumatlar haqqında Qanuna uyğun olaraq şəxsi
                məlumatları üzərində malik olduğu hüquqlardan istifadə etmək və
                bu məlumatlara istənilən vaxt dəyişiklik və ya yeniliklər etmək
                hüququna malikdir.
              </p>
              <h4 className="text-lg leading-[25px] my-3">ƏQLİ MÜLKİYYƏT:</h4>

              <p className="font-light mt-4 pl4">
                “Baysart” brendinin və loqosunun hər cür əqli mülkiyyəti,
                “Baysart” mobil tətbiqi və Platformanın dizaynı, proqram
                təminatı, domen adı və BAYSART tərəfindən yaradılmış istənilən
                brend, dizayn, loqo, ticarət geyimi, şüar və digər bütün
                məzmunların hüquqi əqli mülkiyyəti olaraq öz mülkiyyətidir. Üzv
                yazılı icazəsi olmadan BAYSART və ya ona bağlı şirkətlərin əqli
                mülkiyyət hüquqlarından istifadə edə, paylaşa, paylaya, nümayiş
                etdirə, çoxalda və ya törəmə işlər yarada bilməz.Üzv BAYSART-ın
                yazılı icazəsi olmadan mobil tətbiqin və ya Platformanın tam və
                ya bir hissəsindən başqa mühitdə istifadə edə bilməz. Üzv üçüncü
                şəxslərin və ya BAYSART-ın əqli mülkiyyət hüquqlarını pozan
                hərəkətlər edərsə, Üzv BAYSART-ın və/və ya sözügedən üçüncü
                şəxsin bütün birbaşa və dolayı zərər və xərclərini kompensasiya
                etməyə borcludur.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                MÜQAVİLƏDƏ DƏYİŞİKLİKLƏR:
              </h4>

              <p className="font-light mt-4 pl4">
                BAYSART, öz mülahizəsinə əsasən, mövcud qanunvericiliyin
                müddəalarına zidd olmamaq şərti ilə, birtərəfli qaydada Üzvlük
                Müqaviləsini və Platformada olan hər hansı siyasət, müddəa və
                şərtləri, o cümlədən Məxfilik Siyasətini məqsədəuyğun hesab
                etdiyi istənilən vaxt təktərəfli dəyişiklik edə bilər.Üzvlük
                Müqavilənin dəyişdirilmiş müddəaları Platformada elan edildiyi
                tarixdə qüvvəyə minəcək, qalan müddəalar isə qüvvədə qalacaq və
                öz şərtləri və nəticələrini davam etdirəcək.
              </p>

              <h4 className="text-lg leading-[25px] my-3">FORS-MAJOR:</h4>

              <p className="font-light mt-4 pl4">
                Üsyan, embarqo, hökumət müdaxiləsi, iğtişaş, işğal, müharibə,
                səfərbərlik, tətil, lokavt, əmək aksiyaları və ya boykotlar
                daxil olmaqla işçi-işəgötürən mübahisələri, kiberhücum,
                kommunikasiya problemləri, infrastruktur və internet
                nasazlıqları, sistemin təkmilləşdirilməsi və ya yeniləmə işləri
                və Bu səbəbdən baş verə biləcək nöqasanlar, elektrik kəsilməsi,
                yanğın, partlayış, tufan, sel, zəlzələ, köç, epidemiya və ya
                başqa bir təbii fəlakət və ya BAYSART-ın nəzarətindən kənar
                gerçəkləşən, təqsiri nəticəsində baş verməmiş və ağlabatan
                şəkildə proqnozlaşdırıla bilməyən digər hadisələr ("Forj-Major")
                BAYSART-ın Üzvlük Müqaviləsi ilə bağlı öhdəliklərini yerinə
                yetirməsinə mane olarsa və ya gecikdirərsə, BAYSART Fors-major
                halları nəticəsində maneə törədilən və ya gecikdirilmiş
                öhdəliklərinə görə məsuliyyət daşıya bilməz və bu vəziyyət
                Üzvlük Müqavilənin pozulması kimi qiymətləndirilə bilməz.
              </p>

              <h4 className="text-lg leading-[25px] my-3">
                MÜXTƏLİF MÜDDƏALAR:
              </h4>

              <p className="font-light mt-4 pl4">
                8.1. Dəlil müqaviləsi; Üzvlük Müqaviləsi ilə bağlı yarana
                biləcək mübahisələr halında, Üzv BAYSART-ın verilənlər bazası və
                serverlərində saxlanılan rəsmi kitablar və kommersiya qeydləri
                və e-arxiv qeydləri, elektron məlumat və kompüter qeydlərinin
                məcburi, yekun və müstəsna sübut olacağını və dəlil müqaviləsi
                xarakteri daşıdığını qəbul edir.
              </p>

              <p className="font-light mt-4 pl4">
                8.2. Tətbiq Edilən Qanun və Mübahisələrin Həlli; Üzvlük
                Müqaviləsi müstəsna olaraq Azərbaycan Respublikasının qanunları
                ilə tənzimlənir. Bu Müqavilənin təfsiri və tətbiqi zamanı
                Azərbaycan Respublikasının Qanunları tətbiq edilir.
                Qanunvericilikdə hər hansı dəyişiklik baş verərsə bu Müqavilə
                yeni qanunvericiliklə keçərlidir. Azərbaycan Respublikası
                Məhkəmələri bu Müqavilədən irəli gələ biləcək hər hansı
                mübahisəni həll etmək səlahiyyətinə malikdirlər.
              </p>
              <p className="font-light mt-4 pl4">
                8.3. Bildiriş; DSM Üzvlə qeydiyyat zamanı təqdim etdiyi e-poçt
                ünvanı və ya telefon nömrəsinə zəng edərək SMS göndərməklə əlaqə
                saxlayacaq. Üzv öz e-poçt ünvanını və telefon nömrəsini aktual
                saxlamağa borcludur.
              </p>
              <p className="font-light mt-4 pl4">
                8.4. Üzvlük Müqavilənsinin Bütünlüyü və Bölünə bilməsi; Üzvlük
                Müqaviləsi bu mövzuda Tərəflər arasında bütün razılaşmanı təşkil
                edir. Üzvlük Sazişinin hər hansı müddəası hər hansı səlahiyyətli
                məhkəmə, arbitraj məhkəməsi və ya inzibati orqan tərəfindən
                tamamilə və ya qismən etibarsız və ya icra edilə bilməz və ya
                əsassız olduğu müəyyən edilərsə, Üzvlük Müqaviləsi belə
                etibarsızlıq, icra edilməzlik və ya əsassızlıq dərəcəsinə qədər
                bölünə bilən hesab edilir və digər müddəalar tam olaraq qüvvədə
                qalmağa davam edəcəkdir.
              </p>
              <p className="font-light mt-4 pl4">
                8.5. Üzvlük Müqaviləsinin Transferi; Üzv BAYSART-ın əvvəlcədən
                yazılı razılığı olmadan Üzvlük Müqaviləsindəki hüquq və ya
                vəzifələrini tam və ya qismən başqasına verə bilməz.
              </p>
              <p className="font-light mt-4 pl4">
                8.6. Dəyişiklik və İmtina; Tərəfin Üzvlük Müqaviləsində ona
                verilmiş hər hansı hüququ həyata keçirməməsi və ya icra etməməsi
                bu hüquqdan imtina etmə və ya belə hüququn sonra həyata
                keçirilməsinə və ya icra etməsinə mane olmur.
              </p>

              <p className="font-light mt-4 pl4">
                8 (səkkiz) maddədən ibarət olan Üzvlük Müqaviləsi üzv tərəfindən
                hər bir müddəanın oxunması və tam başa düşülməsi ilə elektron
                mühitdə təsdiq olunaraq təsdiq edildiyi andan qüvvəyə minmişdir.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  if (!isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="max-w-[1800px] mx-auto">
      {/* <Breadcrumb categories={[{ name: 'Baysartda satılır' }]} /> */}
      {step === 1 ? (
        <React.Fragment>
          {renderBanner()}

          <h4 className="text-center mt-[37px] text-lg leading-[25px]">
            {t('sell-on.get_started')}
          </h4>
          <p className="text-center font-light text-md leading-5 w-3/4 sm:w-[375px] mx-auto mt-[29px]">
            {t('sell-on.overview')}
          </p>

          <hr className="my-4 h-0 border-shadow-disabled" />

          {renderHowItWorks()}

          {renderStartSellingOnBaysart()}
        </React.Fragment>
      ) : step === 2 || step === 3 || step === 4 || step === 5 ? (
        <div>
          <h4 className="text-xl md:text-lg sm:text-lg leading-[39px] md:leading-[25px] sm:leading-[25px] text-center mt-14">
            {t('sell-on.title')}
          </h4>
          <div className="mt-10">
            <Steps
              step={
                step === 2
                  ? 1
                  : step === 3
                  ? 3
                  : step === 4
                  ? 3
                  : step === 5
                  ? 4
                  : 1
              }
            />
          </div>
          {step === 2 ? (
            <div className="max-w-[1800px] mt-10">
              <div className="bg-gray-50 text-[#333] w-full rounded font-[sans-serif] overflow-hidden">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
                  <div className="lg:col-span-2 py-10 px-6">
                    <h1 className="text-xl font-bold">
                      {t('sell-on.welcome')}
                    </h1>
                    <p className="mt-4 text-md text-gray-400">
                      {t('sell-on.text-welcome')}
                    </p>

                    <div className="flex w-full justify-center mt-10">
                      <button
                        onClick={e => handleNext(e, 2)}
                        className="w-40 h-[60px] flex justify-center items-center bg-hover rounded-[3px] mr-[30px]"
                      >
                        <span className="font-bold text-sm leading-3 text-white">
                          {t('sell-on.go')}
                        </span>
                      </button>
                      <button
                        onClick={() => setStep(1)}
                        className="w-40 h-[60px] flex justify-center items-center bg-white border"
                      >
                        <span className="font-bold text-sm leading-3 text-active">
                          {t('sell-on.right-now')}
                        </span>
                      </button>
                    </div>
                  </div>
                  <img
                    src="https://readymadeui.com/cardImg.webp"
                    className="w-full h-full object-cover shrink-0"
                  />
                </div>
              </div>
            </div>
          ) : step === 3 ? (
            <div className="w-full h-full mt-10">
              <div className="p-4 max-w-[1300px] mx-auto " id="baysart-form">
                <div className="font-[sans-serif] text-gray-800 bg-gray-100 px-6 py-12 shadow-header">
                  <div className="grid lg:grid-cols-2 gap-8 max-lg:max-w-2xl mx-auto">
                    <div className="text-left">
                      <h2 className="text-4xl font-extrabold mb-6">
                        Mağaza detalları
                      </h2>

                      <div className="flex flex-col w-full items-center mt-[35px]">
                        <div>
                          <AutoComplete
                            id="street"
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                            onPlaceSelected={place =>
                              handleChangeAddress(place)
                            }
                            placeholder="Street"
                            inputAutocompleteValue={street}
                            options={{
                              types: [
                                ['address', 'establishment', 'region', 'name'],
                              ],
                              componentRestrictions: { country: 'az' },
                            }}
                            className="w-[560px] sm:w-[320px] h-[50px] bg-white rounded-[5px] mb-5 outline-none p-4 font-light text-md leading-5"
                          />
                        </div>

                        <div>
                          <AutoComplete
                            id="street2"
                            placeholder="Street 2"
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                            inputAutocompleteValue={street2}
                            onPlaceSelected={place =>
                              handleChangeAddress(place)
                            }
                            type="text"
                            options={{
                              types: [
                                ['address', 'establishment', 'region', 'name'],
                              ],
                              componentRestrictions: { country: 'az' },
                            }}
                            className="w-[560px] sm:w-[320px] h-[50px] bg-white rounded-[5px] mb-5 outline-none p-4 font-light text-md leading-5"
                          />
                        </div>

                        <div>
                          <input
                            name="city"
                            id="city"
                            type="text"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            placeholder="City"
                            className="w-[560px] sm:w-[320px] h-[50px] bg-white rounded-[5px] mb-5 outline-none p-4 font-light text-md leading-5"
                          />
                        </div>

                        <div>
                          <input
                            name="zip-code"
                            id="zip-code"
                            type="text"
                            value={zipCode}
                            onChange={e => setZipCode(e.target.value)}
                            placeholder="Post/Zip Code"
                            className="w-[560px] sm:w-[320px] h-[50px] bg-white rounded-[5px] mb-5 outline-none p-4 font-light text-md leading-5"
                          />
                        </div>

                        <div>
                          <input
                            name="region"
                            id="region"
                            type="text"
                            value={region}
                            onChange={e => setRegion(e.target.value)}
                            placeholder="Region"
                            className="w-[560px] sm:w-[320px] h-[50px] bg-white rounded-[5px] mb-5 outline-none p-4 font-light text-md leading-5"
                          />
                        </div>

                        <div className="flex w-full justify-center mt-10">
                          <button
                            onClick={e => handleNext(e, 3)}
                            className="w-40 h-[60px] flex justify-center items-center bg-hover rounded-[3px] mr-[30px]"
                          >
                            <span className="font-bold text-sm leading-3 text-white">
                              {t('sell-on.continue')}
                            </span>
                          </button>
                          <button
                            onClick={() => setStep(1)}
                            className="w-40 h-[60px] flex justify-center items-center bg-white"
                          >
                            <span className="font-bold text-sm leading-3 text-active">
                              {t('sell-on.skip')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <img
                        src={WorkWithUs}
                        alt="Placeholder Image"
                        className="rounded-lg object-cover w-full h-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 5 ? (
            <div className="w-full h-[282px] bg-primary-white mt-10">
              <div className="flex flex-col items-center justify-center h-full">
                <img src={SuccessIcon} alt="Success" />
                <h4 className="my-[35px]">{t('sell-on.success')}</h4>
                <button className="w-[220px] h-[30px] bg-hover rounded-[3px]">
                  <span
                    className="text-sm leading-3 text-white"
                    onClick={() => {
                      window.open('https://api.baysart.com/admin', '_blank');
                      navigate('/');
                    }}
                  >
                    {t('sell-on.store')}
                  </span>
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {modalRegulations()}
      {modalUserAgreement()}
    </div>
  );
};

export default SellOnBaysart;
