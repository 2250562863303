import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CiCoins1,
  CiCreditCard1,
  CiGlobe,
  CiHome,
  CiLocationOn,
  CiLock,
  CiLogout,
  CiSearch,
  CiShoppingBasket,
} from 'react-icons/ci';
import { IoIosNotificationsOutline } from 'react-icons/io';
import '../../assets/styles/commons.css';
// import { VscAccount } from "react-icons/vsc";
import { PiRobotLight } from 'react-icons/pi';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const AccountSidebar = ({ activePage }) => {
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('userData');
    navigate('/');
    window.location.reload();
  };

  const links = [
    {
      text: t('account-settings.home'),
      to: '/',
      icon: <CiHome />,
    },
    {
      text: t('account-settings.profile'),
      to: '/account/profile',
      icon: <PiRobotLight />,
    },
    {
      text: t('account-settings.account-security'),
      to: '/account/change-password',
      icon: <CiLock />,
    },
    {
      text: t('account-settings.my-orders'),
      to: '/account/orders',
      icon: <CiShoppingBasket />,
    },
    {
      text: t('account-settings.coupons'),
      to: '/account/my-coupons',
      icon: <CiCreditCard1 />,
    },
    {
      text: t('account-settings.my-history'),
      to: '/account/history',
      icon: <CiSearch />,
    },
    {
      text: t('account-settings.credit-balance'),
      to: '/account/my-credit-account',
      icon: <CiCoins1 />,
    },
    {
      text: t('account-settings.addresses'),
      to: '/account/my-addresses',
      icon: <CiLocationOn />,
    },
    {
      text: t('account-settings.country-region-language'),
      to: '/account/localisation',
      icon: <CiGlobe />,
    },
    {
      text: t('account-settings.notifications'),
      to: '/account/notification-permissions',
      icon: <IoIosNotificationsOutline />,
    },
  ];

  return (
    <div className="flex accountSidebar">
      <div
        className={`p-5 pt-8 w-72 duration-300 ease-in relative shadow-header leftSidebar`}
      >
        <ul className="pt-2">
          {links.map((link, index) => (
            <>
              <li
                key={index}
                className={`flex items-center gap-x-4 cursor-pointer p-2 hover:bg-primary-white rounded-md mt-2`}
                onClick={() => navigate(link.to)}
              >
                <span className="text-md block float-left">
                  {link.icon ? link.icon : <CiHome />}
                </span>
                <span className={`text-base font-medium flex-1 duration-200`}>
                  {link.text}
                </span>
              </li>
            </>
          ))}
          <li>
            <a
              href="/"
              onClick={() => handleLogout()}
              className="flex items-center gap-x-4 cursor-pointer p-2 hover:bg-primary-white rounded-md"
            >
              <CiLogout className="text-md block float-left" />
              <span>{t('my-orders.logout')}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountSidebar;
