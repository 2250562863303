import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import '../../assets/styles/commons.css';
import ProductCard from '../../components/ProductCard';
import coreService from '../../services/core.service';
import { useEffectOnce } from '../../utils/useEffectMiddleware';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Collections = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [collection, setCollection] = useState({});
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortedProducts, setSortedProducts] = useState([]);
  const navigate = useLanguageNavigate();
  useEffectOnce(() => {
    handleGetCollection();
  }, [params]);

  const onSelectChange = useCallback(
    selected => {
      switch (selected.target.value) {
        case '-1':
          setSortedProducts(products);
          break;
        case 'a-z':
          setSortedProducts(
            products.sort((a, b) => (a.name > b.name ? 1 : -1))
          );
          break;
        case 'z-a':
          setSortedProducts(
            products.sort((a, b) => (a.name < b.name ? 1 : -1))
          );
          break;
        case 'low-high':
          setSortedProducts(products.sort((a, b) => a.price - b.price));
          break;
        case 'high-low':
          setSortedProducts(products.sort((a, b) => b.price - a.price));
          break;
        default:
          break;
      }
    },
    [products, setSortedProducts]
  );

  const handleGetCollection = () => {
    coreService
      .getCollection({
        slug: params?.name,
      })
      .then(res => {
        setCollection(res?.data);
        setProducts(res?.data?.products);
        setIsLoading(false);
      })
      .catch(err => {});
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="px-20 sm:px-0 mt-10 kolleksiya">
      <div className="md:container lg-container mx-auto">
        <div className="font-[sans-serif] bg-[#f7f6fa] mb-20 shadow-header">
          <div className="p-4 mx-auto lg:max-w-[1800px] sm:max-w-full">
            <div className="px-4 py-4 w-full rounded-lg font-[sans-serif]">
              <div className="flex items-center justify-between pb-4">
                <h1 className="font-bold">
                  {collection.title} (
                  {collection.products.length})
                </h1>
                <select
                  name="popularity"
                  className="w-[165px] h-[40px] rounded-[5px] bg-white text-sm leading-3 text-center border border-[#E0E0E0]"
                  onChange={onSelectChange}
                >
                  <option
                    className="text-sm leading-3 text-primary bg-disabled"
                    value="-1"
                  >
                    {t('common.sort_by')}
                  </option>

                  <option
                    className="text-sm leading-3 text-primary bg-disabled"
                    value="-1"
                  >
                    {t('common.sort_by_pop')}
                  </option>
                  <option
                    className="text-sm leading-3 text-primary bg-disabled"
                    value="a-z"
                  >
                    A-Z
                  </option>
                  <option
                    className="text-sm leading-3 text-primary bg-disabled"
                    value="z-a"
                  >
                    Z-A
                  </option>
                  <option
                    className="text-sm leading-3 text-primary bg-disabled"
                    value="low-high"
                  >
                    {t('common.sort_by_price_up')}
                  </option>
                  <option
                    className="text-sm leading-3 text-primary bg-disabled"
                    value="high-low"
                  >
                    {t('common.sort_by_price_down')}
                  </option>
                </select>
              </div>
            </div>
            {/* <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-5 sm:gap-3"> */}
            <div className="flex justify-center flex-wrap items-center gap-5 product-coll">
              {products?.map((product, key) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collections;
