import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const CategoryList = ({
  categories,
  subcatsToShow,
  showMoreSubcats,
  showLessSubcats,
  t,
  i18n,
}) => {
  const renderShowMoreLessButton = (
    totalItems,
    itemsToShow,
    showMore,
    showLess
  ) => {
    if (totalItems <= 3 || (itemsToShow >= totalItems && itemsToShow <= 5))
      return null;
    return (
      <button
        className="mt-2 ml-4 text-[#4e4790]"
        onClick={itemsToShow < 6 ? showMore : showLess}
      >
        <FontAwesomeIcon icon={itemsToShow < 6 ? faChevronDown : faChevronUp} />{' '}
        {itemsToShow < 6 ? t('common.show_more') : t('common.show_less')}
      </button>
    );
  };

  return (
    <div className="mt-4">
      {categories.length > 0 && (
        <h6 className="text-[#4e4790] text font-bold px-4">
          {t('common.subcategories')}
        </h6>
      )}
      <ul className="mt-2">
        {categories.slice(0, subcatsToShow).map((cat, index) => (
          <li
            key={index}
            className="text-black hover:text-primary text-[15px] block rounded px-4 py-2.5 transition-all"
          >
            <Link
              to={`/${i18n.language}/categories/products?${new URLSearchParams({
                category: cat.slug,
              }).toString()}`}
            >
              {cat.name}
            </Link>
          </li>
        ))}
      </ul>
      {renderShowMoreLessButton(
        categories.length,
        subcatsToShow,
        showMoreSubcats,
        showLessSubcats
      )}
    </div>
  );
};

export default CategoryList;
