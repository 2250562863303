import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AccountSidebar from '../../components/AccountSidebar';
import { goodToast, nahToast } from '../../utils/Toaster';

import AccordionSidebar from '../../components/AccordionSidebar';
import userService from '../../services/user.service';
import { getUserData, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const ChangePassword = () => {
  const activePage = 'change-password';
  const { t } = useTranslation();

  const user = getUserData();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: `/account/${activePage}`,
        },
      });
    }
  }, [isLoggedUser]);

  const renderRightPanel = () => {
    return (
      <div className="lg:col-span-3 space-y-2 sm:w-full p-2 mx-auto w-full">
        <div className="p-2 bg-white shadow-header rounded-md relative">
          <div className="font-sans">
            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              {renderChangePassword()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderChangePassword = () => {
    return (
      <div className="grid grid-cols-1 gap-y-2">
        <div className="bg-gray-50 text-[#333] px-3 py-4 w-full rounded-lg font-[sans-serif]">
          <h1 className="text-4xl font-bold">
            {t('account-settings.changing_account_password')}
          </h1>
          <p className="text-sm text-gray"></p>
        </div>
        <div className="w-full sm:p-2 p-2 ">
          <form
            onSubmit={e => handleChangePassword(e)}
            className="space-y-4 font-[sans-serif] text-[#333] mx-auto"
          >
            <div className="relative flex items-center">
              <input
                placeholder={t('account-settings.old-password')}
                type="password"
                name="old-password"
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                id="old-password"
                className="px-4 py-3 bg-[#f0f1f2] w-full text-sm rounded"
              />
            </div>
            <div className="relative flex items-center">
              <input
                type="password"
                name="new-password"
                id="new-password"
                minLength={8}
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                required
                placeholder={t('account-settings.new-password')}
                className="px-4 py-3 bg-[#f0f1f2] w-full text-sm rounded"
              />
            </div>
            <div className="relative flex items-center">
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                minLength={8}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                required
                placeholder={t('account-settings.confirm-password')}
                className="px-4 py-3 bg-[#f0f1f2] w-full text-sm rounded"
              />
            </div>

            <div class="!mt-5 orderSaveBtn">
              <button
                type="button"
                className="mt-4 px-20 sm:w-full py-2.5 rounded-lg text-white float-right text-sm tracking-wider bg-[#4E4790] border-none outline-none"
              >
                {t('account-settings.change_password')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const handleChangePassword = e => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      nahToast('Yeni parol və təsdiq parolu uyğun gəlmir!');
    } else {
      if (oldPassword === newPassword) {
        nahToast('Köhnə parol və yeni parol eyni ola bilməz!');
      } else if (newPassword.length < 8) {
        nahToast('Yeni parol ən azı 8 simvol uzunluğunda olmalıdır!');
      } else {
        userService
          .changePassword(oldPassword, newPassword, confirmPassword)
          .then(response => {
            if (response.status === 200) {
              goodToast('Parol uğurla dəyişdirildi!');
            } else {
              nahToast('Parolun dəyişdirilməsi uğursuz oldu!');
            }
          })
          .catch(error => {
            if (error.response.data && error.response.data.non_field_errors) {
              nahToast(error.response.data.non_field_errors[0]);
            } else {
              nahToast('Xəta baş verdi!');
            }
          })
          .finally(() => {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
          });
      }
    }
  };

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  return (
    <div className="mx-auto bg-white">
      {' '}
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
          <div class="bg-gray-50 text-[#333] px-8 py-8 w-full rounded-lg font-[sans-serif] shadow-header ">
            <h1 class="text-4xl font-bold">Hi {user ? user.name : 'Guest'}</h1>
            <ul class="flex items-center justify-left font-[sans-serif] space-x-4 mt-4">
              <li class="text-gray text-base cursor-pointer">
                <Link to="/">{t('account-settings.home')}</Link>
              </li>
              <li>{'>'}</li>
              <li class="text-gray text-base cursor-pointer">
                <Link to="/account/profile">
                  {t('account-settings.profile')}
                </Link>
              </li>
              <li>{'>'}</li>
              <li class="text-base cursor-pointer">{t('account-settings.account-security')}</li>
            </ul>
            <AccordionSidebar />
          </div>

          <div className="flex gap-2 relative mt-5">
            {' '}
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
