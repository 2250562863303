import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import ProductCard from '../../components/ProductCard';
import Sidebar from '../../components/Sidebar';

import useWindowSize from '../../hooks/useWindowSize';
import productService from '../../services/product.service';

import { useTranslation } from 'react-i18next';
import '../../assets/styles/accordion.css';
import '../../assets/styles/categories.css';
import '../../assets/styles/commons.css';
import SubscribeButton from '../../components/SubscribeButton';
import categoryService from '../../services/category.service';
import { getUserData } from '../../utils/Utils';
import NothingFound from '../../components/NothingFound';
import ProductsGrid from '../../components/ProductsGrid';

const ProductsList = () => {
  const { width } = useWindowSize();
  const user = getUserData();
  const isMobile = width < 768;
  const { t } = useTranslation();
  const [categories, setMainCats] = useState(
    JSON.parse(sessionStorage.getItem('categoriesCached'))
  );
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [childrenCats, setChildrenCats] = useState([]);

  const [productValues, setProductValues] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productBrands, setProductBrands] = useState([]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [fixMinPrice, setFixMinPrice] = useState(null);
  const [fixMaxPrice, setFixMaxPrice] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);

  const [brand, setBrand] = useState('');
  const [filters, setFilters] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get('mainCategory');
  const secondSlug = queryParams.get('subCategory');
  const thirdSlug = queryParams.get('thirdCategory');
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const [sorted, setSorted] = useState('-1');

  useEffect(() => {
    if (categories) {
    } else {
      categoryService.getBigCategories().then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          setMainCats(responseData);
        }
      });
    }

    setChildrenCats(getSubcats(slug, secondSlug, thirdSlug));
    if (thirdSlug) {
      handleGetProducts(thirdSlug);
      handleGetProductBrands(thirdSlug);
    } else if (secondSlug) {
      handleGetProducts(secondSlug);
      handleGetProductBrands(secondSlug);
    } else {
      handleGetProducts(slug);
      handleGetProductBrands(slug);
    }
  }, [params, categories]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getCategoryName = () => {
    let firstLevelCategory;
    let secondLevelCategory;
    let thirdLevelCategory;

    firstLevelCategory = categories?.find(category => category.slug === slug);
    let categoryNames = [];
    if (!firstLevelCategory) {
      return '';
    } else {
      categoryNames.push({
        name: firstLevelCategory.name,
        slug: slug,
        id: firstLevelCategory.id,
      });
    }
    if (secondSlug) {
      secondLevelCategory = firstLevelCategory.children.find(
        category => category.slug === secondSlug
      );
      if (secondLevelCategory) {
        categoryNames.push({
          name: secondLevelCategory.name,
          slug: secondSlug,
          id: secondLevelCategory.id,
        });
      }
    }

    if (thirdSlug) {
      thirdLevelCategory = secondLevelCategory
        ? secondLevelCategory.children.find(
            category => category.slug === thirdSlug
          )
        : undefined;
      if (thirdLevelCategory) {
        categoryNames.push({
          name: thirdLevelCategory.name,
          slug: thirdSlug,
          id: thirdLevelCategory.id,
        });
      }
    }

    return categoryNames;
  };

  const handleGetProducts = slug => {
    productService
      .filterProducts({
        category: slug,
        search: null,
        sort: null,
        store_slug: null,
        min_price: null,
        max_price: null,
        attributes: null,
        brand: null,
        delivery_method: null,
      })
      .then(response => {
        setProducts(response.data.results.products);
        setTotalProducts(response.data.results.total_count);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
        handleGetProductValue(slug);
        setFixMaxPrice(response.data.results.max_price);
        setFixMinPrice(response.data.results.min_price);
        setPriceRanges(response.data.results.price_ranges);
      })
      .catch(error => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSubcats = (firstCat, secondCat, finalCat) => {
    let firstLevelCategory = null;
    let secondLevelCategory = null;
    let resultCats = [];
    firstLevelCategory = categories?.find(
      category => category.slug === firstCat
    );

    if (firstCat && secondCat && !finalCat) {
      secondLevelCategory = firstLevelCategory.children.find(
        category => category.slug === secondCat
      );
    }

    if (firstCat && secondCat && finalCat) {
    }

    if (firstLevelCategory) {
      resultCats = firstLevelCategory.children;
    } else {
      resultCats = [];
    }

    if (firstLevelCategory && secondLevelCategory) {
      resultCats = secondLevelCategory.children;
    }

    if (finalCat) {
      resultCats = [];
    }

    return resultCats;
  };

  const handleGetProductValue = category => {
    productService
      .getProductValue(category)
      .then(response => {
        setProductValues(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetProductBrands = catSlug => {
    productService
      .getProductBrands(catSlug)
      .then(response => {
        setProductBrands(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetPriceRange = e => {
    setIsLoading(true);
    productService
      .getProducts({
        category: params.slug,
        min_price: minPrice,
        max_price: maxPrice,
        sort: sorted,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
        }
      })
      .catch(error => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFinalCatSlug = () => {
    let final_slug = null;
    if (thirdSlug) {
      final_slug = thirdSlug;
    } else if (secondSlug && thirdSlug === null) {
      final_slug = secondSlug;
    } else {
      final_slug = slug;
    }

    return final_slug;
  };

  const handleSortProducts = value => {
    setIsLoading(true);
    setSorted(value);
    const final_slug = getFinalCatSlug();

    productService
      .getProducts({
        category: final_slug,
        sort: value,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
          setIsLoading(false);
        }
      })
      .catch(error => {});
  };

  const filterByPriceRange = (min, max) => {
    const final_slug = getFinalCatSlug();

    const lookup_data = {
      category: final_slug,
      min_price: min,
      max_price: max,
    };

    productService
      .filterProducts(lookup_data)
      .then(res => {
        setProducts(res.data.results.products);
        setTotalProducts(res.data.results.total_count);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleFilterProduct = (
    selectedBrandsFilters,
    selectedDeliveryFilters,
    selectedAttributesFilters
  ) => {
    const final_slug = getFinalCatSlug();

    const atibutesString = JSON.stringify(selectedAttributesFilters);

    const lookup_data = {
      category: final_slug,
      min_price: minPrice,
      max_price: maxPrice,
      sort: sorted,
      brand: selectedBrandsFilters,
      delivery_method: selectedDeliveryFilters,
      attributes: atibutesString,
    };

    productService
      .filterProducts(lookup_data)
      .then(res => {
        setProducts(res.data.results.products);
        setTotalProducts(res.data.results.total_count);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleLoadMore = e => {
    e.preventDefault();

    productService
      .getProductsPagination(productsPaginationInfo.next)
      .then(response => {
        setProducts(prev => [...prev, ...response.data.results.products]);
        setTotalProducts(response.data.results.total_count);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
      })
      .catch(error => {})
      .finally(() => {});
  };

  const constructBreadcrumbURL = catName => {
    var urlParams = {};
    var categoryList = [slug, secondSlug, thirdSlug];

    // Find the index of catName in the fixed category list
    var catIndex = categoryList.indexOf(catName);

    // Set main, sub, and third categories based on the index
    urlParams.mainCategory = catIndex >= 0 ? categoryList[0] : '';
    urlParams.subCategory = catIndex >= 1 ? categoryList[1] : '';
    urlParams.thirdCategory = catIndex >= 2 ? categoryList[2] : '';

    // Construct the URL with parameters
    var url = Object.keys(urlParams)
      .map(function (key) {
        return urlParams[key]
          ? key + '=' + encodeURIComponent(urlParams[key])
          : null;
      })
      .filter(Boolean) // Remove null values
      .join('&');

    return url;
  };

  const renderBreadCrumbs = () => {
    const catNames = getCategoryName();
    return (
      <>
        {catNames &&
          catNames.map((category, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <li className="text-gray-400" style={{ fontSize: '12px' }}>
                  {'>'}
                </li>
              )}
              <li
                className="text-gray-400 cursor-pointer hover:text-primary"
                style={{ fontSize: '14px' }}
              >
                <Link
                  to={
                    '/categories/products?' +
                    constructBreadcrumbURL(category.slug)
                  }
                >
                  {category.name}
                </Link>
              </li>
            </React.Fragment>
          ))}
      </>
    );
  };

  const renderCurrentCategoryName = () => {
    const catNames = getCategoryName();

    let currentCatName;

    if (catNames.length >= 3) {
      currentCatName = catNames[2].name;
    } else if (catNames.length >= 2) {
      currentCatName = catNames[1].name;
    } else if (catNames.length >= 1) {
      currentCatName = catNames[0].name;
    } else {
      return <p></p>;
    }

    return currentCatName;
  };


  const renderSidebarContent = () => {
    return !isMobile ? (
      <div>
        <Sidebar
          firstSlug={slug}
          secondSlug={secondSlug}
          thirdSlug={thirdSlug}
          categoriesList={childrenCats}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          setBrand={setBrand}
          minPrice={minPrice}
          maxPrice={maxPrice}
          products={products}
          filters={filters}
          setFilters={setFilters}
          handleFilterProduct={handleFilterProduct}
          handleGetPriceRange={handleGetPriceRange}
          productValues={productValues}
          brands={productBrands}
          fixMinPrice={fixMinPrice}
          fixMaxPrice={fixMaxPrice}
          priceRanges={priceRanges}
          filterByPriceRange={filterByPriceRange}
        />
      </div>
    ) : (
      <div className="accordion">
        <div className="accordion-header" onClick={handleToggle}>
          <h3>Filters</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <Sidebar
              firstSlug={slug}
              secondSlug={secondSlug}
              thirdSlug={thirdSlug}
              categoriesList={childrenCats}
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
              setBrand={setBrand}
              minPrice={minPrice}
              maxPrice={maxPrice}
              products={products}
              filters={filters}
              setFilters={setFilters}
              handleFilterProduct={handleFilterProduct}
              handleGetPriceRange={handleGetPriceRange}
              productValues={productValues}
              brands={productBrands}
              fixMinPrice={fixMinPrice}
              fixMaxPrice={fixMaxPrice}
              priceRanges={priceRanges}
              filterByPriceRange={filterByPriceRange}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSortProducts = () => {
    return (
      <select
        name="popularity"
        className="w-[275px] h-[40px] rounded-[5px] text-sm leading-3 text-center outline-none"
        onChange={e => handleSortProducts(e.target.value)}
      >
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="-1"
        >
          {t('common.sort_by_pop')}
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="a-z"
        >
          A-Z
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="z-a"
        >
          Z-A
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="low-high"
        >
          {t('common.sort_by_price_up')}
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="high-low"
        >
          {t('common.sort_by_price_down')}
        </option>
      </select>
    );
  };

  const renderProductsListPanel = () => {
    return (
      <div className="font-[sans-serif] shadow-header">
        <div className="p-2 mx-auto">
          <div className="flex items-center justify-end pb-3">
            {/* {user && (
                    <div>
                      <SubscribeButton
                        categoryId={getCurrentCategoryId()}
                        centered={true}
                      ></SubscribeButton>
                    </div>
                  )} */}
            {renderSortProducts()}
          </div>
          {products && products.length > 0 ? (
            <ProductsGrid products={products}></ProductsGrid>
          ) : (
            <NothingFound></NothingFound>
          )}
        </div>
        {productsPaginationInfo.next && (
          <div
            className="group load-more w-full  text-center flex justify-center items-center hover:cursor-pointer transition-all pb-5"
            onClick={e => handleLoadMore(e)}
          >
            <div className="group-hover:bg-shadow-hover w-44 h-10 bg-shadow-disabled rounded-md flex justify-center items-center transition-all">
              <span className="font-light text-sm leading-3">
                {t('common.load_more')}...
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTopBreadcrumbsPanel = () => {
    return (
      <div className="text-[#333] px-4 py-4 mb-10 w-full rounded-lg font-[sans-serif] shadow-header">
        <h1
          className="font-bold"
          style={{ fontSize: '18px', color: '#4E4790' }}
        >
          {renderCurrentCategoryName()} ({totalProducts})
        </h1>
        <p className="mt-4 text-sm text-gray-500">
          <ul className="flex items-center justify-left space-x-4 font-[sans-serif]">
            <li
              className="text-gray-400 text-sm cursor-pointer hover:text-primary"
              style={{ fontSize: '14px' }}
            >
              {t('error.homepage')}
            </li>
            <li className="text-gray-400 " style={{ fontSize: '12px' }}>
              {'>'}
            </li>
            {renderBreadCrumbs()}
          </ul>
        </p>
      </div>
    );
  };
  return (
    <div className="mx-auto mt-5 max-w-8xl">
      {renderTopBreadcrumbsPanel()}
      <div className="lg:flex gap-4">
        <div className="lg:w-1/5">{renderSidebarContent()}</div>
        <div className="lg:w-4/5 w-full">{renderProductsListPanel()}</div>
      </div>
    </div>
  );
};

export default ProductsList;
