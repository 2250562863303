import React from 'react';

const AttributeList = ({
  productValues,
  selectedAttributesFilters,
  handleAttributesFilterChange,
  t,
}) => {
  return (
    <div className="mt-4">
      {/* <h6 className="text-[#4e4790] text font-bold px-4">
        {t('common.attributes')}
      </h6> */}
      <ul className="mt-2">
        {Object.entries(productValues).map(([key, values]) => (
          <div key={key}>
            <h4 className="text-[#4e4790] px-4">{key}</h4>
            <div className="overflow-scroll max-h-[200px]">
              {values
                ? values
                    .filter(value => value !== '')
                    .map((value, index) => (
                      <li key={index}>
                        <label
                          className="bs_custom_checkbox_cont"
                          style={{ padding: '10px 10px 0 10px' }}
                        >
                          <input
                            type="checkbox"
                            className="bs_custom_checkbox_input"
                            id={`${value}_${index}`}
                            value={`${value}_${index}`}
                            onChange={() =>
                              handleAttributesFilterChange(key, value)
                            }
                            checked={selectedAttributesFilters[key]?.includes(
                              value
                            )}
                          />
                          <span className="bs_custom_checkbox_span w-full">
                            {value}
                          </span>
                        </label>
                      </li>
                    ))
                : null}
            </div>
            <hr className="mt-1 mb-4 opacity-30" />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default AttributeList;
