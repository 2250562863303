import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import '../../assets/styles/mobile_menu.css';
import productService from '../../services/product.service';
import { isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const MobileVerticalTabs = ({
  mainCats,
  topCats,
  bestCats,
  newCats,
  defaultTab = 'category_top',
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const navigate = useLanguageNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const isLoggedUser = isUserLoggedIn();

  const onPanelStateChange = url => {
    navigate(url);
    window.location.reload();
  };

  const linkToAllCategories = () => {
    navigate('/');
    window.location.reload();
  };

  const handleChangeLanguage = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);

    const currentPath = location.pathname;
    const currentLang = currentPath.split('/')[1];

    if (currentLang === 'en' || currentLang === 'az') {
      const newPath = currentPath.replace(`/${currentLang}`, `/${lang}`);
      navigate(newPath + location.search);
    } else {
      // If there's no language prefix, add it
      navigate(`/${lang}${currentPath}${location.search}`);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('userData');
    navigate('/');
    window.location.reload();
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState([]);

  const toggleSubMenu = index => {
    const updatedSubMenuOpen = [...isSubMenuOpen];
    updatedSubMenuOpen[index] = !updatedSubMenuOpen[index];
    setIsSubMenuOpen(updatedSubMenuOpen);
  };

  const [isAllCategoriesOpen, setIsAllCategoriesOpen] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [catSearchResults, setCatSearchResults] = useState(false);

  const handleSearchCategories = e => {
    if (e.target.value.length > 0) {
      productService
        .getCategoriesForSearch(e.target.value)
        .then(response => {
          if (response.status === 200) {
            setCatSearchResults(response.data);
          }
        })
        .catch(error => {
          throw error.response.data;
        });
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
  };

  const searchContainer = () => {
    return (
      <div className="grid-container">
        <div className="relative">
          <input
            style={{ width: '100%', fontSize: '15px' }}
            name="search"
            id="search"
            type="text"
            onKeyDown={e => handleSearchCategories(e)}
            placeholder={'Kateqoriya axtar'}
            className="h-full outline-none px-[7px] font-light text-sm text-gray"
          />
          {showSearchResult && renderSearchResult()}
        </div>
      </div>
    );
  };

  const renderSearchResult = () => {
    return (
      <div
        className={classNames(
          'absolute w-[100%] min-h-[131px] z-10 top-7 sm:top-8 bg-primary-white flex flex-col rounded-b-[5px]'
        )}
      >
        <React.Fragment>
          {catSearchResults && catSearchResults.length ? (
            catSearchResults.map(searchCatResult => (
              <Link to={'/'} key={searchCatResult.id} className="mb-[10px]">
                <div className="flex pl-[7px]">
                  <div className="flex flex-col ml-[10px]">
                    <h4
                      className="text-caption font-light"
                      style={{ fontSize: '13px' }}
                    >
                      {String(searchCatResult.name).length > 50
                        ? String(searchCatResult.name).substring(0, 50)
                        : searchCatResult.name}
                    </h4>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div>
              <h5 className="text-caption font-light px-2 py-[9px]">
                {t('header.nothing_found')}
              </h5>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  };

  return (
    <nav className="cat_nav ">
      <div>{searchContainer()}</div>

      <div className="mt-10" id="readmore">
        <div className="flex text-md sm:text-md leading-5 sm:leading-3 text-gray hover:cursor-pointer">
          <span
            onClick={() => setActiveTab('category_top')}
            className={classNames('mr-[30px]', {
              'text-black': activeTab === 'category_top',
            })}
          >
            Top
          </span>
          <span
            onClick={() => setActiveTab('category_best')}
            className={classNames('mr-[30px]', {
              'text-black': activeTab === 'category_best',
            })}
          >
            {t('')} Best
          </span>
          <span
            onClick={() => setActiveTab('categry_new')}
            className={classNames('mr-[30px]', {
              'text-black': activeTab === 'categry_new',
            })}
          >
            New
          </span>
        </div>

        <div className="w-[992px] md:w-[707px] sm:w-[343px] h-0 border-[0.5px] mt-[18px] border-gray"></div>
      </div>

      {activeTab === 'category_top' ? (
        <div className="flex flex-col mt-4">
          <div className="flex w-full justify-between">
            <ul>
              {topCats &&
                topCats.length &&
                topCats.map(sub_cat => (
                  <li>
                    <Link
                      key={sub_cat.slug}
                      to={'#' + sub_cat.slug}
                      onClick={() =>
                        onPanelStateChange(
                          `/categories/products?${sub_cat.href}`
                        )
                      }
                    >
                      {localStorage.getItem('lang') ===
                      'en' /* previous === en*/
                        ? sub_cat.name
                        : sub_cat.alt_name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : activeTab === 'category_best' ? (
        <div className="flex flex-col mt-4">
          <div className="flex w-full justify-between">
            <ul>
              {bestCats &&
                bestCats.length &&
                bestCats.map(sub_cat => (
                  <li>
                    <Link
                      key={sub_cat.slug}
                      to={'#' + sub_cat.slug}
                      onClick={() =>
                        onPanelStateChange(
                          `/categories/products?${sub_cat.href}`
                        )
                      }
                    >
                      {localStorage.getItem('lang') ===
                      'en' /* previous was === en*/
                        ? sub_cat.name
                        : sub_cat.alt_name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : activeTab === 'categry_new' ? (
        <div className="flex flex-col mt-4">
          <div className="flex w-full justify-between">
            <ul>
              {newCats &&
                newCats.length &&
                newCats.map(sub_cat => (
                  <li>
                    <Link
                      key={sub_cat.slug}
                      to={'#' + sub_cat.slug}
                      onClick={() =>
                        onPanelStateChange(
                          `/categories/products?${sub_cat.href}`
                        )
                      }
                    >
                      {localStorage.getItem('lang') ===
                      'en' /* previous was === en */
                        ? sub_cat.name
                        : sub_cat.alt_name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : null}

      <button
        className="text-sm text-center text-[#3D3770] w-full mt-5 rounded"
        onClick={() => setIsAllCategoriesOpen(!isAllCategoriesOpen)}
        style={{
          fontSize: '14px',
          border: '1px solid #574FA0',
          padding: '5px',
        }}
      >
        <span>Bütün kateqoriyalar</span>
      </button>
      {isAllCategoriesOpen ? (
        <nav className="animated bounceInDown">
          <ul>
            {mainCats?.map((tab, index) => (
              <li key={index} className={tab.children && 'sub-menu'}>
                <a href={'#' + tab.slug} onClick={() => toggleSubMenu(tab.id)}>
                  {localStorage.getItem('lang') === 'en' /*previous was === en*/
                    ? tab.name
                    : tab.alt_name}
                  <span style={{ float: 'right', marginRight: '20px' }}>
                    {tab.children &&
                      (!isSubMenuOpen[tab.id] ? (
                        <FontAwesomeIcon
                          style={{ marginRight: '5px', fontSize: '10px' }}
                          icon={faAngleRight}
                        ></FontAwesomeIcon>
                      ) : (
                        <FontAwesomeIcon
                          style={{ marginRight: '5px', fontSize: '10px' }}
                          icon={faAngleDown}
                        ></FontAwesomeIcon>
                      ))}
                  </span>
                </a>
                {isSubMenuOpen[tab.id] && (
                  <ul>
                    {tab.children &&
                      tab.children.length &&
                      tab.children
                        .slice(0, 10)
                        .sort((a, b) => {
                          if (a.children.length < b.children.length) {
                            return -1;
                          } else if (a.children.length > b.children.length) {
                            return 1;
                          } else {
                            return a.name.localeCompare(b.name);
                          }
                        })
                        .map((sub_cat, key) => (
                          <li key={key} className="sub-menu">
                            <a
                              href={'#' + sub_cat.slug}
                              onClick={() =>
                                sub_cat.children && sub_cat.children.length
                                  ? toggleSubMenu(sub_cat.id)
                                  : onPanelStateChange(
                                      `/categories/products?mainCategory=${tab.slug}&subCategory=${sub_cat.slug}`
                                    )
                              }
                            >
                              {localStorage.getItem('lang') ===
                              'en' /* previous was === en*/
                                ? sub_cat.name
                                : sub_cat.alt_name}
                              <span
                                style={{ float: 'right', marginRight: '15px' }}
                              >
                                {sub_cat.children &&
                                  Boolean(sub_cat.children.length) &&
                                  (!isSubMenuOpen[sub_cat.id] ? (
                                    <FontAwesomeIcon
                                      style={{
                                        marginRight: '5px',
                                        fontSize: '10px',
                                      }}
                                      icon={faAngleRight}
                                    ></FontAwesomeIcon>
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{
                                        marginRight: '5px',
                                        fontSize: '10px',
                                      }}
                                      icon={faAngleDown}
                                    ></FontAwesomeIcon>
                                  ))}
                              </span>
                              {/* <div
                              className={`fa ${
                                isSubMenuOpen[1]
                                  ? 'fa-caret-up'
                                  : 'fa-caret-down'
                              } right`}
                            ></div> */}
                            </a>
                            {isSubMenuOpen[sub_cat.id] && (
                              <ul>
                                {sub_cat.children.map((sub_sub_cat, key) => (
                                  <li key={key}>
                                    <a
                                      href={'#' + sub_cat.slug}
                                      onClick={() =>
                                        onPanelStateChange(
                                          `/categories/products?mainCategory=${tab.slug}&subCategory=${sub_cat.slug}&thirdCategory=${sub_sub_cat.slug}`
                                        )
                                      }
                                    >
                                      {localStorage.getItem('lang') ===
                                      'en' /* previous was === en*/
                                        ? sub_sub_cat.name
                                        : sub_sub_cat.alt_name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      ) : null}

      <div className="flex flex-col mt-4">
        <b style={{ marginLeft: '10px', fontSize: '15px' }}>
          {t('common.sidebar_help_settings')}
        </b>
        <div className="flex w-full justify-between">
          <ul>
            {isLoggedUser ? (
              <li>
                <Link
                  className="ml-[3px]"
                  to={'/account/profile'}
                  onClick={() => onPanelStateChange(`/account/profile`)}
                >
                  {t('common.sidebar_account_adjustment')}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  className="ml-[3px]"
                  to={'/account/sign-in'}
                  onClick={() => onPanelStateChange(`/account/sign-in`)}
                >
                  {t('signin.login')}
                </Link>

                <Link
                  className="ml-[3px]"
                  to={'/account/sign-up'}
                  onClick={() => onPanelStateChange(`/account/sign-up`)}
                >
                  {t('signin.register')}
                </Link>
              </li>
            )}

            <li>
              <select
                style={{
                  width: '350px',
                  marginLeft: '13px',
                  padding: '5px 0px 15px 0px',
                  backgroundColor: 'transparent',
                  border: '0px solid rgb(87, 79, 160)',
                }}
                onClick={e => handleChangeLanguage(e.target.value)}
                defaultChecked={localStorage.getItem('lang')}
              >
                <option value="az"> {t('common.sidebar_aze_lang')}</option>
                <option value="en">{t('common.sidebar_eng_lang')}</option>
              </select>
            </li>

            <li>
              <div
                style={{ width: '350px', marginLeft: '16px' }}
                onClick={() => navigate('/faq')}
              >
                {t('common.sidebar_help_support')}
              </div>
            </li>
            {isLoggedUser ? (
              <li>
                <a style={{ color: 'red' }} onClick={() => handleLogout()}>
                  {t('account-settings.logout')}
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileVerticalTabs;
