import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import {
  getGuestBasketCount,
  getGuestFavsCount,
  getUserData,
  isUserLoggedIn,
  parseJwt,
} from '../../utils/Utils';

import { CiHeart, CiShoppingCart } from 'react-icons/ci';

import { useTranslation } from 'react-i18next';
import HamburgerClose from '../../assets/img/icon/hamburger-close.svg';
import Hamburger from '../../assets/img/icon/hamburger.svg';
import Search from '../../assets/img/icon/icons8-search-64.png';
import LogoHorizontal from '../../assets/img/icon/logo_horizontal.svg';

import {
  faEnvelope,
  faLocation,
  faPhone,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/header.css';
import { setCategories } from '../../redux/actions/catAction';
import { addToBasket, addToFavs } from '../../redux/actions/headerAction';
import orderService from '../../services/order.service';
import productService from '../../services/product.service';
import userService from '../../services/user.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import { CategoriesDropdown } from '../CategoriesDropdown';
import LocalisationSettings from '../LocalisationSettingsDropdown';
import { SearchPanel } from '../SearchPanel';
import UserDropdown from './UserDropdown';

const Header = () => {
  const { width } = useWindowSize();
  const isMobile = width < 767;
  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();
  const user = getUserData();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { navMenu, navMenuTablet } = useSelector(state => state.nav);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isDownloadAppOpen, setDownloadAppOpen] = useState(true);

  const [changeAddressModalOpen, setChangeAddressModalOpen] = useState(false);
  const [userDefaultAddress, setDefaultAddress] = useState(null);

  const openAddressChangeModal = () => {
    userService
      .getAddress()
      .then(response => {
        if (response.status === 200) {
          response.data.results
            .filter(element => element.default === true)
            .forEach(element => setDefaultAddress(element));
        }
      })
      .catch(error => {
        throw error.response.data;
      });
    setChangeAddressModalOpen(true);
  };

  const closeAddressChangeModal = () => {
    setChangeAddressModalOpen(false);
  };

  const header = useSelector(state => state.header);

  useEffect(() => {
    if (isLoggedUser) {
      const accessToken = localStorage.getItem('access');
      if (accessToken) {
        const decodedJwt = parseJwt(accessToken);
        if (decodedJwt.exp * 1000 < Date.now()) {
          handleLogout();
        } else {
          handleFavorites();
          handleBasket();
        }
      }
    } else {
      handleFavorites();
      handleBasket();
    }
  }, [isLoggedUser]);

  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('userData');
    navigate('/');
    window.location.reload();
  };

  let debounceTimer;

  const handleSearchProduct = value => {
    if (value && value.length > 3) {
      setShowSearchResult(true);
      setIsLoading(true);
      setSearchValue(value);

      productService
        .getProductForSearch(value)
        .then(response => {
          if (response.status === 200) {
            setIsLoading(false);
            setSearchProducts(response.data.results);
            setSearchCategories(response.data.found_categories);
          }
        })
        .catch(error => {
          throw error.response.data;
        });
    } else {
      setShowSearchResult(false);
      setIsLoading(false);
    }

    setTimeout(() => {
      console.log(`Search for "${value}" completed`);
    }, 1000);
  };

  const handleInputChangeForSearch = e => {
    setShowSearchResult(true);
    setIsLoading(true);
    setSearchValue(e.target.value);

    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      handleSearchProduct(e.target.value);
    }, 1000);
  };

  const handleCloseDownloadApp = () => {
    setDownloadAppOpen(false);
  };

  const handleFavorites = () => {
    if (isLoggedUser) {
      userService.getFavorites().then(response => {
        if (response.status === 200) {
          dispatch(addToFavs(response.data.count));
        }
      });
    } else {
      dispatch(addToFavs(getGuestFavsCount()));
    }
  };

  const handleBasket = () => {
    if (isLoggedUser) {
      orderService.getCheckoutLines().then(response => {
        if (response.status === 200) {
          dispatch(addToBasket(response.data.count));
        }
      });
    } else {
      dispatch(addToBasket(getGuestBasketCount()));
    }
  };

  const handleSearchSubmit = () => {
    navigate(`/search?q=${searchValue}`);
    setShowSearchResult(false);
  };

  const openCategoriesPanel = () => {
    dispatch(setCategories(true));
  };

  const getLink = product => {
    return `/products/${product?.slug}`;
  };

  const desktopSearch = () => {
    return (
      <div className="flex items-center h-[35px] md:h-[23px] sm:order-2">
        {' '}
        {/* sm:w-[343px]  */}
        <SearchPanel></SearchPanel>
      </div>
    );
  };

  // Updated React component
  const searchContainer = () => {
    return (
      <div className="grid w-full">
        <div className="relative w-full">
          <div className="flex rounded-full border-[1px] border-[#574FA0] w-full overflow-hidden max-w-md mx-auto font-[sans-serif] mb-2">
            <input
              name="search"
              id="search"
              type="text"
              onKeyDown={e =>
                e.code === 'Enter' ? handleSearchSubmit() : null
              }
              onInput={e => handleInputChangeForSearch(e)}
              placeholder={t('header.i_am_purchasing')}
              className="w-full outline-none bg-white text-sm px-5 py-3"
            />
            <button
              onClick={() => handleSearchSubmit()}
              type="button"
              className="flex items-center justify-center px-6"
            >
              <img width={30} src={Search} alt="" />
            </button>
          </div>
          {showSearchResult && renderSearchResult()}
        </div>
      </div>
    );
  };

  const renderTopNavPart = () => {
    return (
      <nav className="h-[40px] mx-auto bg-primary-white flex items-center md:hidden sm:hidden">
        <div className=" mx-auto flex top-header" bis_skin_checked="1">
          {' '}
          {/*w-[1700px] */}
          <ul className="nav-list">
            <li>
              <a className="nav-link" href="https://wa.me/994515324124">
                <FontAwesomeIcon
                  color="#A29ECB"
                  icon={faPhone}
                ></FontAwesomeIcon>{' '}
                +994 51 532 41 24
              </a>
            </li>
            <li>
              <a className="nav-link" href="mailto:hello@baysart.com">
                <FontAwesomeIcon
                  color="#A29ECB"
                  icon={faEnvelope}
                ></FontAwesomeIcon>{' '}
                hello@baysart.com
              </a>
            </li>
          </ul>
          <ul className="nav-list ml-auto">
            <li>
              <button
                className="nav-link flex items-center"
                onClick={() => openAddressChangeModal()}
              >
                <FontAwesomeIcon
                  color="#A29ECB"
                  style={{ marginRight: '3px' }}
                  icon={faLocation}
                />
                {t('common.my_delivery_address')}
              </button>
            </li>
            <li>
              <Link className="nav-link" to="/faq">
                {' '}
                <FontAwesomeIcon
                  color="#A29ECB"
                  style={{ marginRight: '3px' }}
                  icon={faQuestion}
                ></FontAwesomeIcon>{' '}
                {t('header.help_center')}
              </Link>
            </li>
            <li>
              <Link to="/sell-on-baysart" className="nav-link">
                {t('header.sell')}
              </Link>
            </li>
            <li>
              <span
                onClick={() => navigate('/categories')}
                className="nav-link cursor-pointer"
              >
                {t('header.shop')}
              </span>
            </li>
          </ul>
        </div>
      </nav>
    );
  };

  const renderMyAddressModal = () => {
    return (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative">
          <svg
            onClick={() => closeAddressChangeModal()}
            xmlns="http://www.w3.org/2000/svg"
            className="w-3.5 cursor-pointer shrink-0 fill-[#333] hover:fill-red-500 float-right"
            viewBox="0 0 320.591 320.591"
          >
            <path
              d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
              data-original="#000000"
            ></path>
            <path
              d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
              data-original="#000000"
            ></path>
          </svg>
          <div className="my-6 text-center">
            <p className="text-md text-gray-400 mt-4 text-left">
              Default location
            </p>
          </div>

          <div
            className="bg-blue-50 text-[#4E4790] shadow-header p-2 rounded relative"
            role="alert"
          >
            <div className="mb-3 flex items-center">
              <strong className="font-bold text-base mr-3">
                {userDefaultAddress?.title}
              </strong>
            </div>
            <span className="block sm:inline text-sm">
              {userDefaultAddress?.address}
            </span>
          </div>

          <Link
            onClick={() => closeAddressChangeModal()}
            to="/account/my-addresses"
            className="text-sm font-bold text-center mt-4 block"
          >
            Manage address book
          </Link>
        </div>
      </div>
    );
  };

  const renderSearchResult = () => {
    return (
      <div className="absolute px-3 shadow-lg bg-white py-3 z-[1000] min-w-full rounded max-h-[600px] overflow-auto border-[1px]">
        <p className="text-sm text-gray-500 mt-2 mb-2">
          {t('common.search_results')}
        </p>
        <ul>
          {searchProducts && searchProducts.length
            ? searchProducts.splice(0, 5).map((product, key) => (
                <Link key={key} to={getLink(product)}>
                  <li className="py-4 px-4 flex items-center hover:bg-gray-50 text-black text-sm cursor-pointer">
                    <img width={40} src={product.image} alt="" />
                    {String(product.name).length > 40
                      ? String(product.name).substring(0, 40)
                      : product.name}{' '}
                    ...
                  </li>
                </Link>
              ))
            : null}
        </ul>
        {/* <hr className="my-6" /> */}
        {/* <p className="text-sm text-gray-500 mb-2">Related categories</p> */}
        {/* <ul>
          {searchCategories && searchCategories.length
            ? searchCategories.map((cat, key) => (
                <li
                  key={key}
                  className="py-4 px-4 flex items-center hover:bg-gray-50 text-black text-sm cursor-pointer"
                >
                  <span className="mr-2">&#128269;</span> {cat.name}
                </li>
              ))
            : null}
        </ul> */}
      </div>
    );
  };

  const renderDownloadOurApp = () => {
    // return null;
    return (
      <div className="download_our_app">
        <div className="download_our_app__content">
          <div className="download_our_app__title">
            {t('common.get_the_app_text')}
          </div>

          <div style={{ width: '100%' }}>
            {/* <div style={{ display: 'table-row' }}> */}
            <div className="flex justify-center gap-6 store-buttons">
              <div style={{ width: '140px' }}>
                <a
                  className="playstore-button"
                  href="https://play.google.com/store/apps/details?id=com.baysartmmc.baysartapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="icon"
                    viewBox="0 0 512 512"
                  >
                    <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                  </svg>
                  <span className="texts">
                    <span className="text-1">
                      {t('common.download_from_playmarket')}
                    </span>
                  </span>
                </a>
              </div>
              <div className="justify-center items-center">
                {' '}
                <a
                  href="https://apps.apple.com/az/app/baysart-marketpleys/id1670478481"
                  className="playstore-button"
                  style={{ letterSpacing: '1px' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">
                    <svg
                      fill="currentcolor"
                      viewBox="-52.01 0 560.035 560.035"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff"
                    >
                      <g
                        id="SVGRepo_bgCarrier"
                        style={{ strokewidth: '0' }}
                      ></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        style={{
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                        }}
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="texts">
                    <span className="text-1">
                      {t('common.download_from_appstore')}
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="download_our_app__close"
          onClick={() => handleCloseDownloadApp()}
        >
          <svg
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"
              fill="#393a37"
            ></path>
          </svg>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <header className="shadow-sm bg-white max-w-[500px] font-[sans-serif] min-h-[70px]">
        {isDownloadAppOpen ? renderDownloadOurApp() : null}
        <div className="flex flex-wrap items-center justify-between sm:px-1 px-1 py-1 relative lg:gap-y-4 gap-y-2 gap-x-2 nav-top">
          <div className="flex">
            <a href="" className="ml-2 mt-2">
              <img
                width={130}
                src={LogoHorizontal}
                onClick={() => navigate('/')}
                alt="BaySart"
              />
            </a>
          </div>

          <div className="flex items-center p-1 w-full justify-center gap-20 nav-top-second">
            <button>
              <img
                width={30}
                src={navMenu ? HamburgerClose : Hamburger}
                alt="Hamburger"
                className="cursor-pointer"
                onClick={() => openCategoriesPanel()}
              />
            </button>
            <div className="flex items-center gap-[15px] nav-top-mobile">
              <div className="relative">
                <CiHeart
                  onClick={() => navigate('/favorites')}
                  className="text-xl"
                ></CiHeart>
                <div className="w-4 md:hidden rounded-full absolute -top-1 -right-1 bg-primary-white flex justify-center items-center">
                  <span className="text-sm font-light text-primary">
                    {header.favoritesLength}
                  </span>
                </div>
              </div>
              <div className="relative">
                <div className="w-4 md:hidden rounded-full absolute -top-1 -right-1 bg-primary-white flex justify-center items-center">
                  <span className="text-sm font-light text-primary">
                    {header.basketLength}
                  </span>
                </div>
                <CiShoppingCart
                  className="text-xl"
                  onClick={() => navigate('/shop')}
                ></CiShoppingCart>
              </div>
              <UserDropdown
                isLoggedUser={isLoggedUser}
                isMobile={isMobile}
                user={user}
                handleLogout={handleLogout}
              ></UserDropdown>
              <LocalisationSettings />
            </div>
          </div>

          {searchContainer()}
        </div>
      </header>
    );
  };

  const renderWeb = () => {
    return (
      <React.Fragment>
        {renderTopNavPart()}
        {changeAddressModalOpen ? renderMyAddressModal() : null}

        <header className="h-[90px] shadow-header pl-[20px] pr-[20px] bg-white flex items-center mx-auto sm:h-[229px] sm:justify-center sm:shadow-header-mobile">
          <div className="top-header mx-auto sm:w-full sm:h-full sm:mx-0 md:px-[30px]">
            {' '}
            {/*w-[1700px] */}
            <div className="flex sm:flex-col justify-between items-center w-full h-full sm:justify-evenly">
              <img
                src={LogoHorizontal}
                onClick={() => navigate('/')}
                alt="BaySart"
                className="w-[206px] h-[49px] md:w-[146px] md:h-[35px] object-contain md:mr-[11px] hover:cursor-pointer"
              />

              {desktopSearch()}

              <div className="flex items-center gap-2 sm:order-1">
                <div className="flex gap-3 hover:cursor-pointer right-icons">
                  <div className="relative m-auto">
                    <CiHeart
                      className="text-xl"
                      onClick={() => navigate('/favorites')}
                    ></CiHeart>

                    <div className="w-3 md:hidden rounded-full absolute -top-1 -right-1 bg-primary-white flex justify-center items-center">
                      <span className="text-sm font-light text-primary">
                        {header.favoritesLength}
                      </span>
                    </div>
                  </div>
                  <div className="relative m-auto">
                    <div className="w-3 md:hidden rounded-full absolute -top-1 -right-1 bg-primary-white flex justify-center items-center">
                      <span className="text-sm font-light text-primary">
                        {header.basketLength}
                      </span>
                    </div>
                    <CiShoppingCart
                      className="text-xl"
                      onClick={() => navigate('/shop')}
                    ></CiShoppingCart>
                  </div>
                  <LocalisationSettings />
                </div>

                <UserDropdown
                  isLoggedUser={isLoggedUser}
                  isMobile={isMobile}
                  user={user}
                  handleLogout={handleLogout}
                ></UserDropdown>
              </div>
            </div>
          </div>
        </header>

        <nav className="h-[50px] mb-5 shadow-[0_4px_15px_-6px_rgba(0,0,0,0.2)] mx-auto bg-primary-white flex items-center sm:hidden">
          <div className="w-full">
            <div className="">
              <CategoriesDropdown></CategoriesDropdown>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  };

  return isMobile ? renderMobile() : renderWeb();
};

export default Header;
