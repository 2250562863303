import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import FreeShipping from '../../assets/img/icon/icons8-free-shipping-64.png';
import Verified from '../../assets/img/icon/icons8-verified-64.png';
import '../../assets/styles/card.css';
// import CategoriesSlider from '../../components/CategoriesSlider';
import classNames from 'classnames';
import ProductCard from '../../components/ProductCard';
import SubscribeButton from '../../components/SubscribeButton';
import useWindowSize from '../../hooks/useWindowSize';
import categoryService from '../../services/category.service';
import productService from '../../services/product.service';
import storeService from '../../services/store.service';
import { getUserData, pageLimit } from '../../utils/Utils';
const Store = () => {
  const { width } = useWindowSize();
  const user = getUserData();
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState({});
  const [category, setCategory] = useState([]);
  const [storeCategories, setStoreCategories] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null);
  const [allProductsCount, setAllProductsCount] = useState(null);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  const handleChangePage = selected => {
    selected = selected + 0;
    window.scrollTo(0, 500);
    handleStoreProducts(selected, null);
    setCurrentPage(selected);
  };

  const params = useParams();

  useEffect(() => {
    if (params.slug) {
      handleGetStore();
      handleStoreProducts();
    }
  }, [params]);

  const handleGetStore = () => {
    storeService
      .getStore(params.slug)
      .then(response => {
        if (response.status === 200) {
          setStore(response.data.data);
          handleGetSubCategory(response?.data?.data?.category);
          setStoreCategories(response.data?.data?.category);
        }
      })
      .catch(error => {
        console.log(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetSubCategory = category => {
    categoryService.getCategories().then(res => {
      const storeCategory = res?.data?.results?.filter(
        data => data?.id === category[0]?.id
      );

      setCategory(storeCategory);
    });
  };

  const handleSearch = value => {
    if (!value.length) {
      handleStoreProducts();
    }
    const searchProducts = storeProducts.filter(product =>
      product.name.toLowerCase().includes(value.toLowerCase())
    );

    setStoreProducts(searchProducts);
  };

  const handleStoreProducts = (page = null, category = null) => {
    setCurrentSelectedCategory(category);
    productService
      .getProducts({
        store_slug: params?.slug,
        category: category?.slug,
        page,
      })
      .then(response => {
        if (response.status === 200) {
          setStoreProducts(response.data.results.products);

          if (category === null) {
            setAllProductsCount(response.data.count);
          }
          setProductsPaginationInfo({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          });
        }
      })
      .catch(error => {
        console.log(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderStoreInfoBanner = () => {
    return (
      <div className="bg-white rounded-lg shadow-xl mb-8">
        <div className="store grid grid-cols-2 sm:grid-cols-1 justify-around mt-5">
          <div className="flex flex-col items-center mb-5">
            <div className="flex items-center space-x-2 mt-2">
              <div className="flex items-center justify-center bg-gray-100">
                <img
                  // src="https://foundr.com/wp-content/uploads/2023/12/start-an-ecommerce-business.jpg.webp"
                  src={store.profile_image}
                  className="w-32 h-32 md:w-48 md:h-48 border-1 border-white rounded-full object-cover"
                  alt=""
                />
              </div>
              <p className="text-xl">{store.business_name}</p>
              {store.approved ? (
                <div className="relative w-max group mx-auto">
                  <img style={{ width: '40px' }} src={Verified} alt="" />
                  <div className="absolute shadow-lg hidden group-hover:block bg-[#333] text-white font-semibold px-3 py-[6px] text-[13px] right-0 left-0 mx-auto w-max -top-10 rounded">
                    {t('common.verified_store')}
                  </div>
                </div>
              ) : null}
              {store.free_cargo ? (
                <div className="relative w-max group mx-auto">
                  <img
                    style={{ width: '40px' }}
                    src={FreeShipping}
                    alt="freeshipping"
                  />
                  <div className="absolute shadow-lg hidden group-hover:block bg-[#333] text-white font-semibold px-3 py-[6px] text-[13px] right-0 left-0 mx-auto w-max -top-10 rounded">
                    {t('common.free_ship_store')}
                  </div>
                </div>
              ) : null}
            </div>
            {/* <p className="text-gray-700">{store.about} </p> */}
            <p className="text-sm text-gray-600">{store.street}</p>
            <p className="text-sm text-gray-600">
              {store.show_email ? store.email : null}
            </p>
            <p className="text-sm text-gray-600">{store.phone}</p>
          </div>

          <div className="right flex flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <div className="first-box flex flex-col justify-center min-w-[122px] border-r-2 border-[rgb(226,232,240)] py-0 px-3">
                <div className="text-md leading-5 text-center">0</div>
                <div className="flex justify-center items-center flex-row text-sm text-[#888] leading-none text-center mt-1 z-10">
                  <span>Subscribers</span>
                </div>
              </div>
              <div className="first-box flex flex-col justify-center min-w-[122px] border-r-2 border-[rgb(226,232,240)] py-0 px-3">
                <div className="text-md leading-5 text-center">0</div>
                <div className="flex justify-center items-center flex-row text-sm text-[#888] leading-none text-center mt-1 z-10">
                  <span>Sales</span>
                </div>
              </div>
              <div className="first-box flex flex-col justify-center min-w-[122px] py-0 px-3">
                <div className="text-md leading-5 text-center">
                  {allProductsCount}
                </div>
                <div className="flex justify-center items-center flex-row text-sm text-[#888] leading-none text-center mt-1 z-10">
                  <span>Products</span>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="border w-[360px] text-center p-2 transition-all duration-150 ease-in-out rounded-full hover:border-2 hover:border-solid hover:border-gray-400 hover:cursor-pointer hover:shadow-lg">
                {user ? (
                  <SubscribeButton
                    centered={true}
                    store={store}
                  ></SubscribeButton>
                ) : (
                  <button className="baysart-primary-button">
                    Login and subscribe
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="max-w-8xl mx-auto mt-5">
      {renderStoreInfoBanner()}

      <div
        className="mainContentSection border-t border-b border-solid border-[#aaa] py-4 px-4"
        style={{ Height: 10 }}
      >
        <div className="flex items-center justify-between">
          <div className="left flex items-center relative right-2.5">
            <div className="h-15 leading-4 cursor-pointer justify-center inline-flex items-center text-center relative">
              <div className="grid sm:grid-cols-1 sm:text-left grid-cols-5 text-[14px]">
                <h4 onClick={() => handleStoreProducts()}>Bütün məhsullar</h4>
                {storeCategories?.slice(0, 4).map((val, key) => (
                  <h4
                    onClick={() => handleStoreProducts(null, val)}
                    className={classNames(
                      val.id === currentSelectedCategory?.id
                        ? 'font-semibold'
                        : 'font-normal'
                    )}
                  >
                    {val.name}
                  </h4>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="font-[sans-serif] mt-5 mb-5">
        <div className="p-4 mx-auto sm:max-w-full">
          <div className="flex w-full justify-between">
            <h2 className="sm:ml-1 ml-12 text-4xl">
              {currentSelectedCategory
                ? currentSelectedCategory.name
                : 'All products'}
            </h2>
          </div>

          <div className="mt-10 bg-[#f7f6fa]">
            <div className="p-4 mx-auto lg:max-w sm:max-w-full">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-5 gap-3">
                {storeProducts && storeProducts.length ? (
                  storeProducts.map((product, key) => (
                    <div key={key}>
                      <ProductCard
                        key={product.id}
                        product={product}
                        minimized={width < 400}
                      />
                    </div>
                  ))
                ) : (
                  <div className="flex align-center justify-center text-center sm:w-[400px] lg:w-[1600px] md:w-[1000px]">
                    <p className="mt-6 text-md max-w-[400px]">
                      There are no products under this category
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {storeProducts.length > 0 && (
            <div className="flex justify-center w-full items-center pb-[10px]">
              <span className="text-md leading-5">Page</span>
              <select
                value={currentPage}
                onChange={e => handleChangePage(parseInt(e.target.value, 10))}
                className="w-10 h-10 mx-[10px] bg-shadow-disabled text-md leading-5 drop-icon"
                style={{
                  appearance: 'none',
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  textAlign: 'center',
                  backgroundColor: 'white',
                  border: '1px solid #6f6f6f',
                }}
              >
                {Array.from(
                  {
                    length: Math.ceil(productsPaginationInfo.count / pageLimit),
                  },
                  (_, i) => (
                    <option key={i} value={i}>
                      {i + 1}
                    </option>
                  )
                )}
              </select>
              <span className="text-md leading-5">
                of {Math.ceil(productsPaginationInfo.count / pageLimit)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Store;
