import {
  SET_CATEGORIES,
  SET_OPENED_MAIN_CATEGORIES_PANEL,
  SET_OPENED_SUBCATEGORIES_PANEL,
} from '../types/actionTypes';

export const setCategories = cat_state => {
  return {
    type: SET_CATEGORIES,
    payload: cat_state,
  };
};

export const setMainCatsPanelOpen = main_cat_state => {
  return {
    type: SET_OPENED_MAIN_CATEGORIES_PANEL,
    payload: main_cat_state,
  };
};

export const setSubCatsPanelOpen = sub_cat_state => {
  return {
    type: SET_OPENED_SUBCATEGORIES_PANEL,
    payload: sub_cat_state,
  };
};
