/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') !== null;
};

export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      alert('Address copied!');
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
    });
};
export const guestBasketWriter = product => {
  // Retrieve existing data from localStorage or initialize an empty array
  let existingGuestBasket = localStorage.getItem('guestProductBasket');
  let existingBasket = existingGuestBasket
    ? JSON.parse(existingGuestBasket)
    : [];

  // Add the JSON object to the array
  existingBasket.push(product);

  // Convert the updated array to JSON and store it back in localStorage
  localStorage.setItem('guestProductBasket', JSON.stringify(existingBasket));
};

export const getGuestBasketCount = () => {
  let existingGuestBasket = localStorage.getItem('guestProductBasket');
  let existingBasket = existingGuestBasket
    ? JSON.parse(existingGuestBasket)
    : [];
  return existingBasket.length;
};

export const checkIfProductIsFav = product => {
  let existingGuestBasket = localStorage.getItem('guestProductFavorites');
  let existingBasket = existingGuestBasket
    ? JSON.parse(existingGuestBasket)
    : [];

  const isProductInFavorites = existingBasket.some(
    item => item.id === product.id
  );

  return isProductInFavorites;
};

export const guestFavoritesWriter = product => {
  // Retrieve existing data from localStorage or initialize an empty array
  let existingGuestBasket = localStorage.getItem('guestProductFavorites');
  let existingBasket = existingGuestBasket
    ? JSON.parse(existingGuestBasket)
    : [];

  const isAlreadyExist = checkIfProductIsFav(product);
  if (!isAlreadyExist) {
    // Add the JSON object to the array
    existingBasket.push(product);

    // Convert the updated array to JSON and store it back in localStorage
    localStorage.setItem(
      'guestProductFavorites',
      JSON.stringify(existingBasket)
    );
  }
};

export const getGuestFavsCount = () => {
  let existingGuestBasket = localStorage.getItem('guestProductFavorites');
  let existingBasket = existingGuestBasket
    ? JSON.parse(existingGuestBasket)
    : [];
  return existingBasket.length;
};

export const removeFavProduct = productId => {
  var jsonString = localStorage.getItem('guestProductFavorites');
  var jsonArray = JSON.parse(jsonString);
  var indexToRemove = jsonArray.findIndex(function (item) {
    return item.id === productId;
  });

  if (indexToRemove !== -1) {
    jsonArray.splice(indexToRemove, 1);

    localStorage.setItem('guestProductFavorites', JSON.stringify(jsonArray));
  }
};

export const constructCatProductsURL = (catName, parents) => {
  var urlParams = {};

  var catIndex = parents.findIndex(item => item.slug === catName);

  urlParams.mainCategory = catIndex >= 0 ? parents[0].slug : '';
  urlParams.subCategory = catIndex >= 1 ? parents[1].slug : '';
  urlParams.thirdCategory = catIndex >= 2 ? parents[2].slug : '';

  var url = Object.keys(urlParams)
    .map(function (key) {
      return urlParams[key]
        ? key + '=' + encodeURIComponent(urlParams[key])
        : null;
    })
    .filter(Boolean)
    .join('&');

  return url;
};

export const removeBasketProduct = productId => {
  console.log('called', productId);
  var jsonString = localStorage.getItem('guestProductBasket');
  var jsonArray = JSON.parse(jsonString);
  var indexToRemove = jsonArray.findIndex(function (item) {
    return item.product.id === productId;
  });

  if (indexToRemove !== -1) {
    jsonArray.splice(indexToRemove, 1);

    localStorage.setItem('guestProductBasket', JSON.stringify(jsonArray));
  }
};

export const retrieveBasketProducts = () => {
  let existingGuestBasket = localStorage.getItem('guestProductBasket');
  let existingBasket = existingGuestBasket
    ? JSON.parse(existingGuestBasket)
    : [];
  return existingBasket;
};

export const currencyFormat = num => {
  return (
    new Intl.NumberFormat('az', {
      style: 'currency',
      currency: 'AZN',
    })
      .format(num)
      .replace('AZN', '')
      .replace('₼', '')
      .trim() + '₼'
  );
};

export const pageLimit = 18;
