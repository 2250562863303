import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Modal from '../../components/Modal';

import FAQicon from '../../assets/img/icon/icons8-faq-64.png';
import { nahToast } from '../../utils/Toaster';
import { currencyFormat, getUserData } from '../../utils/Utils';

import '../../assets/styles/commons.css';
import '../../assets/styles/table.css';
import CargoItem from '../../components/CargoItem';
import CheckoutDeliveryAddress from '../../components/CheckoutDeliveryAddress';
import CheckoutPaymentOptions from '../../components/CheckoutPaymentOptions';
import CheckoutTermsAndConditions from '../../components/CheckoutTermsAndConditions';
import MembershipPolicies from '../../components/MembershipPolicies';
import PersonalDataPolicy from '../../components/PersonalDataPolicy';
import orderService from '../../services/order.service';
import userService from '../../services/user.service';

import CheckoutRatePayment from '../../components/CheckoutRatePayment';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Checkout = () => {
  const { t } = useTranslation();
  const user = getUserData();
  const { state } = useLocation();
  const navigate = useLanguageNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutLines, setCheckoutLines] = useState([]);
  const [email, setEmail] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectAddress, setSelectAddress] = useState(null);
  const [activeModal, setActiveModal] = useState(false);
  const [activeModalSec, setActiveModalSec] = useState(false);
  const [activeTaksitModal, setActiveTaksitModal] = useState(false);
  const [checkoutId, setCheckoutId] = useState(null);
  const [taksitMonth, setTaksitMonth] = useState('');
  const [taksitFinalPrice, setTaksitFinalPrice] = useState(null);
  const [hasTaksit, setHasTaksit] = useState(false);
  const [shipperName, setShipperName] = useState(null);
  const [selectedShipperFee, setSelectedShipperFee] = useState(null);

  const handleRadioChange = event => {
    const final_month = findGrandTotal() / event.target.value;
    setTaksitMonth(event.target.value);
    setTaksitFinalPrice(final_month);
    setHasTaksit(true);
  };

  const resetRateSelection = () => {
    setTaksitMonth('');
    setTaksitFinalPrice(null);
    setHasTaksit(false);
  };

  const [agree, setAgree] = useState(false);
  const [noPhoneCall, setNoPhoneCall] = useState(false);
  const [currentShippingMethods, setCurrentShippingMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const standardShippingMethods = [
    {
      code: 'BAYSART',
      name: 'Baysart Shipping',
      price: 10,
      description: '7 gün ərzində',
      enabled: true,
      days: 7,
    },
  ];

  const formatDeliveryOptions = basketItems => {
    // Check if all items are from the same store
    const firstStoreId = basketItems[0].product.store.id;
    const allSameStore = basketItems.every(
      item => item.product.store.id === firstStoreId
    );

    if (!allSameStore) {
      return standardShippingMethods;
    }

    let storeDeliveryOptions = [];

    if (allSameStore) {
      // Get delivery options from the first item (since they're all the same)
      const deliveryOptions = basketItems[0].product.store.delivery_options;

      // Format the delivery options
      storeDeliveryOptions = deliveryOptions.map(option => ({
        code: option.option_code
          ? option.option_code
          : option.option_name.replace(/\s+/g, '_').toUpperCase(),
        name: option.option_name,
        price: parseFloat(option.option_fee),
        description: option.option_description,
        enabled: true,
        days: parseInt(option.option_days, 10),
      }));
    }

    // Combine store delivery options with current shipping methods
    return [...storeDeliveryOptions, ...standardShippingMethods];
  };

  const handleSelectCargo = method => {
    setSelectedMethod(method);
    setShipperName(method.code);
    setSelectedShipperFee(method.price);
    handleCheckoutUpdate({
      shipping_method_name: method.name,
      shipping_method_price: method.price,
    });
  };

  const handleGetCheckouts = () => {
    orderService
      .getCheckoutLines()
      .then(response => {})
      .catch(error => {
        navigate('/shop');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!state) {
      navigate('/shop');
    }

    // handleGetCheckouts();

    console.log(checkoutLines);

    handleGetAddresses();
    handleSumTax();
    setSelectedShipperFee(null);
    selectCheckoutId();
    const shippingClients = formatDeliveryOptions(state.baskets);
    setCurrentShippingMethods(shippingClients);
  }, [state]);

  const handleGetAddresses = () => {
    userService
      .getAddress()
      .then(response => {
        if (response.status === 200) {
          setAddresses(response.data.results);
          setSelectAddress(response.data.results[0].id);
          handleCheckoutUpdate({
            shipping_address: response.data.results[0].id,
            billing_address: response.data.results[0].id,
          });
        }
      })
      .catch(error => {});
  };

  const handleMultipleActions = () => {
    setNoPhoneCall(!noPhoneCall);
    handleCheckoutUpdate({ order_verify_by_call: !noPhoneCall });
  };

  const selectCheckoutId = () => {
    const checkoutId = state?.baskets[0].checkout_id;
    setCheckoutId(checkoutId);
  };

  const handleSumTax = () => {
    let totalTax = 0;
    state?.baskets?.forEach(basket => {
      totalTax += basket.product.tax;
    });

    return totalTax;
  };

  const findGrandTotal = () => {
    let grandTotal = 0;

    const subTotal = state?.subTotal || 0;
    const discountTotal = state?.discount || 0;
    const taxTotal = handleSumTax();
    const shipping = selectedShipperFee || 0;

    grandTotal = (subTotal - discountTotal + taxTotal + shipping).toFixed(2);

    return parseFloat(grandTotal);
  };

  const handleSubmitPayment = (e, payment_gateway) => {
    e.preventDefault();
    setIsLoading(true);
    if (selectAddress === null || !email.length || shipperName === null) {
      nahToast('Bütün səhnələri seçdiyinizə əmin olun!');
    } else {
      handleCheckoutUpdate({
        email,
        payment_method: payment_gateway === 'PAYCASH' ? 'CSH' : 'CRD',
        sub_total_price: (state?.subTotal).toFixed(2),
        total_price: findGrandTotal(),
      });

      const orderData = {
        payment_gateway: payment_gateway,
        email: email,
        shipping_address: selectAddress,
        billing_address: selectAddress,
        payment_method: payment_gateway === 'PAYCASH' ? 'CSH' : 'CRD',
        shipping_method_price: selectedShipperFee,
        sub_total_price: (state?.subTotal).toFixed(2),
        total_price: findGrandTotal(),
        checkout_id: checkoutId,
        order_verify_by_call: noPhoneCall,
        taksit: hasTaksit,
        taksit_monthly_payment: (state?.subTotal).toFixed(2),
        taksit_months: taksitMonth,
      };

      orderService
        .paymentRequest(orderData)
        .then(response => {
          if (
            response.data.status === 'success' &&
            response.data.redirect_url
          ) {
            window.open(response.data.redirect_url, '_self');
          }
        })
        .catch(error => {
          nahToast('Xəta baş verdi!');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleChangeAddress = e => {
    e.preventDefault();
    // setIsLoadingCargo(true);

    setSelectAddress(e.target.value);

    handleCheckoutUpdate({
      shipping_address: e.target.value,
      billing_address: e.target.value,
    });
  };

  const handleCheckoutUpdate = data => {
    orderService
      .updateCheckout(data)
      .then(response => {
      })
      .catch(error => {if (error.response.status===404){
        navigate('/shop')
      }})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const modalRegulations = () => {
    return (
      <Modal activeModal={activeModal} setActiveModal={setActiveModal}>
        <div className="relative bg-primary-white rounded-lg shadow">
          <div className="flex justify-between items-start p-4 rounded-t border-b border-shadow-active">
            <button
              onClick={() => setActiveModal(!activeModal)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4">
            <PersonalDataPolicy></PersonalDataPolicy>
          </div>
        </div>
      </Modal>
    );
  };

  const modalUserAgreement = () => {
    return (
      <Modal activeModal={activeModalSec} setActiveModal={setActiveModalSec}>
        <div className="relative bg-primary-white rounded-lg shadow">
          <div className="flex justify-between items-start p-4 rounded-t border-b border-shadow-active">
            <button
              onClick={() => setActiveModalSec(!activeModalSec)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4">
            <MembershipPolicies></MembershipPolicies>
          </div>
        </div>
      </Modal>
    );
  };

  const renderCheckFaqPage = () => {
    return (
      <div className="shadow-header w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-6 mb-2">
        <div className="px-1 my-1">
          <div className="product-icon-container">
            <div className="product-icon-image">
              <img src={FAQicon} width={60}></img>
            </div>

            <div className="product-icon-text">
              <div className="w-full">
                <p className="text-[#4F4F4F] sm:text-sm sm:leading-3">
                  {t('check-out.questions_and_hints')}
                </p>
              </div>
            </div>
          </div>

          <a href="/faq" style={{ textDecoration: 'underline' }}>
            <p className="mt-2 px-2 text-sm text-gray-400">
              {t('check-out.questions_and_hints_description')}
            </p>
          </a>
        </div>
      </div>
    );
  };

  const renderCommonDetails = () => {
    return (
      <div className="py-2">
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">Əlaqə nömrəsi</span>
          <span className="text-[#4F4F4F]">{user.phone}</span>
        </div>
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">E-poçt</span>
          <span className="text-[#4F4F4F]">{email ? email : user.email}</span>
        </div>
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">Çatdırılma xidməti</span>
          <span className="text-[#4F4F4F]">
            {selectedMethod ? selectedMethod?.name : '-'}
          </span>
        </div>
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">Çatdırılma qiyməti</span>
          <span className="text-[#4F4F4F]">
            {selectedShipperFee ? currencyFormat(selectedShipperFee) : '-'}
          </span>
        </div>
        <hr className="m-2 text-gray"></hr>
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">{t('check-out.subtotal')}</span>
          <span className="text-[#4F4F4F]">
            {currencyFormat(state?.subTotal)}
          </span>
        </div>
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">{t('check-out.discount')}</span>
          <span className="text-[#4F4F4F]">
            -{currencyFormat(state?.discount)}
          </span>
        </div>
        <div className="flex items-center justify-between mb-2 px-2">
          <span className="text-[#4F4F4F] ">{t('check-out.tax')}</span>
          <span className="text-[#4F4F4F]">
            {currencyFormat(handleSumTax())}
          </span>
        </div>
        <div className="w-[330px] md:w-[666px] h-0 border border-[#e0e0e0] mx-auto borderContent"></div>
        <div className="flex items-center mt-3 justify-between my-2 px-2">
          <span className="text-md leading-[25px] text-[#574fa0]">
            {t('check-out.grand-total')}
          </span>
          <span className="text-md text-[#574fa0]">
            {currencyFormat(findGrandTotal())}
          </span>
        </div>
      </div>
    );
  };

  const infoJumbotron = () => {
    return (
      <div className="bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg m-auto overflow-hidden w-full px-4 py-4">
        <h1 className="text-md" style={{ color: '#574fa0' }}>
          {t('check-out.delivery_payment')}
        </h1>
        <p className="mt-2 text-gray-500">
          {t('check-out.delivery_payment_desc')} <br />
        </p>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto font-[sans-serif] bg-gray-100">
      {modalRegulations()}
      {modalUserAgreement()}
      <div className="max-w-7xl mx-auto p-1">
        {infoJumbotron()}

        <div className="grid lg:grid-cols-3 gap-3 relative mt-10">
          <div className="lg:col-span-2 lg:space-y-12 checkout-content">
            {/* <CheckoutLines products={state?.baskets} /> */}
            <CargoItem
              shippingMethods={currentShippingMethods}
              selectedMethod={selectedMethod}
              handleSelectCargo={handleSelectCargo}
            />
            <CheckoutDeliveryAddress
              selectAddress={selectAddress}
              handleChangeAddress={handleChangeAddress}
              handleGetAddress={handleGetAddresses}
              addresses={addresses}
              email={email}
              setEmail={setEmail}
            />
            <CheckoutRatePayment
              activeTaksitModal={activeTaksitModal}
              setActiveTaksitModal={setActiveTaksitModal}
              taksitMonth={taksitMonth}
              handleRadioChange={handleRadioChange}
              findGrandTotal={findGrandTotal}
              taksitFinalPrice={taksitFinalPrice}
              hasTaksit={hasTaksit}
              handleSubmitPayment={handleSubmitPayment}
              resetRateSelection={resetRateSelection}
            />
            <CheckoutTermsAndConditions
              noPhoneCall={noPhoneCall}
              handleMultipleActions={handleMultipleActions}
              agree={agree}
              setAgree={setAgree}
              setActiveModal={setActiveModal}
              setActiveModalSec={setActiveModalSec}
            />
          </div>
          <div className="bg-white border-t-[3px] border-[#847CDB] h-max rounded-md p-2  shadow-header sticky top-0 priceContent">
            {renderCommonDetails()}
            <CheckoutPaymentOptions
              agree={agree}
              email={email}
              handleSubmitPayment={handleSubmitPayment}
              setActiveTaksitModal={setActiveTaksitModal}
              taksitOptionSelected={hasTaksit}
            />

            {renderCheckFaqPage()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
