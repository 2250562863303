import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import useWindowSize from '../../hooks/useWindowSize';
import productService from '../../services/product.service';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/accordion.css';
import '../../assets/styles/categories.css';
import '../../assets/styles/commons.css';
import ProductListingsBreadcrumbs from '../../components/ProductsListing/BreadCrumbs';
import ProductListPanel from '../../components/ProductsListing/ProductListPanel';

const Search = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const { t, i18n } = useTranslation();
  const [categories, setMainCats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [childrenCats, setChildrenCats] = useState([]);

  const [productValues, setProductValues] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productBrands, setProductBrands] = useState([]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [productSizes, setSizes] = useState([]);
  const [productColors, setColors] = useState([]);

  const [fixMinPrice, setFixMinPrice] = useState(null);
  const [fixMaxPrice, setFixMaxPrice] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [initialFilters, setInitialFilters] = useState({
    search: queryParams.get('q') || '',
    store_id: null,
    free_delivery: null,
    min_price: null,
    max_price: null,
    category_id: null,
    attributes: null,
    brand: null,
    is_stock: null,
    color_code: null,
    size_code: null,
    sort_by: 'price',
    sort_order: 'asc',
  });

  const [filters, setFilters] = useState(initialFilters);

  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const [sorted, setSorted] = useState('-1');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterProduct = useCallback(async newFilters => {
    setIsLoading(true);
    try {
      const response = await productService.filterProducts(newFilters);
      setProducts(response.data.products);
      setProductBrands(response.data.distinct_brands);
      setSizes(response.data.distinct_sizes);
      setColors(response.data.distinct_colors);
      setFixMinPrice(response.data.min_price);
      setFixMaxPrice(response.data.max_price);
      setTotalProducts(response.data.total_count);
      setFilters(newFilters);
    } catch (error) {
      console.error('Error fetching filtered products:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFilterProduct(initialFilters);
  }, [handleFilterProduct, initialFilters]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleSortProducts = (sortBy, sortOrder) => {
    const newFilters = {
      ...filters,
      sort_by: sortBy,
      sort_order: sortOrder,
    };
    handleFilterProduct(newFilters);
  };

  const renderSidebarContent = () => {
    const sidebarProps = {
      categoriesList: childrenCats,
      setMaxPrice: setMaxPrice,
      setMinPrice: setMinPrice,
      brands: productBrands,
      colors: productColors,
      sizes: productSizes,
      fixMinPrice: fixMinPrice,
      fixMaxPrice: fixMaxPrice,
      isSearchPanel: true,
      handleFilterProduct: handleFilterProduct,
      productValues: productValues,
      initialFilters: initialFilters,
      search_keyword: queryParams.get('q')
    };

    if (!isMobile) {
      return <Sidebar {...sidebarProps} />;
    }

    return (
      <div className="accordion">
        <div className="accordion-header" onClick={handleToggle}>
          <h3>Filters</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <Sidebar {...sidebarProps} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-auto max-w-full p-5">
      <ProductListingsBreadcrumbs
        isSearchPage={true}
        searchQuery={queryParams.get('q')}
        totalProducts={totalProducts}
        categories={categories}
      />
      <div className="lg:flex justify-center gap-4">
        <div className="lg:w-1/6">{renderSidebarContent()}</div>
        <div className="lg:w-5/6 w-full">
          <ProductListPanel
            products={products}
            totalProducts={totalProducts}
            productsPaginationInfo={productsPaginationInfo}
            handleSortProducts={handleSortProducts}
            
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
