import React from 'react';

const PriceFilter = ({ setMinPrice, setMaxPrice, t }) => {
  return (
    <div className="mt-4">
      <h6 className="text-[#4e4790] text font-bold px-2">
        {t('common.price')}
      </h6>
      <div className="price-filter items-center justify-between mb-3">
        <div className="space-y-2 font-sans max-w-md mx-auto p-2">
          <input
            type="number"
            placeholder={`Minimum price`}
            onChange={e => setMinPrice(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
          <input
            type="number"
            placeholder={`Maximum price`}
            onChange={e => setMaxPrice(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default PriceFilter;
