import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/slider.css';
import { setCategories } from '../../redux/actions/catAction';
import categoryService from '../../services/category.service';
import MobileVerticalTabs from '../MobileVerticalTabs';
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  const { cats_panel } = useSelector(state => state.cats);

  const state = cats_panel == true ? '-open' : '';
  const currentClass = 'sidebar' + state;
  const [mainCats, setMainCats] = useState(
    JSON.parse(sessionStorage.getItem('categoriesCached'))
  );

  useEffect(() => {
    if (mainCats) {
    } else {
      categoryService.getBigCategories().then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          setMainCats(responseData);
        }
      });
    }
  }, [mainCats]);

  const topCats = [
    {
      name: 'iPhone Series',
      slug: 'iphone-series',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=iphone-series',
      alt_name: 'iPhone seriyası',
    },
    {
      name: 'Android Phones',
      slug: 'android-phones-samsung-google-pixel-etc',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=android-phones-samsung-google-pixel-etc',
      alt_name: 'Android telefonlar',
    },
    {
      name: 'Phone Cases & Covers ',
      slug: 'phone-cases-covers',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=phone-cases-covers',
      alt_name: 'Telefon örtükləri',
    },

    {
      name: 'Gaming Laptops',
      slug: 'gaming-laptops',
      href: 'mainCategory=elektronika&subCategory=gaming-laptops',
      alt_name: 'Oyun laptopları',
    },
    {
      name: 'Smartwatches',
      slug: 'smartwatches',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=smartwatches',
      alt_name: 'Ağıllı saatlar',
    },
  ];

  const bestCats = [
    {
      name: 'iPhone Series',
      slug: 'iphone-series',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=iphone-series',
      alt_name: 'iPhone seriyası',
    },
    {
      name: 'Winter Hats',
      slug: 'beanies-winter-hats',
      href: 'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=beanies-winter-hats',
      alt_name: 'Qış papaqları',
    },
    {
      name: 'Handbags',
      slug: 'handbags',
      href: 'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=handbags',
      alt_name: 'Əl çantaları',
    },

    {
      name: 'Winter Gloves',
      slug: 'winter-gloves',
      href: 'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=winter-gloves',
      alt_name: 'Qış əlcəkləri',
    },

    {
      name: 'Coats & Jackets',
      slug: 'coats-jackets',
      href: 'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=coats-jackets',
      alt_name: 'Paltolar və Gödəkçələr',
    },
    {
      name: 'Jeans & Denim',
      slug: 'jeans-denim',
      href: 'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=jeans-denim',
      alt_name: 'Cins şalvar və Denim',
    },
    {
      name: 'Sneakers',
      slug: 'sneakers',
      href: 'mainCategory=komputer-sistemleri&subCategory=null&thirdCategory=sneakers',
      alt_name: 'Sneakerlər',
    },
  ];
  const newCats = [
    {
      name: 'Chargers & Cables',
      slug: 'chargers-cables',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=chargers-cables',
      alt_name: 'Şarj Cihazları və Kabellər',
    },
    {
      name: 'Wearable Accessories',
      slug: 'wearable-accessories',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=wearable-accessories',
      alt_name: 'Geyinilə bilən aksesuarlar',
    },
    {
      name: 'Wireless Mice',
      slug: 'wireless-mice',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=wireless-mice',
      alt_name: 'Simsiz Siçanlar',
    },

    {
      name: 'Laptop Bags & Cases',
      slug: 'laptop-bags-cases',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=laptop-bags-cases',
      alt_name: 'Noutbuk Çantaları və örtükləri',
    },
    {
      name: 'Professional Video Cameras',
      slug: 'professional-video-cameras',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=professional-video-cameras',
      alt_name: 'Professional Video Kameralar',
    },
    {
      name: 'Power Banks for Smartphones',
      slug: 'power-banks-for-smartphones',
      href: 'mainCategory=elektronika&subCategory=elektronika&thirdCategory=power-banks-for-smartphones',
      alt_name: 'Smartfonlar üçün Power Banklar',
    },
  ];

  const onPanelStateChange = () => {
    dispatch(setCategories(false));
  };

  return (
    <div id="mySidebar" className={currentClass}>
      <div className="slider-top-design">
        <button className="go-back-button" onClick={() => onPanelStateChange()}>
          <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
        </button>
      </div>
      {window.innerWidth < 600 ? (
        <MobileVerticalTabs
          mainCats={mainCats}
          topCats={topCats}
          bestCats={bestCats}
          newCats={newCats}
        ></MobileVerticalTabs>
      ) : null}
    </div>
  );
};
