import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import '../../assets/styles/commons.css';
import FavoritesCollectionsBanner from '../../components/Favourites/FavoritesCollections';
import FavoritesBanner from '../../components/Favourites/FavsBanner';
import CategoriesBanner from '../../components/Favourites/LikedCats';
import StoresBanner from '../../components/Favourites/LikedStores';
import QuickContact from '../../components/QuickContact';
import { addToFavs } from '../../redux/actions/headerAction';
import userService from '../../services/user.service';
import { nahToast } from '../../utils/Toaster';
import {
  getGuestBasketCount,
  isUserLoggedIn,
  removeFavProduct,
} from '../../utils/Utils';

const Favorites = () => {
  const isLoggedUser = isUserLoggedIn();
  const { t } = useTranslation();

  const [favorites, setFavorites] = useState([]);
  const [storeSubs, setStoreSubs] = useState([]);
  const [catSubs, setCatSubs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedUser) {
      handleGetFavorites();
      handleGetStoreSubscriptions();
      handleGetCatSubscriptions();
    } else {
      loadFavoritesFromStorage();
    }
  }, [isLoggedUser]);

  const handleGetStoreSubscriptions = () => {
    setIsLoading(true);
    userService
      .getUserStoreSubscriptions()
      .then(response => {
        if (response.status === 200) {
          setStoreSubs(response.data);
        }
      })
      .catch(error => {
        nahToast('Xəta baş verdi!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetCatSubscriptions = () => {
    setIsLoading(true);
    userService
      .getUserCategorySubscriptions()
      .then(response => {
        if (response.status === 200) {
          setCatSubs(response.data);
        }
      })
      .catch(error => {
        nahToast('Xəta baş verdi!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetFavorites = page => {
    setIsLoading(true);
    userService
      .getFavorites(page)
      .then(response => {
        if (response.status === 200) {
          setFavorites(response.data.results);
          setProductsPaginationInfo({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          });
        }
      })
      .catch(error => {
        nahToast('Xəta baş verdi!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadFavoritesFromStorage = () => {
    setIsLoading(false);
    let existingGuestBasket = localStorage.getItem('guestProductFavorites');
    setFavorites(JSON.parse(existingGuestBasket));
  };

  const handleRemoveFavorite = productID => {
    setIsLoading(true);
    userService
      .removeFavorite(productID)
      .then(response => {
        if (response.status === 204) {
          handleGetFavorites();
          dispatch(addToFavs(response.data.total));
        }
      })
      .catch(error => {
        nahToast('Xəta baş verdi!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRemoveSubscription = subsId => {
    setIsLoading(true);
    userService
      .dislikeUserSubscription(subsId)
      .then(response => {
        if (response.status === 204) {
          setStoreSubs(response.data);
        }
      })
      .catch(error => {
        nahToast('Xəta baş verdi!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const guestRemoveFavProduct = id => {
    removeFavProduct(id);
    loadFavoritesFromStorage();
    dispatch(addToFavs(getGuestBasketCount()));
  };

  // const handleToggleFavorite = subsId => {
  //   setIsLoading(true);
  //   userService
  //     .toggleUserSubscription(subsId)
  //     .then(response => {
  //       if (response.status === 200) {
  //         console.log(response.data);
  //       }
  //     })
  //     .catch(error => {
  //       nahToast('Xəta baş verdi!');
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto lg:max-w-7xl">
      <FavoritesBanner
        favorites={favorites}
        isLoggedUser={isLoggedUser}
        handleRemoveFavorite={handleRemoveFavorite}
        guestRemoveFavProduct={guestRemoveFavProduct}
      />
      <FavoritesCollectionsBanner />
      <StoresBanner
        storeSubs={storeSubs}
        handleRemoveSubscription={handleRemoveSubscription}
      ></StoresBanner>
      <CategoriesBanner
        catSubs={catSubs}
        handleRemoveSubscription={handleRemoveSubscription}
      ></CategoriesBanner>
      <div className="bg-[#f7f6fa] mb-20 mt-10 p-5 shadow-header">
        <QuickContact></QuickContact>
      </div>
    </div>
  );
};

export default Favorites;
