import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Search from '../../assets/img/icon/search.svg';
import '../../assets/styles/header.css';
import '../../assets/styles/image-grid.css';
import productService from '../../services/product.service';

import categoryService from '../../services/category.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import { MostSearched } from './mostSearched';
import { MostSuggested } from './mostSuggested';
import { SearchResults } from './searchResults';
import ProductsList from '../../pages/ProductsList';
export const SearchPanel = () => {
  const navigate = useLanguageNavigate();

  const { t } = useTranslation();
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCats, setSearchCats] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchSubmit = () => {
    setShowSearchResult(false);
    navigate(`/search?q=${searchValue}`);
    // navigate(<ProductsList/>)
    // <ProductsList/>
  };

  const [options, setMainCats] = useState(
    JSON.parse(sessionStorage.getItem('categoriesCached'))
  );

  useEffect(() => {
    if (options) {
    } else {
      categoryService.getBigCategories().then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          setMainCats(responseData);
        }
      });
    }
  }, [options]);

  const handleSearchProduct = e => {
    if (e.target.value.length > 3) {
      setShowSearchResult(true);
      setSearchValue(e.target.value);

      productService
        .getProductForSearch(e.target.value)
        .then(response => {
          if (response.status === 200) {
            setSearchProducts(response.data.products);
            // setSearchCats(response.data.found_categories);
          }
        })
        .catch(error => {
          throw error.response.data;
        });
    } else {
      setShowSearchResult(false);
    }
  };

  return (
    <div className="search-bar-container">
      <div className="search-input-wrapper">
        <div className="search-container">
          <input
            className="search-input px-5"
            name="search"
            id="search"
            type="text"
            onKeyDown={e => (e.code === 'Enter' ? handleSearchSubmit() : null)}
            onInput={e => handleSearchProduct(e)}
            placeholder={t('header.i_am_purchasing')}
          />

          <div className="search-dropdown"></div>
          <button
            onClick={() => handleSearchSubmit()}
            className="search-button"
          >
            <img src={Search} alt="Search" />
          </button>
        </div>
      </div>

      <div
        className={classNames('results-list', {
          hidden: !showSearchResult,
        })}
      >
        <div>
          <div className="main-container">
            <MostSearched display={false}></MostSearched>

            <SearchResults
              searchCats={searchCats}
              searchProducts={searchProducts}
              showSearchResult={showSearchResult}
            ></SearchResults>

            <section
              className={classNames('other-products', {
                hidden: searchProducts.length > 0 && showSearchResult,
              })}
            >
              <h2>{t('header.other_products')}</h2>
              <MostSuggested display={true}></MostSuggested>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
