import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Sad from '../../assets/img/icon/sad.png';
import '../../assets/styles/header.css';
import '../../assets/styles/image-grid.css';
import { constructCatProductsURL } from '../../utils/Utils';
export const SearchResults = ({
  searchCats,
  searchProducts,
  showSearchResult,
}) => {
  const { t} = useTranslation();

  return (
    <>
      <section>
        {searchCats && searchCats.length
          ? searchCats.map((result, index) => (
              <li key={index}>
                <Link
                  key={result.id}
                  to={
                    '/categories/products?' +
                    constructCatProductsURL(result?.slug, result.parents)
                  }
                  className="mb-[10px]"
                  onClick={() => this.setInputFocus(false)}
                >
                  <div className="flex pl-[10px]">
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{ padding: '5px', fontSize: '15px' }}
                    />
                    <div className="ml-[10px]">
                      <div className="search-result-text">{result.name}</div>
                    </div>
                  </div>
                </Link>
              </li>
            ))
          : null}
      </section>
      <section>
        {searchProducts && searchProducts.length ? (
          searchProducts.splice(0, 8).map((result, index) => (
            <li key={index}>
              <Link
                key={result.id}
                to={`/products/${result?.slug}`}
                className="mb-[10px]"
                onClick={() => this.setInputFocus(false)}
              >
                <div className="flex pl-[10px]">
                  <img src={result.image} alt="" className="w-10 h-10" />
                  <div className="ml-[10px]">
                    <div className="search-result-text">{result.name}</div>
                  </div>
                </div>
              </Link>
            </li>
          ))
        ) : (
          <li style={{ display: !showSearchResult ? 'none' : 'block' }}>
            <div className="search-result-container">
              <div className="search-result-img">
                <img src={Sad} width={25} alt="nothing-found"></img>
              </div>
              <div className="search-result-text">
                {t('header.nothing_found')}
              </div>
            </div>
          </li>
        )}
      </section>
    </>
  );
};
