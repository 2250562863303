import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Store from '../../assets/img/icon/gdt-online-store-50.png';
import '../../assets/styles/commons.css';
import '../../assets/styles/shop-page.css';
import QuickContact from '../../components/QuickContact';
import useWindowSize from '../../hooks/useWindowSize';
import orderService from '../../services/order.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import {
  currencyFormat,
  isUserLoggedIn,
  removeBasketProduct,
} from '../../utils/Utils';

const Shop = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [baskets, setBaskets] = useState([]);
  const { width } = useWindowSize();
  const { t, i18n } = useTranslation();
  const navigate = useLanguageNavigate();

  const isLoggedUser = isUserLoggedIn();
  const isMobile = width < 768;

  const fetchCheckoutLine = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = isLoggedUser
        ? await orderService.getCheckoutLines()
        : {
            data: {
              results:
                JSON.parse(localStorage.getItem('guestProductBasket')) || [],
            },
          };

      if (response.status === 200 || !isLoggedUser) {
        setBaskets(response.data.results);
      }
    } catch (error) {
      nahToast('Xəta baş verdi!');
    } finally {
      setIsLoading(false);
    }
  }, [isLoggedUser]);

  useEffect(() => {
    fetchCheckoutLine();
  }, [fetchCheckoutLine]);

  const calculateSubtotal = () =>
    baskets.reduce((sum, item) => sum + item.sub_total, 0);

  const calculateDiscount = () =>
    baskets.reduce((sum, item) => {
      if (item.product.is_discount) {
        return sum + (item.sub_total - item.product.discount_price);
      }
      return sum;
    }, 0);

  const calculateTotal = () => calculateSubtotal() - calculateDiscount();

  const handleUpdateBasket = async (product, quantity) => {
    try {
      const response = await orderService.createCheckoutLines({
        product: product.id,
        quantity,
      });
      if (response.status === 201) {
        goodToast('Məhsul sayı yeniləndi!');
        fetchCheckoutLine();
      }
    } catch (error) {
      nahToast('Xəta baş verdi!');
    }
  };

  const handleDeleteBasket = async id => {
    try {
      await orderService.deleteCheckoutLine(id);
      fetchCheckoutLine();
    } catch (error) {
      nahToast('Xəta baş verdi!');
    }
  };

  const renderBasketItem = basket => (
    <div className="p-2 sm:p-1 bg-white relative">
      <div className="flex items-center max-sm:flex-col gap-4">
        <a
          href={`/${i18n.language}/products/${basket?.product.slug}`}
          className="product-image__image"
        >
          <div className="w-20 h-[200px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2">
            <img
              src={basket?.product.image}
              alt={basket?.product.slug}
              className="h-full w-full object-contain"
            />
          </div>
        </a>
        <div className="sm:border-l sm:pl-4 w-full">
          <h3 className="text-md text-[#333]">
            <Link
              to={`/products/${basket?.product.slug}`}
              className="text-sm sm:text-sm leading-5 sm:leading-3 md:w-[224px]"
            >
              {basket.product.name}
            </Link>
          </h3>
          <ul className="mt-4 text-sm text-[#333] space-y-2">
            <li>
              <Link
                to={`/products/${basket?.product.slug}`}
                className="text-sm sm:text-sm leading-5 sm:leading-3 md:w-[224px]"
              >
                ({t('common.see_specs')})
              </Link>
            </li>
            {isLoggedUser && (
              <li>
                <div className="flex mt-[7px] items-center product-icon-container">
                  by
                  <span className="text-active mr-2 product-icon-text">
                    <Link
                      to={`/store/${basket.product?.store_slug}`}
                      className="leading-3 text-active hover:cursor-pointer"
                    >
                      {basket.product.store.full_name}
                    </Link>
                  </span>
                </div>
              </li>
            )}
          </ul>
          <div className="flex items-center justify-between flex-wrap gap-4">
            <div className="flex items-center gap-4">
              <h6 className="text-[#333] text-sm">{t('shop.quantity')}:</h6>
              <button
                type="button"
                className="bg-transparent py-2 text-[#333]"
                onClick={() =>
                  handleUpdateBasket(basket.product, basket.quantity - 1)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 fill-current"
                  viewBox="0 0 124 124"
                >
                  <path d="M112 50H12C5.4 50 0 55.4 0 62s5.4 12 12 12h100c6.6 0 12-5.4 12-12s-5.4-12-12-12z"></path>
                </svg>
              </button>
              <button
                type="button"
                className="bg-transparent px-4 py-2 text-[#333] shadow-[0_0px_4px_0px_rgba(6,81,237,0.2)]"
              >
                {basket.quantity}
              </button>
              <button
                onClick={() =>
                  handleUpdateBasket(basket.product, basket.quantity + 1)
                }
                type="button"
                className="bg-transparent py-2 text-[#333]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 fill-current"
                  viewBox="0 0 42 42"
                >
                  <path d="M37.059 16H26V4.941C26 2.224 23.718 0 21 0s-5 2.224-5 4.941V16H4.941C2.224 16 0 18.282 0 21s2.224 5 4.941 5H16v11.059C16 39.776 18.282 42 21 42s5-2.224 5-4.941V26h11.059C39.776 26 42 23.718 42 21s-2.224-5-4.941-5z"></path>
                </svg>
              </button>
            </div>
            <div className="flex items-center">
              <h4 className="font-bold text-[#333]">
                {basket.product.is_discount
                  ? currencyFormat(
                      parseFloat(
                        basket.product.discount_price * basket.quantity
                      )
                    )
                  : currencyFormat(
                      parseFloat(basket.product.price * basket.quantity)
                    )}
              </h4>
              <svg
                onClick={() => {
                  isLoggedUser
                    ? handleDeleteBasket(basket.id)
                    : removeBasketProduct(basket.product.id);
                  fetchCheckoutLine();
                }}
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 cursor-pointer shrink-0 fill-[#333] hover:fill-red-500 absolute top-3 right-3"
                viewBox="0 0 320.591 320.591"
              >
                <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"></path>
                <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderEmptyBasket = () => (
    <div className="mt-10 h-max mt-10 mb-20 items-center font-[sans-serif] text-[#333] mx-auto">
      <div className="max-md:order-1 max-md:text-center">
        <h2 className="md:text-md text-md md:leading-10 font-bold text-[#333] mb-4">
          {t('common.your_cart_empty')}
        </h2>
        <p className="mt-4 text-base text-[#666] leading-relaxed">
          {t('common.your_cart_empty_helptext')}
        </p>
        <button
          type="button"
          onClick={() => navigate('/')}
          className="px-6 py-3 mt-10 rounded text-sm tracking-wider border outline-none bg-white hover:bg-[#5856d6] hover:text-white"
        >
          {t('common.continue_shopping')}
        </button>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-[50px] lg:max-w-8xl sm:px-1 mx-auto ">
      {baskets.length > 0 ? (
        <div className="mx-auto p-2 sm:p-2">
          <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-2 relative">
            <div className="lg:col-span-2 space-y-2 bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg overflow-hidden w-full">
              <div className="text-[#333] shadow-header px-8 py-8 w-full rounded-lg font-[sans-serif]">
                <h1 className="text font-bold mb-5">
                  {t('common.shopping_cart')}
                </h1>
                {baskets.map(basket => renderBasketItem(basket))}
              </div>
            </div>
            <div className="bg-white h-max rounded-md p-5 shadow-header sticky top-0 bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg overflow-hidden w-full">
              {isLoggedUser ? (
                <ul className="text-[#333] text">
                  <li className="flex flex-wrap py-1">
                    {t('common.subtotal')}
                    <span className="ml-auto font-bold">
                      {currencyFormat(calculateSubtotal())}
                    </span>
                  </li>
                  <li className="flex flex-wrap py-1">
                    {t('common.discount')}
                    <span className="ml-auto font-bold">
                      {currencyFormat(calculateDiscount())}
                    </span>
                  </li>
                  <li className="flex flex-wrap py-1 font-bold text-[#574fa0]">
                    {t('common.total')}
                    <span className="ml-auto">
                      {currencyFormat(calculateTotal())}
                    </span>
                  </li>
                </ul>
              ) : (
                <div
                  className="price-list-container"
                  style={{ marginBottom: '20px', marginTop: '0px' }}
                >
                  {t('common.end_process')}
                </div>
              )}
              <button
                className="w-full baysart-main-button mt-5"
                onClick={() =>
                  isLoggedUser
                    ? navigate('/shop/checkout', {
                        state: {
                          baskets,
                          subTotal: calculateSubtotal(),
                          discount: calculateDiscount(),
                          total: calculateTotal(),
                        },
                      })
                    : navigate('/account/sign-in')
                }
              >
                {t('shop.checkout')}
              </button>
              <button
                className="w-full baysart-secondary-button mb-[10px]"
                onClick={() => navigate('/')}
              >
                {t('shop.back')}
              </button>
              <button
                className="w-full baysart-secondary-button"
                onClick={fetchCheckoutLine}
              >
                {t('shop.update')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        renderEmptyBasket()
      )}
      <div className="bg-[#f7f6fa] mb-20 p-5 shadow-header">
        <QuickContact />
      </div>
    </div>
  );
};

export default Shop;
