import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AttributeList from '../ProductsListing/AttributeList';
import BrandList from '../ProductsListing/BrandList';
import CategoryList from '../ProductsListing/CategoryList';
import PriceFilter from '../ProductsListing/PriceFilter';
import ColorsList from '../ProductsListing/ColorsList';
import SizesList from '../ProductsListing/SizesList';

const Sidebar = ({
  categoriesList = [],
  setMaxPrice,
  setMinPrice,
  brands = [],
  colors = [],
  sizes = [],
  fixMinPrice = null,
  fixMaxPrice = null,
  search_keyword = null,
  isSearchPanel = false,
  handleFilterProduct,
  productValues,
  initialFilters = {}, // Provide a default empty object
}) => {
  const { t, i18n } = useTranslation();
  const [selectedBrandsFilters, setSelectedBrandsFilters] = useState(
    initialFilters.brand ? initialFilters.brand.split(',') : []
  );
  const [selectedColorsFilters, setSelectedColorsFilters] = useState(
    initialFilters.color_code ? initialFilters.color_code.split(',') : []
  );
  const [selectedSizesFilters, setSelectedSizesFilters] = useState(
    initialFilters.size_code ? initialFilters.size_code.split(',') : []
  );
  const [selectedAttributesFilters, setSelectedAttributesFilters] = useState(
    initialFilters.attributes
      ? typeof initialFilters.attributes === 'string'
        ? JSON.parse(initialFilters.attributes)
        : initialFilters.attributes
      : {}
  );
  const [minPriceState, setMinPriceState] = useState(
    initialFilters.min_price || null
  );
  const [maxPriceState, setMaxPriceState] = useState(
    initialFilters.max_price || null
  );

  const handleBrandFilterChange = useCallback(filter => {
    setSelectedBrandsFilters(prev =>
      prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter]
    );
  }, []);

  const handleColorFilterChange = useCallback(filter => {
    setSelectedColorsFilters(prev =>
      prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter]
    );
  }, []);

  const handleSizeFilterChange = useCallback(filter => {
    setSelectedSizesFilters(prev =>
      prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter]
    );
  }, []);

  const handleAttributesFilterChange = useCallback((attribute, value) => {
    setSelectedAttributesFilters(prev => {
      const updatedFilters = { ...prev };
      if (!updatedFilters[attribute]) {
        updatedFilters[attribute] = [value];
      } else if (updatedFilters[attribute].includes(value)) {
        updatedFilters[attribute] = updatedFilters[attribute].filter(
          v => v !== value
        );
        if (updatedFilters[attribute].length === 0)
          delete updatedFilters[attribute];
      } else {
        updatedFilters[attribute] = [...updatedFilters[attribute], value];
      }
      return updatedFilters;
    });
  }, []);

  const filterByPriceRange = useCallback((min, max) => {
    setMinPriceState(min);
    setMaxPriceState(max);
  }, []);

  const handleApplyFilters = useCallback(() => {
    const filters = {
      search: search_keyword,
      brand: selectedBrandsFilters.join(','),
      attributes: JSON.stringify(selectedAttributesFilters),
      min_price: minPriceState,
      max_price: maxPriceState,
      color_code: selectedColorsFilters.join(','),
      size_code: selectedSizesFilters.join(','),
    };
    handleFilterProduct(filters);
  }, [
    selectedBrandsFilters,
    selectedColorsFilters,
    selectedSizesFilters,
    selectedAttributesFilters,
    minPriceState,
    maxPriceState,
    handleFilterProduct,
  ]);

  const handleClearFilters = useCallback(() => {
    setSelectedBrandsFilters([]);
    setSelectedColorsFilters([]);
    setSelectedSizesFilters([]);
    setSelectedAttributesFilters({});
    setMinPriceState(null);
    setMaxPriceState(null);
    setMinPrice(null);
    setMaxPrice(null);

    handleFilterProduct({
      brand: '',
      attributes: '{}',
      min_price: null,
      max_price: null,
      color_code: '',
      size_code: '',
    });

    handleApplyFilters();
  }, [handleFilterProduct, setMinPrice, setMaxPrice]);

  return (
    <nav className="bg-white shadow-header sm:min-w-[0px] py-4 px-2 font-sans sticky">
      {!isSearchPanel && (
        <CategoryList categories={categoriesList} t={t} i18n={i18n} />
      )}
      {brands && brands.length > 0 && (
        <BrandList
          brands={brands}
          selectedBrandsFilters={selectedBrandsFilters}
          handleBrandFilterChange={handleBrandFilterChange}
          t={t}
        />
      )}
      {colors && colors.length > 0 && (
        <ColorsList
          colors={colors}
          selectedColorsFilters={selectedColorsFilters}
          handleColorFilterChange={handleColorFilterChange}
          t={t}
        />
      )}
      {sizes && sizes.length > 0 && (
        <SizesList
          sizes={sizes}
          selectedSizesFilters={selectedSizesFilters}
          handleSizeFilterChange={handleSizeFilterChange}
          t={t}
        />
      )}
      {productValues && (
        <AttributeList
          productValues={productValues}
          selectedAttributesFilters={selectedAttributesFilters}
          handleAttributesFilterChange={handleAttributesFilterChange}
          t={t}
        />
      )}
      <PriceFilter
        priceRanges={[fixMinPrice, fixMaxPrice]}
        filterByPriceRange={filterByPriceRange}
        setMinPrice={value => {
          setMinPriceState(value);
          setMinPrice(value);
        }}
        setMaxPrice={value => {
          setMaxPriceState(value);
          setMaxPrice(value);
        }}
        fixMinPrice={fixMinPrice}
        fixMaxPrice={fixMaxPrice}
        t={t}
      />
      <div className="items-center justify-between mb-2">
        <div className="max-w-md mx-auto p-2">
          <button
            onClick={handleApplyFilters}
            className="w-full justify-around cursor-pointer p-2 flex items-center justify-center text-white bg-[#4e4790] hover:bg-[#3d3873] rounded-md transition-colors"
          >
            <span>{t('common.apply_filters')}</span>
          </button>
        </div>
        <div className="max-w-md mx-auto p-2">
          <button
            onClick={handleClearFilters}
            className="w-full justify-around cursor-pointer p-2 flex items-center justify-center text-[#4e4790] border-[1px] border-[#4e4790] bg-white rounded-md transition-colors"
          >
            <span>{t('common.clear_filters')}</span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
