import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import ProductCard from '../../components/ProductCard';

import useWindowSize from '../../hooks/useWindowSize';
import productService from '../../services/product.service';

import { useTranslation } from 'react-i18next';
import '../../assets/styles/categories.css';
import Sidebar from '../../components/Sidebar';
import { useEffectOnce } from '../../utils/useEffectMiddleware';

const Category = props => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const params = useParams();
  const [filterModal, setFilterModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [childrenCats, setChildrenCats] = useState([]);
  const [mainCat, setMainCat] = useState('');
  const [subCat, setSubCat] = useState('');
  const [productValues, setProductValues] = useState([]);
  const [productBrands, setProductBrands] = useState([]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [filters, setFilters] = useState([]);
  const { t} = useTranslation();
  const getLink = (mainCat, subCat, subSubCat) => {
    let link = `/categories/${mainCat}`;

    if (subCat) {
      link += `/${subCat}`;

      if (subSubCat) {
        link += `/${subSubCat}`;
      }
    } else if (subSubCat) {
      link += `/${subSubCat}`;
    }

    return link;
  };

  const [products, setProducts] = useState([]);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const [sorted, setSorted] = useState('-1');
  const location = useLocation();

  const data = location.state?.data;
  useEffectOnce(() => {
    const { secondSlug, slug } = params;
    if (secondSlug) {
      setSubCat(secondSlug);
      handleGetProducts(secondSlug);
      handleGetProductBrands(secondSlug);
    } else if (slug) {
      handleGetProducts(slug);
      setMainCat(slug);
      handleGetProductBrands(slug);
    }

    setChildrenCats(data);
  }, [params]);

  const handleGetProducts = slug => {
    productService
      .getProducts({ category: slug })
      .then(response => {
        setProducts(response.data.results);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
        handleGetProductValue(response.data.results);
      })
      .catch(error => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetProductValue = products => {
    const productsID = [];
    products.forEach(product => {
      productsID.push(product.id);
    });
    productService
      .getProductValue(productsID)
      .then(response => {
        setProductValues(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetProductBrands = catSlug => {
    productService
      .getProductBrands(catSlug)
      .then(response => {
        setProductBrands(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGetPriceRange = e => {
    setIsLoading(true);
    productService
      .getProducts({
        category: params.slug,
        min_price: minPrice,
        max_price: maxPrice,
        sort: sorted,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results);
        }
      })
      .catch(error => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSortProducts = value => {
    setIsLoading(true);
    setSorted(value);
    productService
      .getProducts({
        category: params.slug,
        sort: value,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results);
          setIsLoading(false);
        }
      })
      .catch(error => {});
  };

  const handleFilterProduct = data => {
    const { secondSlug, slug } = params;
    let final_slug = null;
    if (secondSlug && slug) {
      final_slug = secondSlug;
    } else {
      final_slug = slug;
    }
    productService
      .filterProducts(data, {
        category: final_slug,
        min_price: minPrice,
        max_price: maxPrice,
        sort: sorted,
      })
      .then(res => {
        setProducts(res.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleLoadMore = e => {
    e.preventDefault();

    productService
      .getProductsPagination(productsPaginationInfo.next)
      .then(response => {
        setProducts(prev => [...prev, ...response.data.results]);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
      })
      .catch(error => {})
      .finally(() => {});
  };

  return (
    <div className="max-w-[1800px] mx-auto">
      <div className="md:container sm:px-4 mx-auto">
        {!isMobile ? (
          <header className="headear">
            <a className="header-link">{t('header.categories')} !!!!!</a>
            <div className="w-full flex items-center h-full justify-end sm:justify-between pr-6 ">
              <select
                name="popularity"
                id="popularity"
                className="w-[175px] h-[27px] bg-white rounded-[5px] text-sm leading-3 text-center outline-none"
                onChange={e => handleSortProducts(e.target.value)}
              >
                <option className="text-sm leading-3" value="-1">
                  {t('header.filter_for_popularity')} !!!!!
                </option>
                <option className="text-sm leading-3" value="a-z">
                  A-Z
                </option>
                <option className="text-sm leading-3" value="z-a">
                  Z-A
                </option>
                <option className="text-sm leading-3" value="low-high">
                  {t('header.increasing_price')} !!!!!
                </option>
                <option className="text-sm leading-3" value="high-low">
                  {t('header.decreasing_price')} !!!!!
                </option>
              </select>
            </div>
          </header>
        ) : null}

        <div id="category-products-container">
          {!isMobile ? (
            <div id="category-products-categories">
              {childrenCats?.map((cat, index) => (
                <div key={index} className="flex items-center py-2">
                  <Link
                    to={getLink(mainCat, subCat, cat.slug)}
                    state={{ data: cat.children }}
                  >
                    <span className="category-products-category">
                      {cat.name}
                    </span>
                  </Link>
                </div>
              ))}
              <div className="w-[200px] mx-auto h-0 border border-gray opacity-10 mb-[5px] mt-[10px]"></div>

              <Sidebar
                setMinPrice={setMinPrice}
                setMaxPrice={setMaxPrice}
                minPrice={minPrice}
                maxPrice={maxPrice}
                products={products}
                filters={filters}
                setFilters={setFilters}
                handleFilterProduct={handleFilterProduct}
                handleGetPriceRange={handleGetPriceRange}
                productValues={productValues}
                brands={productBrands}
              />
            </div>
          ) : null}
          <div id="category-products-products">
            {isLoading ? (
              <div className="flex flex-col items-center justify-center h-screen">
                <div className="flex items-center justify-center ">
                  <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap mt-[30px] sm:justify-between">
                {isMobile && filterModal && (
                  <div className="absolute w-full z-10 opacity-95 h-screen left-0">
                    <div className="w-[277px] md:w-[225px] sm:w-full sm:min-h-[530px] bg-primary-white px-[31px] md:px-[15px] py-[37px] md:py-[27px] rounded-[5px]">
                      <div className="w-[200px] mx-auto h-0 border border-gray opacity-10 mb-[23px]"></div>
                    </div>
                  </div>
                )}
                {isMobile ? (
                  products && products.length ? (
                    products.map(product => (
                      <div key={product.id} className="mb-6">
                        <ProductCard product={product} />
                      </div>
                    ))
                  ) : (
                    <p className="text-center text-md text-primary w-full p-4 mb-16">
                      {t('header.no_product_for_this_category')} !!!!!
                    </p>
                  )
                ) : products && products.length ? (
                  products.map(product => (
                    <div key={product.id} className="mb-5">
                      <ProductCard product={product} />
                    </div>
                  ))
                ) : (
                  <p className="text-center text-md text-primary w-full p-4 mb-16">
                    {t('header.no_product_for_this_category')} !!!!!
                  </p>
                )}
              </div>
            )}
            {productsPaginationInfo.next && (
              <div
                className="group load-more w-full text-center flex justify-center items-center hover:cursor-pointer transition-all"
                onClick={e => handleLoadMore(e)}
              >
                <div className="group-hover:bg-shadow-hover w-24 h-10 bg-shadow-disabled rounded-xl flex justify-center items-center transition-all">
                  <span className="font-light text-sm leading-3">
                    {t('header.view_more')}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
