import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import ProductCard from '../../components/ProductCard';
import productService from '../../services/product.service';
import { useEffectOnce } from '../../utils/useEffectMiddleware';

const ProductsClassified = () => {
  const [collection, setCollection] = useState({});
  const [products, setProducts] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  useEffectOnce(() => {
    handleGetProductClassification(params.name);
  }, [params.name]);

  const onSelectChange = useCallback(
    selected => {
      switch (selected.target.value) {
        case '-1':
          setSortedProducts(products);
          break;
        case 'a-z':
          setSortedProducts(
            products.sort((a, b) => (a.name > b.name ? 1 : -1))
          );
          break;
        case 'z-a':
          setSortedProducts(
            products.sort((a, b) => (a.name < b.name ? 1 : -1))
          );
          break;
        case 'low-high':
          setSortedProducts(products.sort((a, b) => a.price - b.price));
          break;
        case 'high-low':
          setSortedProducts(products.sort((a, b) => b.price - a.price));
          break;
        default:
          break;
      }
    },
    [products, setSortedProducts]
  );

  const handleGetProductClassification = type => {
    productService
      .getProductsClassified(type)
      .then(res => {
        setCollection(res?.data);
        setProducts(res?.data?.results);
        setIsLoading(false);
      })
      .catch(err => {});
  };

  const renderSortBlock = () => {
    return (
      <select
        name="popularity"
        id="popularity"
        className="w-[175px] h-[27px] bg-white rounded-[5px] text-sm leading-3 text-center outline-none"
        onChange={onSelectChange}
      >
        <option className="text-sm leading-3" value="-1">
          {t('common.sort_by_pop')}
        </option>
        <option className="text-sm leading-3" value="a-z">
          A-Z
        </option>
        <option className="text-sm leading-3" value="z-a">
          Z-A
        </option>
        <option className="text-sm leading-3" value="low-high">
          {t('common.sort_by_price_up')}
        </option>
        <option className="text-sm leading-3" value="high-low">
          {t('common.sort_by_price_down')}
        </option>
      </select>
    );
  };
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="max-w-[1440px] mx-auto">
      <div className="lg:container md:container sm:w-[95%] mx-auto">
        <h4 className="text-center mt-10 mb-[49px] text-lg leading-[25px]">
          {collection?.title}
        </h4>

        <div className="w-full flex items-center h-10 justify-end sm:justify-between pr-6 bg-primary-white mb-4">
          {renderSortBlock()}
        </div>

        <div className="w-full flex flex-wrap sm:justify-around">
          {sortedProducts && sortedProducts.length ? (
            sortedProducts.map(product => (
              <div key={product.id} className="mr-4 sm:mr-0 mb-4">
                <ProductCard product={product} />
              </div>
            ))
          ) : products && products.length ? (
            products.map(product => (
              <div key={product.id} className="mr-4 sm:mr-0 mb-4">
                <ProductCard product={product} />
              </div>
            ))
          ) : (
            <h4 className="w-full text-center text-md leading-[31px] font-light mb-14">
              Kolleksiyaya uyğun məhsul tapılmadı...
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsClassified;
