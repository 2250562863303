import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import ImageGallery from 'react-image-gallery';
import '../../assets/styles/carousel_style.css';
import orderService from '../../services/order.service';
import productService from '../../services/product.service';
import {
  currencyFormat,
  guestBasketWriter,
  isUserLoggedIn,
} from '../../utils/Utils';
import { useEffectOnce } from '../../utils/useEffectMiddleware';

import {
  CiBadgeDollar,
  CiCreditCard1,
  CiDollar,
  CiShare2,
  CiTimer,
} from 'react-icons/ci';
import { FaRegCheckCircle } from 'react-icons/fa';
import '../../assets/styles/commons.css';
import ProductVariant from '../../components/ProductVariant';
import QuickContact from '../../components/QuickContact';
import SuggesionsBlock from '../../components/SuggestionsBlock';
import { goodToast, nahToast } from '../../utils/Toaster';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import Error from '../Error';
const ProductDetail = () => {
  const params = useParams();
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();
  const isLoggedUser = isUserLoggedIn();

  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [description, setDescription] = useState([]);
  const [relatedStoreProducts, setRelatedStoreProducts] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [question, setQuestion] = useState('');
  const [inputError, setInputError] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
    setInputError(false);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  const handleChange = e => {
    setQuestion(e.target.value);
    if (e.target.value.trim()) {
      setInputError(false);
    }
  };
  const handleSubmit = () => {
    if (question.trim()) {
      console.log('Question sent:', question);
      setQuestion('');
      handleCloseModal();
      setShowSuccessModal(true);
    } else {
      setInputError(true);
    }
  };

  useEffectOnce(() => {
    if (params?.slug) {
      handleGetProduct(params.slug);
    }

    window.scrollTo(0, 0);
  }, [params]);

  const handleGetRelatedProductsByStore = storeSlug => {
    productService
      .getProductbyStore(storeSlug)
      .then(response => {
        if (response.status === 200) {
          setRelatedStoreProducts(response.data.results.products);
        }
      })
      .catch(error => {});
  };

  const handleGetProduct = slug => {
    productService
      .getProduct(slug)
      .then(response => {
        if (response.status === 200) {
          setProduct(response.data);
          let imageslist = [];
          imageslist.push({
            original: response?.data?.image,
            thumbnail: response?.data?.image,
            thumbnailHeight: 30,
            thumbnailWidth: 30,
          });
          response?.data?.images &&
            response?.data?.images.map((image, key) =>
              imageslist.push({
                original: image.image,
                thumbnail: image.image,
                thumbnailHeight: 30,
                thumbnailWidth: 30,
              })
            );

          setProductImages(imageslist);
          setDescription(response?.data?.description.split('=/='));
          handleGetRelatedProducts(response?.data?.category[0].slug);
          handleGetRelatedProductsByStore(response?.data?.store_slug);
        }
      })
      .catch(error => {
        if (!product.slug){
          navigate("product-not-found")
        }
        throw error.response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetRelatedProducts = slug => {
    productService
      .getRelatedProducts({ category: slug })
      .then(response => {
        if (response.status === 200) {
          setRelatedProducts(response.data);
        }
      })
      .catch(error => {
        throw error.response.data;
      });
  };

  const renderProductAttributes = () => {
    return (
      <div class="mt-5 shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px] p-5 sm:p-1 p-6">
        <h3 class="text font-bold text-[#4E4790]">Product information</h3>
        <ul class="mt-4 space-y-6 text-gray-800">
          <div className="px-2 py-2 sm:w-full">
            {product?.all_product_values && product?.all_product_values.length
              ? product?.all_product_values
                  .slice(0, 15)
                  .map((productValue, key) => (
                    <li class="text-sm">
                      {productValue?.attribute}{' '}
                      <span class="ml-4 float-right">
                        {productValue?.value}
                      </span>
                    </li>
                  ))
              : 'No product attributes found'}
          </div>
        </ul>
      </div>
    );
  };

  const handleAddBasket = (product, quantity, now) => {
    if (!isLoggedUser) {
      guestBasketWriter({ product: product, quantity });
      goodToast('Məhsul əlavə edildi!');
    } else {
      const data = {
        product: product.id,
        quantity: quantity,
      };

      if (now) {
        orderService
          .createCheckoutLines(data)
          .then(response => {
            if (response.status === 201) {
              goodToast('Məhsul sayı dəyişdi!');
              navigate('/shop');
            }
          })
          .catch(error => {
            nahToast('Xəta baş verdi!');
          });
      } else {
        orderService
          .createCheckoutLines(data)
          .then(response => {
            if (response.status === 201) {
              goodToast('Məhsul sayı dəyişdi!');
            }
          })
          .catch(error => {
            nahToast('Xəta baş verdi!');
          });
      }
    }
  };

  const renderDeliveryMethods = () => {
    return (
      <div className="mt-5">
        <span className="text-gray">{t('common.faq_delivery_options')}</span>
        <div className="bg-white flex items-center gap-2 sm:gap-1 w-full sm:w-full ">
          {product?.store_delivery_methods?.map((val, key) => (
            <div className="bg-white shadow-header p-3 w-full max-w-sm rounded-lg overflow-hidden mt-4">
              <div className="">
                <h3 className="text-[#4E4790]">
                  {val.option_name} - {currencyFormat(val.option_fee)}
                </h3>
                <p className="mt-2 text-sm text-gray">
                  {t('common.store_del_helptext')} {val.option_days}{' '}
                  {t('common.days_to_deliver')}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderProductDetailRightContainer = () => {
    return (
      <div className="col-span-2 sm:pl-5 mt-4">
        <div className="flex items-center justify-between mb-2">
          <div>
            <p className="text-[13px] text-[#a0a0a0]">
              {t('common.product_code')}: {product?.sku}
            </p>
          </div>
          <div>
            <span className="text-[13px] text-[#a0a0a0]">
              {t('common.viewed')} {product?.view_count} {t('common.times')}
            </span>
          </div>
        </div>
        <h2 className="text-md font-bold font-[sans-serif] ">
          <span>{product?.name}</span>
        </h2>

        {description.length > 0 ? (
          <div className="mt-[5px]">
            <ul className="font-light" style={{ fontSize: '13px' }}>
              <li className="">{description[1]}</li>
            </ul>
          </div>
        ) : null}

        <div>
          <ul className="space-y-2">
            <div className="flex items-center mt-2">
              <span className="font-light text-gray">Availability: </span>
              <span>
                {product?.is_stock ? (
                  <span
                    className="bg-green px-2 py-1 ml-1 text-white"
                    style={{ fontSize: '16px' }}
                  >
                    {t('common.in_stock')}
                  </span>
                ) : (
                  <span
                    className="bg-red px-2 py-1 ml-3 text-white"
                    style={{ fontSize: '16px' }}
                  >
                    {t('common.out_stock')}
                  </span>
                )}
              </span>
            </div>

            <div className="flex items-center mt-2">
              <span className="font-light mr-1 text-gray">
                {t('detail.brand')}
              </span>
              <span className="mr-2">{product?.brand}</span>
            </div>

            <div className="flex  items-center">
              <span className="font-light text-gray mr-1">
                {t('common.store')}:
              </span>
              <span className="mr-2">
                <Link
                  to={`/store/${product?.store_slug}`}
                  className="leading-3 text-active hover:cursor-pointer"
                >
                  {product?.store_business_name}
                </Link>
              </span>
            </div>
          </ul>
        </div>

        <div className="flex flex-wrap gap-4 mt-2">
          <div>
            {product?.is_discount ? (
              <React.Fragment>
                <span className="text-2xl leading-[60px] mr-4 text-red">
                  {currencyFormat(product?.discount_price)}
                </span>
                <span className="text-2xl leading-[60px] text-gray font-light line-through">
                  {currencyFormat(product?.price)}
                </span>
              </React.Fragment>
            ) : (
              <span className="text-2xl text-green leading-[60px]">
                {currencyFormat(product?.price)}
              </span>
            )}
          </div>
        </div>

        <div class="relative group">
          <div
            className="p-2 mt-2 text text-[#4E4790] rounded-lg border-[1px] cursor-pointer hover:bg-gray-200"
            role="alert"
          >
            <span className="font-medium tracking-wider">
              {t('common.buy_now_rates')}
            </span>
          </div>
          <div class="absolute shadow-lg hidden group-hover:block bg-[#333] text-white font-semibold px-3 py-[6px] text-[13px] right-0 left-0 mx-auto w-max -top-10 rounded before:w-4 before:h-4 before:rotate-45 before:bg-[#333] before:absolute before:z-[-1] before:-bottom-1 before:left-0  before:right-0 before:mx-auto">
            {t('common.payment_valid')}
          </div>
        </div>

        <div className="mt-5 mb-5">
          <ProductVariant
            main_product_slug={product?.slug}
            main_product_id={product?.main_product_id}
            color={product?.color?.id}
          />
        </div>

        <ul className="icon-list">
          <div className="product-icon-container">
            <div className="product-icon-image">
              <CiTimer className="text-purple text-lg"></CiTimer>
            </div>
            <div className="product-icon-text">
              <p>{t('common.delivery_around')}</p>
            </div>
          </div>

          <div className="product-icon-container">
            <div className="product-icon-image">
              <CiBadgeDollar className="text-purple text-lg"></CiBadgeDollar>
            </div>
            <div className="product-icon-text">
              <p>
                {t('common.taksit_month_sum')}
                <span style={{ color: 'red', fontSize: '20px' }}>
                  {currencyFormat(product?.price / 12)}
                </span>
              </p>
            </div>
          </div>

          <div className="product-icon-container">
            <div className="product-icon-image">
              <CiCreditCard1 className="text-purple text-lg"></CiCreditCard1>
            </div>
            <div className="product-icon-text">
              <p>
                <span style={{ color: '#756EB1' }}>
                  {t('common.safe_purch')}
                </span>{' '}
                {t('common.safe_purch2')}
              </p>
            </div>
          </div>

          <div className="product-icon-container">
            <div className="product-icon-image">
              <CiDollar className="text-purple text-lg"></CiDollar>
            </div>
            <div className="product-icon-text">
              <p>
                <span style={{ color: '#756EB1' }}>{t('common.cash')},</span>{' '}
                <span style={{ color: '#756EB1' }}>{t('common.taksit')}</span>{' '}
                {t('common.and')}{' '}
                <span style={{ color: '#756EB1' }}>{t('common.card')}</span>{' '}
                {t('common.payment')}
              </p>
            </div>
          </div>
        </ul>

        {renderPurchaseActions()}

        {/* <div className="mt-5 mb-5">
          {product?.is_stock ? (
            <div className="bg-white flex items-center gap-4 sm:gap-1 w-full sm:w-full mt-4 ">
              <button
                onClick={() => setQuantity(prev => (prev <= 1 ? 1 : prev - 1))}
                type="button"
                className="px-2 py-2.5 flex items-center text-sm tracking-wider  font-semibold outline-none"
              >
                -
              </button>
              <span className="w-[35px] text-button leading-[18px] text-center">
                {quantity}
              </span>
              <button
                onClick={() => setQuantity(prev => prev + 1)}
                type="button"
                className="px-2 py-2.5 flex items-center text-sm tracking-wider font-semibold outline-none"
              >
                +
              </button>

              <button
                text={t('detail.add-card')}
                onClick={() => handleAddBasket(product, quantity, false)}
                type="button"
                className="baysart-secondary-button w-full"
              >
                {t('common.add_to_basket')}
              </button>
              <button
                text={t('detail.buy-now')}
                type="button"
                secondary
                className="baysart-secondary-button w-full mr-10"
                onClick={() => handleAddBasket(product, quantity, true)}
              >
                {t('common.buynow')}
              </button>
            </div>
          ) : null}
        </div> */}

        {product?.store_delivery_methods ? renderDeliveryMethods() : null}
      </div>
    );
  };

  const generateShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('Product link is copied to clipboard.');
  };

  const renderPurchaseActions = () => {
    return (
      <div className="flex gap-1 mt-4">
        <button
          type="button"
          onClick={() => handleAddBasket(product, 1, true)}
          class="w-full py-4 rounded-lg text tracking-wider font-medium border border-current outline-none bg-[#574FA0] hover:bg-transparent text-white hover:text-[#574FA0] transition-all duration-300"
        >
          {t('common.buynow')}
        </button>

        <button
          type="button"
          onClick={() => handleAddBasket(product, 1, false)}
          class="w-full py-4 rounded-lg text tracking-wider font-medium border border-[#574FA0] outline-none bg-transparent hover:bg-[#574FA0] text-[#574FA0] hover:text-white transition-all duration-300"
        >
          {t('common.add_to_basket')}
        </button>

        <button
          type="button"
          onClick={() => generateShareLink()}
          class="px-5 py-4 rounded-lg text-sm tracking-wider font-medium border border-[#574FA0] outline-none bg-transparent hover:bg-[#574FA0] text-[#574FA0] hover:text-white transition-all duration-300"
        >
          <CiShare2 className="text-lg"></CiShare2>
        </button>
      </div>
    );
  };

  const renderSellerInfoBlock = () => {
    return (
      <div className="mt-5 shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px] p-5 sm:p-1 p-6">
        <div>
          <h5 className="text font-bold leading-tight mb-3 text-[#4E4790]">
            Seller: {product?.store_business_name}
          </h5>
          <span
            className="flex text-sm items-center gap-2"
            style={{ color: 'gray' }}
          >
            {t('common.top_seller')}
          </span>
        </div>
        <ul className="w-full">
          <li className="w-full py-2 shop_cat">
            <span className="customer_satisfaction_rate">99.6%</span>
            {t('common.seller_rate')}
          </li>
          <li className="w-full py-2 shop_cat" style={{ color: '#4E4790' }}>
            <Link to={`/store/${product?.store_slug}`}>
              {t('common.sellers_items')}
            </Link>
          </li>
        </ul>
        <div className="flex gap-2 py-2">
          <Link to={`/store/${product?.store_slug}`}>
            <span
              className="pointer-events-auto me-5 inline-block cursor-pointer rounded text-base font-normal leading-normal text-primary"
              style={{ color: '#4E4790' }}
            >
              {t('common.seller_info')}
            </span>
          </Link>
          <span>|</span>
          <a
            type="button"
            onClick={handleOpenModal}
            className="pointer-events-auto inline-block cursor-pointer rounded text-base font-normal leading-normal text-primary"
            style={{ color: '#4E4790' }}
          >
            Ask a question
          </a>
          {showModal && (
            <div className="modal top-0 left-0 bg-gray-900 bg-opacity-50 fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
              <div className="bg-white rounded-lg p-8 w-1/2 relative modal_content">
                <button
                  className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 text-lg"
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
                <form>
                  <h2 className="text-xl">{t('common.ask_question_title')}</h2>
                  <p className="mb-5">{t('common.question_subtitle')}</p>
                  {inputError && (
                    <p className="text-red-500 text-sm text-red">
                      {t('common.input_required')}
                    </p>
                  )}
                  <input
                    type="text"
                    value={question}
                    required
                    onChange={handleChange}
                    className="border rounded w-full py-2 px-3 mb-4 ask_quest"
                    placeholder={t('common.question_input')}
                  />

                  <button
                    onClick={handleSubmit}
                    className="px-6 py-2.5 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-[#4E4790] hover:bg-[#574FA0]"
                  >
                    {t('common.question_send')}
                  </button>
                </form>
              </div>
            </div>
          )}

          {showSuccessModal && (
            <div className="modal fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center inset-0 p-4 flex-wrap z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
              <div className="bg-white rounded-lg p-8 w-1/2 relative success_modal_content">
                <button
                  className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 text-lg"
                  onClick={handleCloseSuccessModal}
                >
                  &times;
                </button>
                <div className="flex justify-center mb-10">
                  <FaRegCheckCircle
                    className="text-3xl"
                    style={{ color: '#4E4790' }}
                  />
                </div>
                <h2 className="text-2xl text-center">
                  {t('common.question_sent')}
                </h2>
                <p className="mb-5 text-center">
                  {t('common.question_sent_success')}
                </p>
                <button
                  onClick={handleCloseSuccessModal}
                  className="px-6 py-2.5 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-[#4E4790] hover:bg-[#574FA0]"
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderProductDescription = () => {
    return (
      <div class="mt-5 shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px] p-5 sm:p-1 p-6">
        <h3 class="text font-bold text-[#4E4790]">Product description</h3>
        <ul class="mt-4 space-y-6 text-gray-800">
          <div className="px-2 py-2 sm:w-full">{product?.description}</div>
        </ul>
      </div>
    );
  };

  const renderProductImagesContainer = () => {
    return (
      <div className="w-full col-span-3 top-0 sm:flex">
        <div className="flex productImageFlex sm:w-full">
          <div className="flex flex-col w-full p-5 gap-5 product-slide">
            <ImageGallery
              showPlayButton={false}
              items={productImages}
            ></ImageGallery>
          </div>
        </div>
      </div>
    );
  };
  const renderProductDetailComponent = () => {
    return (
      <div className="shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px] p-5 sm:p-1">
        <div className="grid items-start grid-cols-1 lg:grid-cols-5 gap-3">
          {renderProductImagesContainer()}
          {renderProductDetailRightContainer()}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center ">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="font-[sans-serif]">
      <div className="p-2 max-w-8xl m-auto mt-10">
        {renderProductDetailComponent()}

        <div className="flex flex-col lg:flex-row gap-5">
          <div className="flex-grow lg:flex-grow-0 lg:flex-shrink-0 lg:basis-3/5">
            {renderProductAttributes()}
            {renderProductDescription()}
          </div>
          <div className="flex-grow lg:flex-grow-0 lg:flex-shrink-0 lg:basis-2/5">
            {renderSellerInfoBlock()}
          </div>
        </div>

        <div className="shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px]">
          <SuggesionsBlock
            max={5}
            title={t('common.suggested_products')}
            relatedProducts={relatedProducts}
          ></SuggesionsBlock>
        </div>
        <div className="shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px]">
          <SuggesionsBlock
            max={10}
            relatedProducts={relatedStoreProducts}
            title={t('common.more_from_this_seller')}
          ></SuggesionsBlock>
        </div>
        <div className="mt-10 mb-20 p-5 shadow-[rgba(0,_0,_0,_0.1)_0px_3px_20px]">
          <QuickContact></QuickContact>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
