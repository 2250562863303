import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const SizesList = ({
  sizes,
  selectedSizesFilters,
  handleSizeFilterChange,
  t,
}) => {
  const [itemsToShow, setItemsToShow] = useState(5);

  const showMore = () => setItemsToShow(sizes.length);
  const showLess = () => setItemsToShow(5);

  const renderShowMoreLessButton = () => {
    if (sizes.length <= 3 || (itemsToShow >= sizes.length && itemsToShow <= 5))
      return null;
    return (
      <button
        className="mt-2 ml-4 text-[#4e4790]"
        onClick={itemsToShow < 6 ? showMore : showLess}
      >
        <FontAwesomeIcon icon={itemsToShow < 6 ? faChevronDown : faChevronUp} />{' '}
        {itemsToShow < 6 ? t('common.show_more') : t('common.show_less')}
      </button>
    );
  };

  return (
    <div className="mt-4">
      <h6 className="text-[#4e4790] text font-bold px-2">
        {t('common.sizes')}
      </h6>
      <ul >
        {sizes.slice(0, itemsToShow).map((size, index) => (
          <li key={index}>
            <label
              className="bs_custom_checkbox_cont"
              style={{ padding: '5px 5px 0 10px' }}
            >
              <input
                type="checkbox"
                className="bs_custom_checkbox_input"
                id={`${size}_${index}`}
                value={`${size}_${index}`}
                onChange={() => handleSizeFilterChange(size)}
                checked={selectedSizesFilters.includes(size)}
              />
              <span className="bs_custom_checkbox_span w-full">{size}</span>
            </label>
          </li>
        ))}
      </ul>
      {renderShowMoreLessButton()}
    </div>
  );
};

export default SizesList;